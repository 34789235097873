import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Modal,
} from "@mui/material";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "178px" },
    height: { xs: "38px", md: "48px" },
    color: "white",
    textTransform: "none",
    fontWeight: "bold",
  },
  saveButton: {
    background: "#DFBF41",
    ":hover": {
      background: "#a18e46",
    },
  },
  modal: {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // width: "80%",
    // bgcolor: "background.paper",
    // boxShadow: 24,
    // borderRadius: "10px",
    // p: 4,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%", // Adjust width for mobile
    maxWidth: "1300px", // Limit width for larger screens
    maxHeight: "100vh", // Restrict height for smaller screens
    overflowY: "auto", // Enable scrolling for long content
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 3,
  },
};

const InvestmentSearch = () => {
  const [branches, setBranches] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [filteredInvestments, setFilteredInvestments] = useState([]);
  const [searchParams, setSearchParams] = useState({
    branchCode: "",
    fromDate: "",
    toDate: "",
    memberName: "",
    policyCode: "",
    memberCode: "",
    schemeType: "",
    advisorCode: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const axiosInstance = axios.create({
    baseURL: "https://testapi.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });

  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  const fetchAllInvestments = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        "/admin/investment/getallinvestment"
      );
      setInvestments(response.data);
    } catch (error) {
      console.error("Error fetching investments:", error.message);
    }
  }, []);

  useEffect(() => {
    fetchBranches();
    fetchAllInvestments();
  }, [fetchBranches, fetchAllInvestments]);

  const handleSearchChange = (field, value) => {
    setSearchParams((prev) => ({ ...prev, [field]: value }));
  };

  const handleSearch = () => {
    console.log("hellooooo");
    const filtered = investments.filter((inv) => {
      const matchesBranch = searchParams.branchCode
        ? inv.branchCode === searchParams.branchCode
        : true;
      const matchesFromDate = searchParams.fromDate
        ? new Date(inv.policyDate) >= new Date(searchParams.fromDate)
        : true;
      const matchesToDate = searchParams.toDate
        ? new Date(inv.policyDate) <= new Date(searchParams.toDate)
        : true;
      const matchesMemberName = searchParams.memberName
        ? inv.memberName
            .toLowerCase()
            .includes(searchParams.memberName.toLowerCase())
        : true;
      const matchesPolicyCode = searchParams.policyCode
        ? inv.policyCode.includes(searchParams.policyCode)
        : true;
      const matchesMemberCode = searchParams.memberCode
        ? inv.member.memberCode.includes(searchParams.memberCode)
        : true;
      const matchesSchemeType = searchParams.schemeType
        ? inv.schemeType === searchParams.schemeType
        : true;
      const matchesAdvisorCode = searchParams.advisorCode
        ? inv.advisorCode.includes(searchParams.advisorCode)
        : true;

      return (
        matchesBranch &&
        matchesFromDate &&
        matchesToDate &&
        matchesMemberName &&
        matchesPolicyCode &&
        matchesMemberCode &&
        matchesSchemeType &&
        matchesAdvisorCode
      );
    });
    console.log("filtered", filtered);
    setFilteredInvestments(filtered);
    setShowTable(true);
  };

  const handleViewClick = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  return (
    <Box>
      <Typography sx={styles.header}>Investment Search</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Search Box</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Branch</Typography>
              <TextField
                select
                value={searchParams.branchCode}
                onChange={(e) =>
                  handleSearchChange("branchCode", e.target.value)
                }
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {`${branch.branchName} (${branch.branchCode})`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>From Date</Typography>
            <TextField
              type="date"
              value={searchParams.fromDate}
              onChange={(e) => handleSearchChange("fromDate", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>To Date</Typography>
            <TextField
              type="date"
              sx={styles.textField}
              value={searchParams.toDate}
              onChange={(e) => handleSearchChange("toDate", e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>Applicant Name</Typography>
            <TextField
              sx={styles.textField}
              value={searchParams.memberName}
              onChange={(e) => handleSearchChange("memberName", e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>Policy No.</Typography>
            <TextField
              sx={styles.textField}
              value={searchParams.policyCode}
              onChange={(e) => handleSearchChange("policyCode", e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>Member Code</Typography>
            <TextField
              sx={styles.textField}
              value={searchParams.memberCode}
              onChange={(e) => handleSearchChange("memberCode", e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>Plan Name</Typography>
            <TextField
              select
              sx={styles.textField}
              value={searchParams.schemeType}
              onChange={(e) => handleSearchChange("schemeType", e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              <MenuItem value="DRD">DRD</MenuItem>
              <MenuItem value="RD">RD</MenuItem>
              <MenuItem value="FD">FD</MenuItem>
              <MenuItem value="MIS">MIS</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>Advisor Code</Typography>
            <TextField
              sx={styles.textField}
              value={searchParams.advisorCode}
              onChange={(e) =>
                handleSearchChange("advisorCode", e.target.value)
              }
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
        </Grid>
        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Button
            onClick={handleSearch}
            sx={{
              ...styles.button,
              ...styles.saveButton,
              mt: 2.5,
            }}
          >
            Search
          </Button>
        </Box>
      </Paper>
      {showTable && (
        <Paper sx={{ ...styles.paper, mt: 2 }}>
          <Typography sx={styles.sectionHeader}>
            Investment Data List
          </Typography>
          <Box sx={{ overflowX: "auto" }}>
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "20px",
                width: { md: "1200px", xs: "296px" },
                overflowX: "auto",
              }}
            >
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#413AF1" }}>
                  <TableRow>
                    {[
                      "SL No",
                      "POLICY NO",
                      "MEMBER CODE",
                      "APPLICANT NAME",
                      "POLICY DATE",
                      "PLAN CODE",
                      "AMOUNT",
                      "MATURITY AMOUNT",
                      "MATURITY DATE",
                      "ADVISOR CODE",
                      "BRANCH",
                      "BALANCE",
                      "STATUS",
                      "VIEW",
                    ].map((header, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          color: "white",
                          fontWeight: 400,
                          fontSize: "13px",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredInvestments.length > 0 ? (
                    filteredInvestments.map((inv, index) => (
                      <TableRow key={inv.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{inv.policyCode || ""}</TableCell>
                        <TableCell>{inv.member?.memberCode || ""}</TableCell>
                        <TableCell>{inv.memberName || ""}</TableCell>
                        <TableCell>
                          {new Date(inv.policyDate || "").toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </TableCell>
                        <TableCell>{inv.schemeType || ""}</TableCell>
                        <TableCell>{inv.policyAmount || ""}</TableCell>
                        <TableCell>{inv.maturityAmount || ""}</TableCell>
                        <TableCell>
                          {new Date(inv.maturityDate || "").toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </TableCell>
                        <TableCell>{inv.advisorCode || ""}</TableCell>
                        <TableCell>{inv.branchName || ""}</TableCell>
                        <TableCell>{inv.policyAmount || ""}</TableCell>
                        <TableCell>{inv.status || ""}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleViewClick(inv)}>
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={12}
                        align="center"
                        sx={{ color: "red" }}
                      >
                        No results found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Modal open={openModal} onClose={handleCloseModal}>
              <Box sx={styles.modal}>
                <Typography
                  sx={{
                    mb: 2,
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  Investment Details
                </Typography>
                {selectedRow && (
                  <Grid container spacing={2}>
                    {[
                      {
                        label: "Policy Code",
                        value: selectedRow.policyCode || "",
                      },
                      {
                        label: "Member Name",
                        value: `${selectedRow.memberName || ""} (${
                          selectedRow?.member?.memberCode || ""
                        })`,
                      },
                      {
                        label: "Policy Date",
                        value: `${new Date(
                          selectedRow.policyDate || ""
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}`,
                      },
                      {
                        label: "Branch",
                        value: `${selectedRow.branchName || ""} (${
                          selectedRow.branchCode || ""
                        })`,
                      },
                      {
                        label: "Plan Code",
                        value: selectedRow.schemeType || "",
                      },
                      {
                        label: "Policy Amount",
                        value: `${selectedRow.policyAmount || ""}`,
                      },
                      {
                        label: "Maturity Amount",
                        value: selectedRow.maturityAmount || "",
                      },
                      {
                        label: "Maturity Date",
                        value: `${new Date(
                          selectedRow.maturityDate || ""
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}`,
                      },
                      {
                        label: "DOB & Age",
                        value: `${new Date(
                          selectedRow?.member?.dob || ""
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })} [${selectedRow?.member?.age || ""}]`,
                      },
                      {
                        label: "Mobile No",
                        value: `${selectedRow?.member?.mobileNo || ""}`,
                      },
                      {
                        label: "Operation Mode",
                        value: `${selectedRow?.operationMode || ""}`,
                      },
                      {
                        label: "Joint Code",
                        value: `${selectedRow.jointCode || "--"}`,
                      },
                      {
                        label: "Advisor Code",
                        value: selectedRow.advisorCode || "",
                      },
                      {
                        label: "Joint Name",
                        value: `${selectedRow.jointName || "--"}`,
                      },
                      {
                        label: "Nominee Name",
                        value: selectedRow.nomineeName || "",
                      },
                      {
                        label: "Nominee Age",
                        value: `${selectedRow.nomineeAge || "--"}`,
                      },
                      {
                        label: "Nominee Relation",
                        value: selectedRow.nomineeRelation || "",
                      },
                      {
                        label: "Deposit Amount",
                        value: `${selectedRow.depositAmount || "--"}`,
                      },
                      {
                        label: "SMS Status",
                        value: selectedRow.smsStatus ? "YES" : "NO",
                      },
                      {
                        label: "Scheme Name",
                        value: `${selectedRow.schemeName || "--"}`,
                      },
                      { label: "Status", value: selectedRow.status || "" },
                      {
                        label: "Payment By",
                        value: selectedRow.paymentBy || "",
                      },
                      {
                        label: "Remarks",
                        value: `${selectedRow.remarks || "--"}`,
                      },
                      {
                        label: "Address",
                        value: selectedRow?.member?.address || "",
                      },
                      {
                        label: "District",
                        value: `${selectedRow?.member?.district || "--"}`,
                      },
                      {
                        label: "State",
                        value: selectedRow?.member?.state || "",
                      },
                      {
                        label: "Pincode",
                        value: `${selectedRow?.member?.pincode || "--"}`,
                      },
                    ].map((item, index) => (
                      <Grid container item xs={12} sm={6} key={index}>
                        <Grid item xs={6}>
                          <Typography fontWeight="bold">
                            {item.label}:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{item.value}</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            </Modal>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default InvestmentSearch;
