import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  TableContainer,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PrintIcon from "@mui/icons-material/Print";
import axios from "axios";
import { useReactToPrint } from "react-to-print";

const SavingStatement = () => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [statementData, setStatementData] = useState([]);
  const [showStatement, setShowStatement] = useState(false);

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current, // Define the component to print
    documentTitle: "Account Statement", // Customize the title of the document
  });

  const token = sessionStorage.getItem("token");

  // Fetch account numbers when the component mounts
  useEffect(() => {
    // Fetch all account numbers
    axios
      .get("https://testapi.vlnidhi.com/api/admin/saving-accounts/getAll", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAccounts(response.data); // Assuming the data is an array of account objects
      })
      .catch((error) => {
        console.error("Error fetching accounts:", error);
      });
  }, [token]);

  const handleSearch = () => {
    if (!selectedAccount || !fromDate || !toDate) return;

    // Fetch transaction data based on the selected account and date range
    axios
      .get(
        "https://testapi.vlnidhi.com/api/admin/transactions/gettransaction",
        {
          params: {
            savingAccountNumber: selectedAccount,
            startDate: fromDate,
            endDate: toDate,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("API Response:", response.data);
        setStatementData(response.data);
        setShowStatement(true);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });
  };

  const textFieldStyle = {
    // width: { xs: "290px", lg: "578px" },
    height: "48px",
    borderRadius: "80%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    opacity: 0.6,
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Saving Statement
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>
        <Grid container spacing={2} alignItems="center">
          {/* Select A/C No. */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Select A/C No.
            </Typography>
            <TextField
              select
              fullWidth
              variant="outlined"
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              {accounts.map((account) => (
                <MenuItem
                  key={account.savingAccountNumber}
                  value={account.savingAccountNumber}
                >
                  {account.member?.memberName || "Unnamed"} (
                  {account.savingAccountNumber || "No Account No"})
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* From Date */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              From Date
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          {/* To Date */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              To Date
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          {/* Search Button */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "48px",
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                fullWidth
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  // width: { xs: "50%", md: "157px" },
                  width: "166px",
                  height: "48px",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                  // mt: 3,
                }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {showStatement && statementData.length > 0 && (
        <Paper ref={printRef} sx={{ mt: 4, borderRadius: "20px 20px 0 0" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "48px",
              background: "#D9D9D9",
              borderRadius: "20px 20px 0 0",
              padding: "0 16px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              margin: "0 auto 20px",
            }}
          >
            <Typography
              align="center"
              sx={{
                flexGrow: 1,
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Account Statement
            </Typography>
            <IconButton onClick={handlePrint}>
              <PrintIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                margin: "auto",
                p: 1,
                px: 3,
              }}
            >
              <Grid item xs={6}>
                <Box sx={{ display: "flex", flexFlow: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      opacity: 0.6,
                    }}
                  >
                    Account Number:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      ml: 5,
                    }}
                  >
                    {statementData[0]?.acccountNo}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexFlow: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      opacity: 0.6,
                    }}
                  >
                    A/C Holder Name:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      ml: 4.5,
                    }}
                  >
                    {statementData[0]?.accountHolderName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexFlow: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      opacity: 0.6,
                    }}
                  >
                    Relative Name:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      ml: 7,
                    }}
                  >
                    {statementData[0]?.relativeName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexFlow: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      opacity: 0.6,
                    }}
                  >
                    Relative Relation:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      ml: 5,
                    }}
                  >
                    {statementData[0]?.relativeRelation || "-----"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexFlow: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      opacity: 0.6,
                    }}
                  >
                    Address:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      ml: 13.5,
                    }}
                  >
                    {statementData[0]?.address}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: "flex", flexFlow: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      opacity: 0.6,
                    }}
                  >
                    Op. Date:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      ml: 10,
                    }}
                  >
                    {statementData[0]?.openingDate}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexFlow: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      opacity: 0.6,
                    }}
                  >
                    Member Code:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      ml: 4.5,
                    }}
                  >
                    {statementData[0]?.memberCode}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexFlow: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      opacity: 0.6,
                    }}
                  >
                    PAN:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      ml: 14.5,
                    }}
                  >
                    {statementData[0]?.panNo}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexFlow: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      opacity: 0.6,
                    }}
                  >
                    Mode Of Op.:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      ml: 6,
                    }}
                  >
                    {statementData[0]?.modeOfOperation}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexFlow: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      opacity: 0.6,
                    }}
                  >
                    Op. Branch:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      mb: 2,
                      ml: 7,
                    }}
                  >
                    {statementData[0]?.branchName}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ p: 2 }}>
            <TableContainer
              component={Paper}
              sx={{ borderRadius: "20px 20px 0px 0px" }}
            >
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#413AF1" }}>
                  <TableRow>
                    <TableCell sx={{ color: "#fff" }}>Account No.</TableCell>
                    <TableCell sx={{ color: "#fff" }}>TXN Date</TableCell>
                    {/* <TableCell sx={{ color: "#fff" }}>Time</TableCell> */}
                    {/* <TableCell sx={{ color: "#fff" }}>Inst No</TableCell> */}
                    <TableCell sx={{ color: "#fff" }}>
                      Bal. before Txn
                    </TableCell>
                    <TableCell sx={{ color: "#fff" }}>Deposit</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Withdrawal</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Balance</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Pay Mode</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Branch</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Remarks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statementData.map((transaction, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "#F2F6FA" : "#C1E0FB",
                      }}
                    >
                      <TableCell>{transaction.acccountNo || "N/A"}</TableCell>
                      <TableCell>
                        {new Date(transaction.transaDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        ) || "N/A"}
                      </TableCell>
                      {/* <TableCell>
                        {transaction.transactionTime
                          ? new Date(
                              `1970-01-01T${transaction.transactionTime}`
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                          : ""}
                      </TableCell> */}
                      {/* <TableCell>0</TableCell> */}
                      <TableCell>
                        {transaction.balanceAttransaction || "0"}
                      </TableCell>
                      <TableCell>{transaction.deposite || "0"}</TableCell>
                      <TableCell>{transaction.withdrwal || "0"}</TableCell>
                      <TableCell>
                        {transaction.balanceAfterTransaction || "0"}
                      </TableCell>
                      <TableCell>{transaction.paymentBy || "N/A"}</TableCell>
                      <TableCell>{transaction.branchName || "N/A"}</TableCell>
                      <TableCell>{transaction.remarks || "N/A"}</TableCell>
                    </TableRow>
                  ))}

                  {/* Grand Total Row */}
                  {/* <TableRow sx={{ backgroundColor: "#413AF1" }}>
                    <TableCell colSpan={3} sx={{ fontWeight: "bold", color: "white" }}>
                      Grand Total
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                      {statementData
                        .reduce(
                          (total, transaction) =>
                            total + (parseFloat(transaction.deposite) || 0),
                          0
                        )
                        .toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                      {statementData
                        .reduce(
                          (total, transaction) =>
                            total + (parseFloat(transaction.withdrwal) || 0),
                          0
                        )
                        .toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                      {statementData
                        .reduce(
                          (total, transaction) =>
                            total +
                            (parseFloat(transaction.balanceAfterTransaction) ||
                              0),
                          0
                        )
                        .toFixed(2)}
                    </TableCell>
                    <TableCell colSpan={4}></TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default SavingStatement;
