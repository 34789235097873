import React, { useEffect, useState } from "react";
import {
  TextField,
  Switch,
  Button,
  MenuItem,
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const RecurringDeposit = () => {
  const token = sessionStorage.getItem("token");
  const [minimumAmount, setMinimumAmount] = useState();
  const [interestRate, setInterestRate] = useState();
  const [termMode, setTermMode] = useState("Monthly");
  const [term, setTerm] = useState();
  const [compoundInterval, setCompoundInterval] = useState("Monthly");
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [maturityAmount, setMaturityAmount] = useState(0);
  const [planName, setPlanName] = useState("");
  const [gracePeriod, setGracePeriod] = useState("");
  const [lateFine, setLateFine] = useState("");
  const [commissionNew, setCommissionNew] = useState("");
  const [commissionRenew, setCommissionRenew] = useState("");
  const [planStatus, setPlanStatus] = useState(true);

  const [rdPlans, setRdPlans] = useState([]);

  const fetchRdPlans = async () => {
    try {
      const response = await axios.get(
        "https://testapi.vlnidhi.com/api/admin/getallrd",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRdPlans(response.data);
    } catch (error) {
      console.error("Error fetching RD plans:", error);
    }
  };

  useEffect(() => {
    fetchRdPlans();
  }, []);

  // Handle form submission
  const handleSubmit = async () => {
    const requestBody = {
      planName,
      interestRate,
      termMode,
      term,
      commissionNew,
      commissionRenew,
      compoundInterval,
      gracePeriod,
      lateFine,
      planStatus,
      minimumAmount,
    };

    try {
      const response = await axios.post(
        "https://testapi.vlnidhi.com/api/admin/createrdplan",
        requestBody,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Form submitted successfully:", response.data);
      fetchRdPlans();
      Swal.fire("Success", "Plan created successfully!", "success");

      setPlanName("");
      setInterestRate("");
      setTermMode("Monthly");
      setTerm("");
      setCommissionNew("");
      setCommissionRenew("");
      setCompoundInterval("Monthly");
      setGracePeriod("");
      setLateFine("");
      setPlanStatus(true);
      setMinimumAmount("");
      setTotalDeposit(0);
      setMaturityAmount(0);
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire(
        "Error",
        error.response?.data?.message || "Failed to create plan",
        "error"
      );
    }
  };

  // Calculate Total Deposit and Maturity Amount
  useEffect(() => {
    // Total Deposit Calculation
    const totalDepositCalc =
      minimumAmount > 0 && term > 0 ? (minimumAmount * term).toFixed(2) : 0;
    setTotalDeposit(totalDepositCalc);

    // Determine compounding frequency (n)
    const n =
      compoundInterval === "Daily"
        ? 365
        : compoundInterval === "Monthly"
        ? 12
        : compoundInterval === "Quarterly"
        ? 4
        : compoundInterval === "Half-Yearly"
        ? 2
        : 1;

    // Interest rate as a decimal
    const r = interestRate / 100;

    // Convert term to years if term mode is monthly
    const t = termMode === "Monthly" ? term / 12 : term;

    // Maturity Amount Calculation for Recurring Deposit
    if (r > 0 && t > 0 && minimumAmount > 0) {
      let maturity = 0;

      // Loop through each month's deposit and calculate its maturity
      for (let i = 1; i <= term; i++) {
        const monthsRemaining = (term - i + 1) / 12; // remaining term in years
        maturity += minimumAmount * Math.pow(1 + r / n, n * monthsRemaining);
      }

      setMaturityAmount(maturity.toFixed(2));
    } else {
      setMaturityAmount(0);
    }
  }, [minimumAmount, interestRate, term, compoundInterval, termMode]);

  const textFieldStyle = {
    width: { xs: "100%", lg: "100%" },
    height: "48px",
    borderRadius: "80%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    // opacity: 0.6,
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
    // mb: 1,
  };

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Recurring Deposit
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Plan Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Compound Interval<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={compoundInterval}
              onChange={(e) => setCompoundInterval(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem value="Daily">Daily</MenuItem>
              <MenuItem value="Monthly">Monthly</MenuItem>
              <MenuItem value="Quarterly">Quarterly</MenuItem>
              <MenuItem value="Half-Yearly">Half-Yearly</MenuItem>
              <MenuItem value="Yearly">Yearly</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Minimum Amount<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              // type="number"
              value={minimumAmount}
              onChange={(e) => setMinimumAmount(Number(e.target.value))}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Total Deposit<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={totalDeposit}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                  fontWeight: 1000,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Interest Rate (%)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              // type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(Number(e.target.value))}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Maturity Amount<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={maturityAmount}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                  fontWeight: 1000,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Term Mode<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={termMode}
              onChange={(e) => setTermMode(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem value="Monthly">Monthly</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Grace Period<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={gracePeriod}
              onChange={(e) => setGracePeriod(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Term<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              // type="number"
              value={term}
              onChange={(e) => setTerm(Number(e.target.value))}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Late Fine(%)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={lateFine}
              onChange={(e) => setLateFine(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Comm. New(%)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={commissionNew}
              onChange={(e) => setCommissionNew(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Comm. Renew(%)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={commissionRenew}
              onChange={(e) => setCommissionRenew(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Plan Status<span style={{ color: "red" }}>*</span>
            </Typography>
            <Switch
              checked={planStatus}
              onChange={(e) => setPlanStatus(e.target.checked)}
              sx={{ transform: "scale(1.5)" }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 3,
            gap: "2%",
          }}
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#E8C30B",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#a99220" },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Box sx={{ mt: 3, borderRadius: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Recurring Plan
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "10px 10px 0px 0px",
            width: { xs: "320px", md: "100%", lg: "100%" },
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "#3F00FF" }}>
                <TableCell style={{ color: "#fff" }}>Plan Code</TableCell>
                <TableCell style={{ color: "#fff" }}>Plan Name</TableCell>
                <TableCell style={{ color: "#fff" }}>Term</TableCell>
                <TableCell style={{ color: "#fff" }}>ROI</TableCell>
                {/* <TableCell style={{ color: "#fff" }}>Mode</TableCell> */}
                <TableCell style={{ color: "#fff" }}>Comm(N)</TableCell>
                <TableCell style={{ color: "#fff" }}>Comm(R)</TableCell>
                <TableCell style={{ color: "#fff" }}>MinAmount</TableCell>
                <TableCell style={{ color: "#fff" }}>Grace Days</TableCell>
                <TableCell style={{ color: "#fff" }}>Late Fine(%)</TableCell>
                {/* <TableCell style={{ color: "#fff" }}>isFlexi</TableCell> */}
                {/* <TableCell style={{ color: "#fff" }}>View</TableCell>
                <TableCell style={{ color: "#fff" }}>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rdPlans.map((plan) => (
                <TableRow key={plan.id}>
                  <TableCell>{plan.planCode}</TableCell>
                  <TableCell>{plan.planName}</TableCell>
                  <TableCell>{plan.term}</TableCell>
                  <TableCell>{plan.interestRate}%</TableCell>
                  {/* <TableCell>{plan.termMode}</TableCell> */}
                  <TableCell>{plan.commissionNew}%</TableCell>
                  <TableCell>{plan.commissionRenew}%</TableCell>
                  {/* <TableCell>{plan.isFlexi ? "Yes" : "No"}</TableCell> */}
                  <TableCell>{plan.minimumAmount}</TableCell>
                  <TableCell>{plan.gracePeriod}</TableCell>
                  <TableCell>{plan.lateFine}%</TableCell>
                  {/* <TableCell>
                    <Button variant="contained">View</Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default RecurringDeposit;
