import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
  InputAdornment,
  Grid,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const SavingTransactionApproval = () => {
  const [closingBranches, setClosingBranches] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [selectedIds, setSelectedIds] = useState(new Set()); // State to track selected transaction IDs
  const [showTable, setShowTable] = useState(false);
  const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions

  const [formData, setFormData] = useState({
    closingBranch: "",
    fromDate: "",
    toDate: "",
  });
  const token = sessionStorage.getItem("token");

  // Fetch closing branches and transactions on mount
  useEffect(() => {
    axios
      .get("https://testapi.vlnidhi.com/api/admin/getallbid", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => setClosingBranches(response.data))
      .catch((error) =>
        console.error("Error fetching closing branches:", error)
      );

    // Fetch transactions on mount
    axios
      .get(
        "https://testapi.vlnidhi.com/api/admin/transactions/getpendingtransaction",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error.response?.data?.message ||
            "An error occurred while fetching transactions.",
        });
      });
  }, []);

  const fetchTransactions = (
    closingBranch = "",
    fromDate = "",
    toDate = ""
  ) => {
    axios
      .get(
        "https://testapi.vlnidhi.com/api/admin/transactions/getpendingtransaction",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        let transactionsData = response.data;

        if (closingBranch || fromDate || toDate) {
          // Apply client-side filtering if any filters are provided
          transactionsData = transactionsData.filter((txn) => {
            const txnDate = new Date(txn.txnDate);
            const from = fromDate ? new Date(fromDate) : null;
            const to = toDate ? new Date(toDate) : null;

            return (
              (!closingBranch || txn.branch === closingBranch) &&
              (!from || txnDate >= from) &&
              (!to || txnDate <= to)
            );
          });
        }

        setTransactions(transactionsData);
        setFilteredTransactions(transactionsData);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error.response?.data?.message ||
            "An error occurred while fetching transactions.",
        });
      });
  };

  useEffect(() => {
    fetchTransactions(); // Fetch transactions when the component mounts
  }, []);

  const handleSearch = () => {
    const { closingBranch, fromDate, toDate } = formData;
    fetchTransactions(closingBranch, fromDate, toDate); // Call with parameters
    setShowTable(true);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) => {
      const newSelectedIds = new Set(prev);
      if (newSelectedIds.has(id)) {
        newSelectedIds.delete(id);
      } else {
        newSelectedIds.add(id);
      }
      return newSelectedIds;
    });
  };

  const handleApproval = () => {
    // Convert selected IDs to an array and send as JSON
    const payload = Array.from(selectedIds);

    axios
      .put(
        `https://testapi.vlnidhi.com/api/admin/transactions/transactinapproval`,
        payload, // Send array of IDs
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Transactions approved successfully!",
        });
        fetchTransactions();
      })
      .catch((error) => {
        console.error("Error approving transactions:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error.response?.data?.message || "Error approving transactions.",
        });
      });
  };

  const handleRejection = () => {
    // Convert selected IDs to an array and send as JSON
    const payload = Array.from(selectedIds);

    axios
      .put(
        `https://testapi.vlnidhi.com/api/admin/transactions/rejecttransaction`,
        payload, // Send array of IDs
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Transactions rejected successfully!",
        });
        fetchTransactions();
      })
      .catch((error) => {
        console.error("Error rejecting transactions:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error.response?.data?.message || "Error rejecting transactions.",
        });
      });
  };

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    // opacity: 0.6,
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Saving Transaction Approval
      </Typography>
      <Paper
        sx={{
          p: { md: 2, xs: 2 },
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={1}>
          {/* Branch Dropdown */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Branch :
              </Typography>
              <TextField
                select
                name="closingBranch"
                value={formData.closingBranch}
                onChange={handleFormChange}
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {closingBranches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchName}>
                    {branch.branchName} - {branch.branchCode}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          {/* From Date */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                From Date :
              </Typography>
              <TextField
                type="date"
                name="fromDate"
                value={formData.fromDate}
                onChange={handleFormChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* To Date */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                To Date :
              </Typography>
              <TextField
                type="date"
                name="toDate"
                value={formData.toDate}
                onChange={handleFormChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={handleSearch}
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  width: { xs: "100%", md: "157px" },
                  height: { md: "48px", xs: "40px" },
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                  color: "white",
                  mt: { md: 2.5, xs: 1 },
                  mb: { md: 0, xs: 2 },
                }}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {showTable && (
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "36px",
            }}
          >
            Saving Txn Data List
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TableContainer component={Paper} sx={{ borderRadius: "20px" }}>
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#413AF1" }}>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox sx={{ color: "white" }} />
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Sl No
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Account No
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Applicant Name
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Txn Date
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Inst No
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Txn Type
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Phone No
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Member Code
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Branch
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Amount
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Pay Mode
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((txn, index) => (
                    <TableRow
                      key={txn.id}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "#F2F6FA" : "#C1E0FB", // Alternate row color
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedIds.has(txn.id)}
                          onChange={() => handleCheckboxChange(txn.id)}
                        />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{txn.accountNo}</TableCell>
                      <TableCell>{txn.applicantName}</TableCell>
                      <TableCell>
                        {new Date(
                          txn.txnDate ? txn.txnDate : "N/A"
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </TableCell>
                      <TableCell>{txn.instNo ? txn.instNo : "N/A"}</TableCell>
                      <TableCell>{txn.txnType}</TableCell>
                      <TableCell>{txn.phoneNo}</TableCell>
                      <TableCell>{txn.memberCode}</TableCell>
                      <TableCell>{txn.branch}</TableCell>
                      <TableCell>{txn.amount}</TableCell>
                      <TableCell>{txn.payMode}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleApproval}
              disabled={selectedIds.size === 0}
              sx={{ marginRight: 2 }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleRejection}
              disabled={selectedIds.size === 0}
            >
              Reject
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SavingTransactionApproval;
