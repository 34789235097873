import {
  Alert,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import PersonIcon from "@mui/icons-material/Person";
import Swal from "sweetalert2";

function SBIntraTransfer() {
  const [accounts, setAccounts] = useState([]);
  const [debitAccount, setDebitAccount] = useState("");
  console.log("debitAccount", debitAccount);
  const [creditAccount, setCreditAccount] = useState("");
  console.log("creditAccount", creditAccount);
  const [debitDetails, setDebitDetails] = useState({});
  const [creditDetails, setCreditDetails] = useState({});
  const [error, setError] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [errors, setErrors] = useState({
    debitAccount: "",
    creditAccount: "",
    transferAmount: "",
  });
  const validateFields = () => {
    const newErrors = {};

    if (!debitAccount) {
      newErrors.debitAccount = "Debit account is required.";
    }
    if (!creditAccount) {
      newErrors.creditAccount = "Credit account is required.";
    }
    if (!transferAmount) {
      newErrors.transferAmount = "Amount is required.";
    } else if (isNaN(transferAmount) || transferAmount <= 0) {
      newErrors.transferAmount = "Amount must be a positive number.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const axiosInstance = axios.create({
    baseURL: "https://testapi.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });

  const handleSave = async () => {
    if (!validateFields()) return;
    try {
      await axiosInstance.post("/admin/trans/transfer", {
        debitAccountNumber: debitAccount,
        creditAccountNumber: creditAccount,
        transferAmount,
        remarks,
      });
      fetchAccounts();
      Swal.fire("Success", "Transfer successful!", "success");
      resetForm();
    } catch (error) {
      console.error("Transfer Failed..", error);
      Swal.fire({
        icon: "error",
        title: "Transfer Failed..",
        text: error.response?.data?.message || "Failed..",
      });
    }
  };

  const fetchAccounts = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/saving-accounts/getAll");
      setAccounts(response.data);
    } catch (error) {
      console.error("Error fetching saving accounts:", error);
    }
  }, []);

  useEffect(() => {
    fetchAccounts();
  }, []);

  // Handle account selection for Debit
  const handleDebitSelect = (accountNumber) => {
    if (accountNumber === creditAccount) {
      setError(
        "Debit Account Number and Credit Account Number should not be equal"
      );
      return;
    }

    setDebitAccount(accountNumber);
    const selectedAccount = accounts.find(
      (account) => account.virtualAccNumber === accountNumber
    );
    setDebitDetails({
      memberCode: selectedAccount?.member?.memberCode || "N/A",
      accountBranch: `${selectedAccount?.branch?.branchName || "Unknown"} - ${
        selectedAccount?.branch?.branchCode || "N/A"
      }`,
      availableBalance: selectedAccount?.openingAmount || "N/A",
      mobileNo: selectedAccount?.member?.mobileNo || "N/A",
    });
    setError(""); // Clear any previous error
  };

  // Handle account selection for Credit
  const handleCreditSelect = (accountNumber) => {
    if (accountNumber === debitAccount) {
      setError(
        "Debit Account Number and Credit Account Number should not be equal"
      );
      return;
    }

    setCreditAccount(accountNumber);
    const selectedAccount = accounts.find(
      (account) => account.virtualAccNumber === accountNumber
    );
    setCreditDetails({
      memberCode: selectedAccount?.member?.memberCode || "N/A",
      accountBranch: `${selectedAccount?.branch?.branchName || "Unknown"} - ${
        selectedAccount?.branch?.branchCode || "N/A"
      }`,
      availableBalance: selectedAccount?.openingAmount || "N/A",
      mobileNo: selectedAccount?.member?.mobileNo || "N/A",
    });
    setError(""); // Clear any previous error
  };

  const resetForm = () => {
    setDebitAccount("");
    setCreditAccount("");
    setDebitDetails({});
    setCreditDetails({});
    setError("");
    setTransferAmount("");
    setRemarks("");
    setErrors({ debitAccount: "", creditAccount: "", transferAmount: "" });
  };

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };
  const fontStyle = {
    fontSize: "14px",
    width: "100%",
  };
  const buttonStyle = {
    width: { xs: "100%", md: "157px" },
    height: { md: "48px", xs: "40px" },
    borderRadius: "10px",
    fontWeight: "bold",
    mt: { md: 2.5, xs: 2 },
    mb: { md: 0, xs: 2 },
  };
  const paperHeader = {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  };
  const paperStyle = {
    padding: 3,
    borderRadius: "20px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    "@media (max-width: 600px)": {
      padding: "10px",
    },
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Intra Account Transfer
      </Typography>

      <Box mt={1} mb={1}>
        {error && (
          <Box>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
      </Box>

      {/* Before Payment */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          gap: 3,
        }}
      >
        <Paper sx={paperStyle}>
          {/* Debit Account Details */}
          <Grid container spacing={1}>
            <Typography sx={paperHeader}>Debit Account Details</Typography>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={fontStyle}>
                  Select Account No. <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  select
                  value={debitAccount}
                  onChange={(e) => handleDebitSelect(e.target.value)}
                  error={!!errors.debitAccount}
                  helperText={errors.debitAccount}
                  sx={textFieldStyle}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                >
                  {accounts.map((account) => (
                    <MenuItem key={account.id} value={account.virtualAccNumber}>
                      {`${account.member?.memberName || "Unknown"} - ${
                        account.virtualAccNumber
                      }`}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={fontStyle}>
                  Member Code<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  value={debitDetails.memberCode || ""}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={fontStyle}>
                  Account Branch<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  value={debitDetails.accountBranch || ""}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={fontStyle}>
                  Available Balance<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  value={debitDetails.availableBalance || ""}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={fontStyle}>
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  value={debitDetails.mobileNo || ""}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Paper sx={paperStyle}>
          {/* Credit Account Details */}
          <Grid container spacing={1}>
            <Typography sx={paperHeader}>Credit Account Details</Typography>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={fontStyle}>
                  Select Account No.<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  select
                  value={creditAccount}
                  onChange={(e) => handleCreditSelect(e.target.value)}
                  error={!!errors.creditAccount}
                  helperText={errors.creditAccount}
                  sx={textFieldStyle}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                >
                  {accounts.map((account) => (
                    <MenuItem key={account.id} value={account.virtualAccNumber}>
                      {`${account.member?.memberName || "Unknown"} - ${
                        account.virtualAccNumber
                      }`}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={fontStyle}>
                  Member Code<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  value={creditDetails.memberCode || ""}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={fontStyle}>
                  Account Branch<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  value={creditDetails.accountBranch || ""}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={fontStyle}>
                  Available Balance<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  value={creditDetails.availableBalance || ""}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={fontStyle}>
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  value={creditDetails.mobileNo || ""}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {/* Payment Details */}
      <Paper sx={{ ...paperStyle, mt: 3 }}>
        <Typography sx={paperHeader}>Payment Details</Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={fontStyle}>Transfer Date</Typography>
              <TextField
                type="date"
                disabled
                value={new Date().toISOString().split("T")[0]}
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={fontStyle}>Remarks</Typography>
              <TextField
                sx={textFieldStyle}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={fontStyle}>
                Amount<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                type="number"
                sx={textFieldStyle}
                value={transferAmount}
                onChange={(e) => setTransferAmount(e.target.value)}
                error={!!errors.transferAmount}
                helperText={errors.transferAmount}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>
        {/* Button */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            onClick={handleSave}
            sx={{
              ...buttonStyle,
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
              background: "#DFBF41",
            }}
          >
            Save
          </Button>

          <Button
            onClick={resetForm}
            sx={{
              ...buttonStyle,
              "&:hover": { backgroundColor: "#17809a" },
              color: "white",
              background: "#05ACD6",
            }}
          >
            New
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default SBIntraTransfer;
