import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";

function AdvisorDownline() {
  const token = sessionStorage.getItem("token");
  const [data, setData] = useState([]);
  const [advisorCode, setAdvisorCode] = useState("");
  const [advisorName, setAdvisorName] = useState("");
  const [position, setPosition] = useState("");

  // Fetch data based on the advisor code
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://testapi.vlnidhi.com/api/admin/Downline/details/${advisorCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to headers
          },
        }
      );

      setData([response.data]);
      setAdvisorName(response.data.advisorName);
      setPosition(response.data.position);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data");
    }
  };

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    // opacity: 0.6,
  };
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Advisor Downline
      </Typography>

      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={1}>
          {/* Branch Dropdown */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Advisor Code :
              </Typography>
              <TextField
                value={advisorCode}
                onChange={(e) => setAdvisorCode(e.target.value)}
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Advisor Name :
              </Typography>
              <TextField
                disabled
                value={advisorName}
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Position :
              </Typography>
              <TextField
                disabled
                value={position}
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={fetchData}
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  width: { xs: "100%", md: "157px" },
                  height: { md: "48px", xs: "40px" },
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                  color: "white",
                  mt: { md: 2.5, xs: 1 },
                  mb: { md: 0, xs: 2 },
                }}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ mt: 2, p: 2, borderRadius: "20px", minHeight: "350px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 2,
            mt: 0,
          }}
        >
          DOWNLINE REPORT OF CODE -
        </Typography>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px 20px 0px 0px",
              width: { md: "100%", xs: "296px" },
            }}
          >
            <Table aria-label="simple table" size="small">
              <TableHead sx={{ background: "#413AF1" }}>
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Sl No
                  </TableCell>

                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    A.Code
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    A.Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Positon
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    D.O.J
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Intro Code
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Intro Name
                  </TableCell>

                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.slNo}</TableCell>
                    <TableCell>{row.advisorCode}</TableCell>
                    <TableCell>{row.advisorName}</TableCell>
                    <TableCell>{row.position}</TableCell>
                    <TableCell>{row.doj}</TableCell>
                    <TableCell>{row.introducerCode}</TableCell>
                    <TableCell>{row.introducerName}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}

export default AdvisorDownline;
