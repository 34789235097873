import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Button,
  MenuItem,
  Grid,
  FormControl,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  Checkbox,
  TextField,
  TableContainer,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";

const CertificateReissue = () => {
  const [branch, setBranch] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    // Fetch all accounts
    axios
      .get("https://testapi.vlnidhi.com/api/admin/saving-accounts/getAll", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching account data:", error);
      });
  }, [token]);

  const handleSearchClick = () => {
    if (branch) {
      const account = accounts.find(
        (acc) => acc.savingAccountNumber === branch
      );
      setSelectedAccount(account);
      fetchTransactionData(account.savingAccountNumber);
      setShowTable(true);
    }
  };

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
    setShowTable(false);
  };

  const fetchTransactionData = (accountNumber) => {
    axios
      .get(
        `https://testapi.vlnidhi.com/api/admin/transactions/getpassbook/${accountNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching transaction data:", error);
      });
  };

  const printRef = useRef();

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          fontFamily: "Poppins",
          mb: 1,
        }}
      >
        Certificate Re-issue
      </Typography>

      {/* Form Paper */}
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Details for Print
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: { xs: 1, sm: 0.5 },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <Box>
              <Typography
                sx={{
                  // width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "14px",
                  //   opacity: 0.6,
                  mb: 1,
                }}
              >
                Select by Policy No.<span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <Box>
              <FormControl
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  flex: 1,
                }}
              >
                <TextField
                  select
                  value={branch}
                  onChange={handleBranchChange}
                  sx={{
                    width: { xs: "100%", md: "100%" },
                    height: "48px",
                    borderColor: "#D9D9D9CC",
                    backgroundColor: "#FFFFFF",
                    "& .MuiInputBase-root": {
                      height: "48px",
                    },
                  }}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                >
                  {accounts.map((account) => (
                    <MenuItem
                      key={account.savingAccountNumber}
                      value={account.savingAccountNumber}
                    >
                      {`${account.member.memberName} (${account.savingAccountNumber})`}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Box>
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              marginLeft: { xs: 0, sm: "4%" },
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              flex: 1,
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#DFBF41",
                ":hover": { background: "#9d893a" },
                color: "white",
                borderRadius: "10px",
                width: "106px",
                textTransform: "none",
              }}
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* Table Paper */}
      {showTable && transactions.length > 0 && (
        <Paper
          elevation={3}
          sx={{ marginTop: 2, padding: 2, borderRadius: "20px" }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 1,
              mt: 1,
            }}
          >
            Data List
          </Typography>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "10px 10px 0px 0px",
              width: { xs: "320px", md: "100%", lg: "100%" },
            }}
          >
            <Table>
              <TableHead
                sx={{
                  backgroundColor: "#413AF1",
                  padding: "13px 46px 13px 46px",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Policy No
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Advisor Code
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Member Code
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Policy Date
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Plan Code
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Branch
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Print Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Checkbox
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction, index) => (
                  <TableRow key={transaction.transactionId}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{transaction.transactionDate}</TableCell>
                    <TableCell>{transaction.accountNo}</TableCell>
                    <TableCell>{transaction.credit}</TableCell>
                    <TableCell>{transaction.debit}</TableCell>
                    <TableCell>{transaction.balance}</TableCell>
                    <TableCell>{transaction.transactionId}</TableCell>
                    <TableCell>{transaction.status}</TableCell>
                    <TableCell>{transaction.status}</TableCell>
                    <TableCell>
                      <Checkbox disabled />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
          >
            <Button
              variant="contained"
              sx={{
                background: "#92230C",
                borderRadius: "10px",
                textTransform: "none",
                ":hover": { background: "#742b2b" },
              }}
            >
              Print Certificate
            </Button>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default CertificateReissue;
