import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Divider,
  IconButton,
  Modal,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VisibilityIcon from "@mui/icons-material/Visibility";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import vllogo from "../../Assets/logo.png";
import DownloadIcon from "@mui/icons-material/Download";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontSize: { xs: "12px", md: "14px" },
  },
  receiptLabel: {
    fontSize: "14px",
    opacity: 0.6,
  },
  receiptLabelValue: {
    fontSize: "14px",
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "180px" },
    height: "40px",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
  signatory: {
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "center",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 950,
    // bgcolor: "background.paper",
    // border: "2px solid #000",
    // boxShadow: 24,
    p: 2,
  },
};

const InfoRow = ({ label, value }) => (
  <Grid container sx={{ mb: 1 }}>
    <Grid item xs={6}>
      <Typography sx={styles.receiptLabel}>{label}</Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography sx={styles.receiptLabelValue}>{value}</Typography>
    </Grid>
  </Grid>
);

const RecurringReceipt = () => {
  const [allPolicyCode, setAllPolicyCode] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [policyData, setPolicyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [policyCodePrefix, setPolicyCodePrefix] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const modalContentRef = useRef();

  const [open, setOpen] = useState(false);
  const handleOpen = (data) => {
    setSelectedRowData(data);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const downloadPdf = async () => {
    console.log("Modal Content Ref:", modalContentRef.current);
    if (!modalContentRef.current) {
      console.log("Modal content reference is not found.");
      return;
    }

    try {
      const element = modalContentRef.current;
      const canvas = await html2canvas(element, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("Policy_Receipt.pdf");
      console.log("PDF saved successfully");
    } catch (error) {
      console.log("Error generating PDF:", error);
    }
  };

  const axiosInstance = axios.create({
    baseURL: "https://testapi.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });

  const fetchAllPolicyCode = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/investment/all/policy");
      setAllPolicyCode(response.data);
    } catch (error) {
      console.error("Error fetching policy codes:", error.message);
    }
  }, []);

  const fetchPolicyData = async (policyNo) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/admin/investment/policyinvest?policyNo=${policyNo}`
      );

      setPolicyData(response.data);
    } catch (error) {
      console.error("Error fetching policy data:", error.message);
      toast.warn("Error fetching policy data.");
      setPolicyData("");
    } finally {
      setLoading(false);
    }
  };

  const handlePolicyChange = (policyNo) => {
    setSelectedPolicy(policyNo);
    fetchPolicyData(policyNo);
    const prefix = policyNo.slice(0, 5);
    setPolicyCodePrefix(prefix);
  };

  useEffect(() => {
    fetchAllPolicyCode();
  }, [fetchAllPolicyCode]);

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Box>
      <Typography sx={styles.header}>Renewal Receipt</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>
          Search Details for Print
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Select Policy No. <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedPolicy}
              onChange={(e) => handlePolicyChange(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              {allPolicyCode.map((policy) => (
                <MenuItem key={policy.policyCode} value={policy.policyCode}>
                  {`${policy.applicantName} - ${policy.policyCode}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Data List</Typography>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px",
              width: { md: "100%", xs: "296px", sm: "400px" },
              overflowX: "auto",
            }}
          >
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#413AF1" }}>
                <TableRow>
                  {[
                    "PolicyNo",
                    "INSTNO",
                    "Date",
                    "ApplicantName",
                    "Plan",
                    "Branch",
                    "Term",
                    "Amount",
                    "View",
                  ].map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : policyData.length > 0 ? (
                  policyData.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{data?.policyCode || "-"}</TableCell>
                      <TableCell>{data?.installmentNo || "-"}</TableCell>
                      <TableCell>
                        {new Date(data?.renewalDate || "-").toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </TableCell>
                      <TableCell>{data?.applicantName || "-"}</TableCell>
                      <TableCell>{data?.planCode || "-"}</TableCell>
                      <TableCell>
                        {data?.addInvestMent?.branchName || "-"}
                      </TableCell>
                      <TableCell>{data?.term || "-"}</TableCell>
                      <TableCell>{data?.amount || "-"}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleOpen(data)}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>

      <Modal open={open} onClose={handleClose}>
        <Paper
          ref={modalContentRef}
          sx={{ ...styles.paper, mt: 2, ...styles.modalStyle }}
        >
          <Box sx={{ mb: 1 }}>
            <Grid container alignItems="center">
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box sx={{ mr: 2 }}>
                  <img
                    src={vllogo}
                    alt="vllogo"
                    style={{ height: "80px", width: "80px" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    <Typography
                      sx={{
                        ...styles.header,
                        fontSize: { xs: "16px", md: "20px" },
                      }}
                    >
                      VENKETLAXMI URBAN PUNE NIDHI LTD
                    </Typography>
                    <Typography sx={{ fontFamily: "Poppins" }}>
                      Reg. Office: SECOND FLOOR, GOLDEN EMPIRE, BESIDE CROMA,
                      BANER ROAD, BANER - MAHARASHTRA - 411045
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton onClick={downloadPdf}>
                      <DownloadIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ mt: 0, mb: 2, borderBottomWidth: 2 }} />
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Typography fontWeight={700}>NEW RECEIPT</Typography>
          </Box>

          {selectedRowData ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InfoRow
                    label="Installment No:"
                    value={selectedRowData?.installmentNo || "-"}
                  />
                  <InfoRow
                    label="Branch:"
                    value={
                      selectedRowData?.addInvestMent?.branch?.branchName || "-"
                    }
                  />
                  <InfoRow
                    label="Policy No. :"
                    value={selectedRowData.policyCode || "-"}
                  />
                  <InfoRow
                    label="Member Name :"
                    value={selectedRowData.applicantName || "-"}
                  />
                  <InfoRow
                    label="Address :"
                    value={`${
                      selectedRowData?.addInvestMent?.member?.address || ""
                    }, ${
                      selectedRowData?.addInvestMent?.member?.district || ""
                    }, ${
                      selectedRowData?.addInvestMent?.member?.state || ""
                    }, ${
                      selectedRowData?.addInvestMent?.member?.pincode || ""
                    }`}
                  />
                  <InfoRow
                    label="Nominee Name:"
                    value={selectedRowData?.addInvestMent?.nomineeName || ""}
                  />
                  <InfoRow
                    label="Advisor/Collector Name:"
                    value={`${
                      selectedRowData?.addInvestMent?.addCollector
                        ?.advisorName || ""
                    } - ${
                      selectedRowData?.addInvestMent?.addCollector
                        ?.advisorCode || ""
                    }`}
                  />
                  <InfoRow
                    label="Next Due Date :"
                    value={new Date(
                      selectedRowData.nextDueDate || "-"
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InfoRow
                    label="Print Date :"
                    value={getCurrentDate() || ""}
                  />
                  <InfoRow
                    label="Transaction Date :"
                    value={new Date(
                      selectedRowData.renewalDate || "-"
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  />
                  <InfoRow
                    label="Maturity Date :"
                    value={new Date(
                      selectedRowData?.addInvestMent?.maturityDate || "-"
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  />

                  <InfoRow
                    label="Scheme Type :"
                    value={selectedRowData?.addInvestMent?.schemeType || "-"}
                  />
                  <InfoRow label="Term :" value={selectedRowData.term || "-"} />
                  <InfoRow
                    label="Payment Mode :"
                    value={selectedRowData?.addInvestMent?.paymentBy || "-"}
                  />
                  <InfoRow
                    label="Payment Amount :"
                    value={selectedRowData.amount || "-"}
                  />
                  <InfoRow label="Consideration Amount :" value="120000" />
                  <InfoRow
                    label="Maturity Amount :"
                    value={
                      selectedRowData?.addInvestMent?.maturityAmount || "-"
                    }
                  />
                  <InfoRow
                    label="Total Deposit :"
                    value={selectedRowData.netDeposited || "-"}
                  />
                  {/* <InfoRow label="Late Fine :" value="0" /> */}
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography>No Data Available</Typography>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Box>
              <Divider sx={{ borderBottomWidth: 2, background: "black" }} />
              <Typography sx={styles.signatory}>
                Authorized signatory
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

export default RecurringReceipt;
