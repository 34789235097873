import {
  Container,
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  FormControl,
  Select,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

// Validation schema using Yup
const validationSchema = Yup.object({
  schemeName: Yup.string().required("Scheme Name is required"),
  effectiveDate: Yup.date().required("Effective Date is required"),
  schemeCreatedBy: Yup.string().required("Scheme Created By is required"),
  annualRoi: Yup.number()
    .required("Annual ROI is required")
    .min(0, "Must be a non-negative number"),
  minOpeningAmount: Yup.number()
    .required("Min Opening Amount is required")
    .positive("Must be a positive number"),
  monthlyAvgBalance: Yup.number()
    .required("Monthly Avg Balance is required")
    .positive("Must be a positive number"),
  lockInBalance: Yup.number()
    .required("Lock In Balance is required")
    .positive("Must be a positive number"),
  smsCharges: Yup.number()
    .required("SMS Charges is required")
    .positive("Must be a positive number"),
  smsInterval: Yup.string().required("SMS Interval is required"),
  freeIfscCollection: Yup.number()
    .required("Free IFSC Collection is required")
    .positive("Must be a positive number"),
  freeTransfers: Yup.number()
    .required("Free Transfers is required")
    .positive("Must be a positive number"),
  singleTxnLimit: Yup.number()
    .required("Single Transaction Limit is required")
    .positive("Must be a positive number"),
  dailyMaxLimit: Yup.number()
    .required("Daily Max Limit is required")
    .positive("Must be a positive number"),
  weeklyMaxLimit: Yup.number()
    .required("Weekly Max Limit is required")
    .positive("Must be a positive number"),
  monthlyMaxLimit: Yup.number()
    .required("Monthly Max Limit is required")
    .positive("Must be a positive number"),
  serviceCharges: Yup.number()
    .required("Service Charges is required")
    .positive("Must be a positive number"),
  // cardCharge: Yup.number()
  //   .required("Card Charges is required")
  //   .positive("Must be a positive number"),
  serviceInterval: Yup.string().required("Service Interval is required"),
  // cardLimitMonthly: Yup.number()
  //   .required("Card Limit Monthly is required")
  //   .min(0, "Must be a non-negative number"),
  // cardTotalLimitYearly: Yup.number()
  //   .required("Card Total Limit Yearly is required")
  //   .min(0, "Must be a non-negative number"),
});

const SavingPlanMaster = () => {
  const [savingPlans, setSavingPlans] = useState([]);
  const token = sessionStorage.getItem("token");

  const fetchSavingPlans = async () => {
    try {
      const response = await axios.get(
        "https://testapi.vlnidhi.com/api/admin/savings-account-schemes/get-all-saving-account-schemes",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSavingPlans(response.data);
    } catch (error) {
      console.error("Error fetching saving plans:", error);
    }
  };

  useEffect(() => {
    fetchSavingPlans();
  }, []);

  const textFieldStyle = {
    width: { xs: "100%", lg: "100%" },
    height: "48px",
    borderRadius: "10px",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    width: "100%",
  };

  const handleXlsDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(savingPlans); // Convert table data to sheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Unalloted Shares"); // Append the sheet
    XLSX.writeFile(workbook, "Scheme-List.xlsx"); // Save the file
  };

  const contentRef = useRef(null);

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          fontFamily: "Poppins",
          mb: 1,
        }}
      >
        Saving Plan Master
      </Typography>

      {/* Form */}
      <Paper sx={{ padding: "2%", marginTop: "1%", borderRadius: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
          }}
        >
          Saving Plan Details
        </Typography>

        <Formik
          initialValues={{
            schemeName: "",
            effectiveDate: "",
            schemeCreatedBy: "",
            annualRoi: "",
            minOpeningAmount: "",
            monthlyAvgBalance: "",
            lockInBalance: "",
            smsCharges: "",
            smsInterval: "Monthly",
            freeIfscCollection: "",
            singleTxnLimit: "",
            dailyMaxLimit: "",
            weeklyMaxLimit: "",
            monthlyMaxLimit: "",
            serviceCharges: "",
            serviceInterval: "Monthly",
            cardLimitMonthly: "",
            cardTotalLimitYearly: "",
            freeTransfers: "",
            cardCharge: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              const response = await axios.post(
                "https://testapi.vlnidhi.com/api/admin/savings-account-schemes/add-saving-account-schemes",
                values,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              fetchSavingPlans();
              resetForm();
              Swal.fire({
                icon: "success",
                title: "Success",
                text: response?.data?.message || "Scheme created successfully",
              });
            } catch (error) {
              console.error("Error adding saving plan:", error);
              Swal.fire({
                icon: "error",
                title: "Error",
                text:
                  error.response?.data?.message ||
                  "Failed to create saving plan. Please try again.",
              });
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Scheme Name<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="schemeName"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(touched.schemeName && errors.schemeName)}
                    helperText={<ErrorMessage name="schemeName" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Effective Date<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    type="date"
                    name="effectiveDate"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.effectiveDate && errors.effectiveDate
                    )}
                    helperText={<ErrorMessage name="effectiveDate" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Scheme Created By<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="schemeCreatedBy"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.schemeCreatedBy && errors.schemeCreatedBy
                    )}
                    helperText={<ErrorMessage name="schemeCreatedBy" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Annual R.O.I (%)<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="annualRoi"
                    // // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(touched.annualRoi && errors.annualRoi)}
                    helperText={<ErrorMessage name="annualRoi" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Minimum Opening Amount
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="minOpeningAmount"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.minOpeningAmount && errors.minOpeningAmount
                    )}
                    helperText={<ErrorMessage name="minOpeningAmount" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Monthly Average Balance
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="monthlyAvgBalance"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.monthlyAvgBalance && errors.monthlyAvgBalance
                    )}
                    helperText={<ErrorMessage name="monthlyAvgBalance" />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Lock-In Balance<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="lockInBalance"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.lockInBalance && errors.lockInBalance
                    )}
                    helperText={<ErrorMessage name="lockInBalance" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    SMS Charges<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="smsCharges"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(touched.smsCharges && errors.smsCharges)}
                    helperText={<ErrorMessage name="smsCharges" />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    SMS Interval<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth>
                    {/* <InputLabel id="sms-interval-label">
                      SMS Interval
                    </InputLabel> */}
                    <Field
                      as={Select}
                      // labelId="sms-interval-label"
                      id="smsInterval"
                      name="smsInterval"
                      sx={{
                        ...textFieldStyle,
                      }}
                      inputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      error={Boolean(touched.smsInterval && errors.smsInterval)}
                    >
                      <MenuItem value="Monthly">Monthly</MenuItem>
                      <MenuItem value="Quarterly">Quarterly</MenuItem>
                      <MenuItem value="Half Yearly">Half Yearly</MenuItem>
                      <MenuItem value="Yearly">Yearly</MenuItem>
                    </Field>
                    {touched.smsInterval && errors.smsInterval && (
                      <FormHelperText sx={{ mb: -2.5 }} error>
                        {errors.smsInterval}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Free IFSC Collection(P.M)
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="freeIfscCollection"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.freeIfscCollection && errors.freeIfscCollection
                    )}
                    helperText={<ErrorMessage name="freeIfscCollection" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Free Transfers(p.m)<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="freeTransfers"
                    // // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.freeTransfers && errors.freeTransfers
                    )}
                    helperText={<ErrorMessage name="freeTransfers" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Single Transaction Limit
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="singleTxnLimit"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.singleTxnLimit && errors.singleTxnLimit
                    )}
                    helperText={<ErrorMessage name="singleTxnLimit" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Daily Max Limit<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="dailyMaxLimit"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.dailyMaxLimit && errors.dailyMaxLimit
                    )}
                    helperText={<ErrorMessage name="dailyMaxLimit" />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Weekly Max Limit<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="weeklyMaxLimit"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.weeklyMaxLimit && errors.weeklyMaxLimit
                    )}
                    helperText={<ErrorMessage name="weeklyMaxLimit" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Monthly Max Limit<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="monthlyMaxLimit"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.monthlyMaxLimit && errors.monthlyMaxLimit
                    )}
                    helperText={<ErrorMessage name="monthlyMaxLimit" />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Service Charges<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="serviceCharges"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.serviceCharges && errors.serviceCharges
                    )}
                    helperText={<ErrorMessage name="serviceCharges" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Service Interval<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth>
                    {/* <InputLabel id="service-interval-label">
                      Service Interval
                    </InputLabel> */}
                    <Field
                      as={Select}
                      // labelId="service-interval-label"
                      id="serviceInterval"
                      name="serviceInterval"
                      sx={{
                        ...textFieldStyle,
                      }}
                      inputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      error={Boolean(
                        touched.serviceInterval && errors.serviceInterval
                      )}
                    >
                      <MenuItem value="Monthly">Monthly</MenuItem>
                      <MenuItem value="Quarterly">Quarterly</MenuItem>
                      <MenuItem value="Half Yearly">Half Yearly</MenuItem>
                      <MenuItem value="Yearly">Yearly</MenuItem>
                    </Field>
                    {touched.serviceInterval && errors.serviceInterval && (
                      <FormHelperText sx={{ mb: -2.5 }} error>
                        {errors.serviceInterval}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Card Charge<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="cardCharge"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(touched.cardCharge && errors.cardCharge)}
                    helperText={<ErrorMessage name="cardCharge" />}
                  />
                </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Card Limit Monthly<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="cardLimitMonthly"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.cardLimitMonthly && errors.cardLimitMonthly
                    )}
                    helperText={<ErrorMessage name="cardLimitMonthly" />}
                  />
                </Grid> */}

                {/* <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Card Total Limit Yearly
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="cardTotalLimitYearly"
                    // type="number"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(
                      touched.cardTotalLimitYearly &&
                        errors.cardTotalLimitYearly
                    )}
                    helperText={<ErrorMessage name="cardTotalLimitYearly" />}
                  />
                </Grid> */}
              </Grid>

              <Grid
                container
                justifyContent="flex-end"
                sx={{
                  marginTop: "2%",
                  mt: { md: 2, xs: 3 },
                  mb: { md: 1, xs: 2 },
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    width: { xs: "100%", md: "247px" },
                    height: "48px",
                    backgroundColor: "#3029D9",
                    borderRadius: "10px",
                    padding: "12px 103px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "2px",
                    ":hover": { background: "#28238a" },
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>

      {/* Table */}
      <Paper sx={{ padding: "2%", marginTop: "2%", borderRadius: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
            }}
          >
            Scheme List
          </Typography>
          <Box>
            {/* <Button
              variant="contained"
              onClick={handleXlsDownload}
              sx={{
                width: "200px",
                borderRadius: "20px",
                textTransform: "none",
                background: "#D1D1D1",
                color: "black",
                ":hover": { background: "#c7c0c0" },
              }}
            >
              Download XLS
            </Button> */}
          </Box>
        </Box>

        <Box mt={3} ref={contentRef}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "12px",
              width: { xs: "360px", md: "100%" },
              overflowX: "auto",
            }}
          >
            <Table size="small">
              {/* size="small" makes the table dense */}
              <TableHead sx={{ backgroundColor: "#3c3cc1", height: "70px" }}>
                <TableRow sx={{ backgroundColor: "#413AF1", height: "70px" }}>
                  {[
                    "Scheme Code",
                    "Scheme Name",
                    "Int. Rate (%)",
                    "Opening Amount",
                    "Avg. Balance",
                    "Lock In Balance",
                    "SMS Mode",
                    "SMS Charges",
                    // "Card Charges",
                    // "Card Limit Mly",
                    // "Card Limit Yly",
                    // "Delete",
                    // "Edit",
                  ].map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        color: "white",
                        fontSize: { xs: "12px", md: "14px", height: "70px" },
                        textAlign: "center",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {savingPlans.map((plan, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#F2F6FA" : "#C1E0FB", // Alternate row color
                    }}
                  >
                    {[
                      "schemeCode",
                      "schemeName",
                      "annualRoi",
                      "minOpeningAmount",
                      "monthlyAvgBalance",
                      "lockInBalance",
                      "smsInterval",
                      "smsCharges",
                      // "cardCharge",
                      // "cardLimitMonthly",
                      // "cardTotalLimitYearly",
                    ].map((field, idx) => (
                      <TableCell
                        key={idx}
                        sx={{
                          fontSize: { xs: "10px", md: "14px" },
                          textAlign: "center",
                        }}
                      >
                        {plan[field]}
                      </TableCell>
                    ))}
                    {/* <TableCell sx={{ textAlign: "center" }}>
                      <IconButton>
                        <DeleteIcon sx={{ fontSize: "20px" }} />
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <IconButton>
                        <EditIcon sx={{ fontSize: "20px" }} />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default SavingPlanMaster;
