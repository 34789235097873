import React, { useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "198px" },
    fontWeight: "bold",
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
};

const ChangePassword = () => {
  const userName = sessionStorage.getItem("username");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  const toggleVisibility = (setter) => {
    setter((prev) => !prev);
  };

  const handleChangePassword = async () => {
    // Validation
    if (!oldPassword || !newPassword || !confirmPassword) {
      Swal.fire("Error", "All fields are required", "error");
      return;
    }

    if (newPassword !== confirmPassword) {
      alert("New Password and Confirm Password do not match");
      return;
    }

    try {
      const payload = {
        username: userName,
        oldPassword: oldPassword,
        newPassword: newPassword,
      };

      const response = await axios.post(
        "https://testapi.vlnidhi.com/api/ChangePassword/change-password",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Password changed successfully. Please login again.");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        sessionStorage.clear();
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      Swal.fire(
        "Error",
        error.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  return (
    <Box>
      <Typography sx={styles.header}>Change Password</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Change Password</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              User Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={userName}
              disabled
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Old Password <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              placeholder="Enter old password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              sx={styles.textField}
              type={showOldPassword ? "text" : "password"}
              InputProps={{
                style: { borderRadius: "10px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => toggleVisibility(setShowOldPassword)}
                      edge="end"
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              New Password <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              sx={styles.textField}
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                style: { borderRadius: "10px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => toggleVisibility(setShowNewPassword)}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Confirm Password <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={styles.textField}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                style: { borderRadius: "10px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => toggleVisibility(setShowConfirmPassword)}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleChangePassword}
            sx={{ ...styles.button, ...styles.saveButton, mt: 2 }}
          >
            Change Password
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ChangePassword;
