import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
const AddMoney = () => {
  const generateTxnid = () => {
    const prefix = "iPaisa";
    const timestamp = Math.floor(Date.now() / 1000); // Current time in seconds
    return `${prefix}${timestamp}`;
  };
  const [data, setData] = useState({
    phone: "7798552844",
    amount: 1,
    txnid: generateTxnid(),
    email: "example@example.com",
    surl: "https://yourdomain.com/success",
  });
  const [transactionResponses, setTransactionResponses] = useState([]);
  const [hitApi, setHitApi] = useState(true);
  const onHandleSubmit = async () => {
    try {
      // Call the API with the provided data
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/pg/genrateKey",
        data
      );
      console.log("API Response:", response.data);
      // If the API call is successful and returns a valid key, construct the redirect URL
      if (response.data && response.data.key) {
        const redirectUrl = `https://ipaisa.co.in/deeplinkpage/${response.data.key}`;
        // Open the URL in a new window
        const newWindow = window.open(redirectUrl, "_blank");
        if (newWindow) {
          const checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
              clearInterval(checkWindowClosed);
              setTimeout(() => {
                handlePaymentStatus(data.txnid);
              }, 1000); // Adjust the timeout as needed
            }
          }, 1000); // Check every second if the window is closed
          // Set a timer to close the window after 2 minutes
          setTimeout(() => {
            if (!newWindow.closed) {
              newWindow.close();
              console.log("Payment window closed after 2 minutes");
            }
          }, 120000); // 2 minutes in milliseconds
        }
      } else {
        // Handle case where the key is not present in the response
        console.log("Received data:", response.data);
      }
    } catch (error) {
      console.error("Error calling the API:", error);
      // Handle error appropriately
    }
  };
  const handlePaymentStatus = async (txnid) => {
    try {
      const token = sessionStorage.getItem("jwtToken");
      const response = await axios.get(
        `https://api.ipaisa.co.in/myapp/pg/transaction/${txnid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Update the state with the new response
      setTransactionResponses((prevResponses) => {
        const updatedResponses = [response.data, ...prevResponses];
        return updatedResponses.slice(0, 5); // Keep only the latest 5 responses
      });
      handlePaymentSuccess(response.data);
    } catch (error) {
      console.error("Error verifying payment:", error);
      handlePaymentSuccess({ status: 0, message: "Error verifying payment" });
    }
  };
  const handlePaymentSuccess = (response) => {
    console.log("Payment success response:", response.msg.status);
    if (response.msg.status === "userCancelled") {
      setHitApi(false);
      console.log(hitApi, "This is hit api");
    }
    if (response.status === 1) {
      alert("Payment successful");
    } else {
      alert("Payment failed: " + response.message);
    }
  };
  // Function to map response data for the table
  const getMappedTransactionData = (response) => {
    const responseFields = [
      "txnid",
      "firstname",
      "email",
      "phone",
      "mode",
      "error",
      "errorMessage",
      "amount",
      "netAmountDebit",
      "cashBackPercentage",
      "deductionPercentage",
      "productinfo",
      "cardType",
      "status",
    ];
    return responseFields
      .map((field) => ({ field, value: response[field] || "N/A" }))
      .filter((item) => item.value !== "N/A"); // Remove fields with 'N/A' values
  };
  return (
    <Container>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <TextField
            label="Enter Amount"
            variant="outlined"
            value={data.amount}
            onChange={(event) =>
              setData({ ...data, amount: event.target.value })
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onHandleSubmit}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Paper>
            <TableContainer component={Paper}>
              {transactionResponses.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Field</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactionResponses.flatMap((response) =>
                      getMappedTransactionData(response).map(
                        ({ field, value }) => (
                          <TableRow key={`${response.txnid}-${field}`}>
                            <TableCell>{field}</TableCell>
                            <TableCell>{value}</TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </TableBody>
                </Table>
              ) : (
                <Typography variant="h6" align="center" style={{ padding: 20 }}>
                  Records not found
                </Typography>
              )}
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
export default AddMoney;
