import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import React, { useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";

import axios from "axios";

const StyledNode = ({ name, position }) => (
  <Card
    sx={{
      padding: "0px",
      borderRadius: "8px",
      display: "inline-block",
      border: "2px solid #3f51b5",
      backgroundColor: "#3029D9",
      color: "#fff",
      textAlign: "center",
      minWidth: "80px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      mb: -1,
    }}
  >
    <CardContent
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        mx: -1,
        my: -1,
        mb: -2,
      }}
    >
      <Box
        sx={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          background: "white",
        }}
      ></Box>
      <Box>
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          {name}
        </Typography>
        <Typography sx={{ fontSize: "12px", fontWeight: 300 }}>
          {position}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

function AdvisorTree() {
  const token = sessionStorage.getItem("token");
  const [data, setData] = useState([]);
  const [advisorCode, setAdvisorCode] = useState("");
  const [advisorName, setAdvisorName] = useState("");
  const [position, setPosition] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://testapi.vlnidhi.com/api/admin/advisors/${advisorCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setData([response.data]);
      setAdvisorName(response.data.advisorName);
      setPosition(response.data.position);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Advisor Tree
      </Typography>

      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Advisor Code :
              </Typography>
              <TextField
                value={advisorCode}
                onChange={(e) => setAdvisorCode(e.target.value)}
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Advisor Name :
              </Typography>
              <TextField
                disabled
                value={advisorName}
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Position :
              </Typography>
              <TextField
                disabled
                value={position}
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={fetchData}
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  width: { xs: "100%", md: "157px" },
                  height: { md: "48px", xs: "40px" },
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                  color: "white",
                  mt: { md: 2.5, xs: 1 },
                  mb: { md: 0, xs: 2 },
                }}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ mt: 2, borderRadius: "20px", p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 3,
            }}
          >
            Organizational Hierarchy
          </Typography>
        </Box>
        <Box>
          <Tree
            lineWidth={"5px"}
            lineColor={"#746FF8"}
            lineBorderRadius={"20px"}
            label={<StyledNode name="Joon Dea" position="CEO" />}
          >
            <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />}>
              <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />} />
              <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />} />
              <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />} />
              <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />} />
            </TreeNode>
            <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />}>
              <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />} />
              <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />} />
            </TreeNode>

            <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />}>
              <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />} />
              <TreeNode label={<StyledNode name="Joon Dea" position="CEO" />} />
            </TreeNode>
          </Tree>
        </Box>
      </Paper>
    </Box>
  );
}

export default AdvisorTree;
