import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  MenuItem,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import axios from "axios";

const textFieldStyle = {
  width: { xs: "100%", md: "100%" },
  borderColor: "#D9D9D9CC",
  mb: 1,
  backgroundColor: "#FFFFFF",
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
};

const SearchSaving = () => {
  const [branches, setBranches] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [filters, setFilters] = useState({
    branch: "",
    fromDate: "",
    toDate: "",
    applicantName: "",
    accountNo: "",
    memberCode: "",
    advisorCode: "",
    schemeName: "",
  });
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const componentRef = useRef();

  // Token for authorization
  const token = sessionStorage.getItem("token");

  // Fetch branch list and account details on component mount
  useEffect(() => {
    // Fetch branches
    axios
      .get("https://testapi.vlnidhi.com/api/admin/getallbid", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setBranches(response.data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });

    // Fetch saving accounts
    axios
      .get(
        "https://testapi.vlnidhi.com/api/admin/saving-accounts/searchaccount",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching account details:", error);
      });
  }, [token]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // Handle Search button click to filter the accounts
  const handleSearch = () => {
    const filtered = accounts.filter((account) => {
      const matchesBranch =
        filters.branch === "" || account.branchName === filters.branch;
      const matchesFromDate =
        filters.fromDate === "" ||
        new Date(account.openingDate) >= new Date(filters.fromDate);
      const matchesToDate =
        filters.toDate === "" ||
        new Date(account.openingDate) <= new Date(filters.toDate);
      const matchesApplicantName =
        filters.applicantName === "" ||
        account.accountHolderName
          .toLowerCase()
          .includes(filters.applicantName.toLowerCase());
      const matchesAccountNo =
        filters.accountNo === "" ||
        account.accountNo.includes(filters.accountNo);
      const matchesMemberCode =
        filters.memberCode === "" ||
        account.memberCode.includes(filters.memberCode);
      const matchesAdvisorCode =
        filters.advisorCode === "" ||
        (account.advisorCode &&
          account.advisorCode.includes(filters.advisorCode));
      const matchesSchemeName =
        filters.schemeName === "" ||
        account.savingSchemeName
          .toLowerCase()
          .includes(filters.schemeName.toLowerCase());

      return (
        matchesBranch &&
        matchesFromDate &&
        matchesToDate &&
        matchesApplicantName &&
        matchesAccountNo &&
        matchesMemberCode &&
        matchesAdvisorCode &&
        matchesSchemeName
      );
    });

    setFilteredAccounts(filtered);
    setIsSearchClicked(true); // Show table after search is clicked
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Search Savings
      </Typography>
      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>
        <Grid container spacing={1}>
          {/* Branch Dropdown */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Branch
              </Typography>
              <TextField
                select
                name="branch"
                value={filters.branch}
                onChange={handleInputChange}
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchName}>
                    {branch.branchName} - {branch.branchCode}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          {/* From Date */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                From Date
              </Typography>
              <TextField
                type="date"
                fullWidth
                name="fromDate"
                value={filters.fromDate}
                onChange={handleInputChange}
                variant="outlined"
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* To Date */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                To Date
              </Typography>
              <TextField
                type="date"
                name="toDate"
                value={filters.toDate}
                onChange={handleInputChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* Applicant Name */}
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Applicant Name
            </Typography>
            <TextField
              name="applicantName"
              value={filters.applicantName}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          {/* Account No */}
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Account No
            </Typography>
            <TextField
              name="accountNo"
              value={filters.accountNo}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          {/* Member Code */}
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Member Code
            </Typography>
            <TextField
              name="memberCode"
              value={filters.memberCode}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          {/* Advisor/Collector Code */}
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Advisor/Collector Code
            </Typography>
            <TextField
              name="advisorCode"
              value={filters.advisorCode}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          {/* Scheme Name */}
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Scheme Name
            </Typography>
            <TextField
              name="schemeName"
              value={filters.schemeName}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            gap: "16px",
            marginTop: "10px",
            mb: { md: 0, xs: 2 },
          }}
        >
          <Button
            onClick={handleSearch}
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
            }}
          >
            Search
          </Button>

          {/* Print Button */}
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#F37D01",
                  width: { xs: "50%", md: "157px" },
                  height: "48px",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  color: "white",
                  ":hover": { background: "#9c5f1e" },
                }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Box>
      </Paper>

      {/* Conditionally Render Table only if Search Button is Clicked */}
      {isSearchClicked && (
        <Paper sx={{ marginTop: "20px", p: 3, borderRadius: "20px" }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 2,
              mt: 1,
            }}
          >
            Saving Data List
          </Typography>
          <TableContainer component={Paper} sx={{ borderRadius: "20px" }}>
            <Table
              ref={componentRef}
              aria-label="simple table"
              dense
              size="small"
            >
              <TableHead sx={{ background: "#413AF1" }}>
                <TableRow>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Account No
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Account Holder Name
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Scheme Name
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Opening Date
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Branch
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Member Code
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Advisor Code
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Balance
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAccounts.map((account, index) => (
                  <TableRow
                    key={account.accountNo}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#F2F6FA" : "#C1E0FB", // Alternate row color
                    }}
                  >
                    <TableCell>{account.accountNo}</TableCell>
                    <TableCell>{account.accountHolderName}</TableCell>
                    <TableCell>{account.savingSchemeName}</TableCell>
                    <TableCell>{account.openingDate}</TableCell>
                    <TableCell>{account.branchName}</TableCell>
                    <TableCell>{account.memberCode}</TableCell>
                    <TableCell>{account.advisorCode || "N/A"}</TableCell>
                    <TableCell>{account.accountBalance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
};

export default SearchSaving;
