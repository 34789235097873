import {
  Box,
  Button,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PersonIcon from "@mui/icons-material/Person";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "128px" },
    height: { xs: "38px", md: "48px" },
    color: "white",
    fontWeight: "bold",
  },
  saveButton: {
    background: "#DFBF41",
    ":hover": {
      background: "#a18e46",
    },
  },
  cancelButton: {
    background: "#d73925",
    ":hover": {
      background: "#a1392c",
    },
  },
};

const ImageUploadWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px solid #E0E0E0",
  borderRadius: "8px",
  width: "200px",
  height: "200px",
  backgroundColor: "#F9F9F9",
  alignSelf: "flex-start",
}));

const UploadButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  marginTop: theme.spacing(2),
  backgroundColor: "#E0E0E0",
  alignSelf: "flex-start",
}));

const Caption = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: "12px",
  color: "#BDBDBD",
  alignSelf: "flex-start",
}));

function CompanySetting() {
  const [company, setCompany] = useState("");
  const [initialCompany, setInitialCompany] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState("");
  const [signatureFile, setSignatureFile] = useState(null);
  const [signaturePreview, setSignaturePreview] = useState("");

  const axiosInstance = axios.create({
    baseURL: "https://testapi.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });

  const fetchCompany = useCallback(async () => {
    try {
      const [companyResponse, logoResponse] = await Promise.all([
        axiosInstance.get("/admin/company-settings/1"),
        axios.get("https://testapi.vlnidhi.com/api/public/getlogo"),
        axios.get("https://testapi.vlnidhi.com/api/public/getSignature"),
      ]);

      setCompany(companyResponse.data);
      setInitialCompany(companyResponse.data);

      if (logoResponse?.data) {
        setLogoPreview("https://testapi.vlnidhi.com/api/public/getlogo");
        setSignaturePreview(
          "https://testapi.vlnidhi.com/api/public/getSignature"
        );
      }
    } catch (error) {
      toast.error("Failed to fetch company details or logo!");
      console.error("Error fetching company or logo:", error.message);
    }
  }, []);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogoFile(file);
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  const handleSignChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSignatureFile(file);
      setSignaturePreview(URL.createObjectURL(file));
    }
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  const handleCancel = () => {
    setIsEditable(false);
    setCompany(initialCompany);
    setLogoPreview("https://testapi.vlnidhi.com/api/public/getlogo");
    setLogoFile(null);
    setSignaturePreview("https://testapi.vlnidhi.com/api/public/getSignature");
    setSignatureFile(null);
  };

  const handleSave = async () => {
    try {
      if (logoFile) {
        const logoFormData = new FormData();
        logoFormData.append("logo", logoFile);
        await axiosInstance.post(
          "/admin/company-settings/upload-logo",
          logoFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Logo uploaded successfully!");
        window.location.reload();
      }

      if (signatureFile) {
        const signatureFormData = new FormData();
        signatureFormData.append("signature", signatureFile);
        await axiosInstance.post(
          "/admin/company-settings/upload-signature",
          signatureFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Signature uploaded successfully!");
        window.location.reload();
      }

      const response = await axiosInstance.put(
        `/admin/company-settings/update/${company.id}`,
        company
      );
      Swal.fire("Success", "Company details updated successfully!", "success");
      setInitialCompany(company);
      setIsEditable(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred.";
      Swal.fire("Error", errorMessage, "error");
      console.error("Error during save operation:", error.message);
    }
  };

  const handleChange = (field, value) => {
    setCompany({ ...company, [field]: value });
  };

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  return (
    <Box>
      <Typography sx={styles.header}>Company Setting</Typography>
      <Paper sx={styles.paper}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={styles.sectionHeader}>Company Details</Typography>
          {!isEditable && (
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              sx={{ borderRadius: "10px", fontWeight: "bold" }}
              onClick={handleEdit}
            >
              Edit
            </Button>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>Company Name :</Typography>
            <TextField
              value={company.companyName || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("companyName", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>Short Name :</Typography>
            <TextField
              value={company.shortName || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("shortName", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>Registration Date :</Typography>
            <TextField
              type="date"
              disabled={!isEditable}
              value={company.registrationDate || ""}
              onChange={(e) => handleChange("registrationDate", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>CIN No. :</Typography>
            <TextField
              value={company.cinNo || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("cinNo", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>PAN :</Typography>
            <TextField
              value={company.pan || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("pan", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>TAN :</Typography>
            <TextField
              value={company.tan || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("tan", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>GSTIN :</Typography>
            <TextField
              value={company.gstIN || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("gstIN", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>Nominal Share Value :</Typography>
            <TextField
              value={company.nominalShareValue || ""}
              disabled={!isEditable}
              onChange={(e) =>
                handleChange("nominalShareValue", e.target.value)
              }
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>Address :</Typography>
            <TextField
              value={company.address || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("address", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>State :</Typography>
            <TextField
              value={company.state || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("state", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>Pin Code :</Typography>
            <TextField
              value={company.pinCode || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("pinCode", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>Email ID :</Typography>
            <TextField
              value={company.emailId || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("emailId", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>Authorized Capital :</Typography>
            <TextField
              value={company.authorizedCapital || ""}
              disabled={!isEditable}
              onChange={(e) =>
                handleChange("authorizedCapital", e.target.value)
              }
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>PaidUp Capital :</Typography>
            <TextField
              value={company.paidUpCapital || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("paidUpCapital", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>NOF :</Typography>
            <TextField
              value={company.nof == "0" ? "0" : company.nof || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("nof", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>Mobile No :</Typography>
            <TextField
              value={company.mobileNo || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("mobileNo", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>TDS (With PAN) :</Typography>
            <TextField
              value={
                company.tds_with_PAN == "0" ? "0" : company.tds_with_PAN || ""
              }
              disabled={!isEditable}
              onChange={(e) => handleChange("tds_with_PAN", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>TDS (Without PAN) :</Typography>
            <TextField
              value={
                company.tds_without_PAN == "0"
                  ? "0"
                  : company.tds_without_PAN || ""
              }
              disabled={!isEditable}
              onChange={(e) => handleChange("tds_without_PAN", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>GST(%):</Typography>
            <TextField
              value={company.gst == "0" ? "0" : company.gst || ""}
              disabled={!isEditable}
              onChange={(e) => handleChange("gst", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={styles.label}>TAX Deduction :</Typography>
            <TextField
              value={
                company.tax_deduction == "0" ? "0" : company.tax_deduction || ""
              }
              disabled={!isEditable}
              onChange={(e) => handleChange("tax_deduction", e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: isEditable ? "white" : "#eee",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={4}></Grid>

          {/* Logo */}
          <Grid item xs={12} md={3}>
            <Typography sx={styles.label}>Logo</Typography>
            <ImageUploadWrapper>
              <img
                style={{ width: "180px", height: "180px" }}
                src={logoPreview}
                alt="Company Logo"
              />
            </ImageUploadWrapper>
            {isEditable && (
              <>
                <UploadButton variant="contained" component="label">
                  Choose File
                  <input type="file" hidden onChange={handleLogoChange} />
                </UploadButton>
                <Caption>Photo Size: Maximum 10MB</Caption>
              </>
            )}
          </Grid>

          {/* Signature */}
          <Grid item xs={12} md={3}>
            <Typography sx={styles.label}>Signature</Typography>
            <ImageUploadWrapper>
              <img
                style={{ width: "180px", height: "180px" }}
                src={signaturePreview}
                alt="Signature"
              />
              {/* Removed PersonIcon for Signature */}
            </ImageUploadWrapper>
            {isEditable && (
              <>
                <UploadButton variant="contained" component="label">
                  Choose File
                  <input type="file" hidden onChange={handleSignChange} />
                </UploadButton>
                <Caption>Photo Size: Maximum 10MB</Caption>
              </>
            )}
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 2 }}>
          {isEditable && (
            <Box
              sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 2 }}
            >
              <Button
                sx={{ ...styles.button, ...styles.saveButton }}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                sx={{ ...styles.button, ...styles.cancelButton }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default CompanySetting;
