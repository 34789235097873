import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";

const textFieldStyle = {
  width: { xs: "100%", md: "100%" },
  height: "48px",
  borderRadius: "10px",
  borderColor: "#D9D9D9CC",
  backgroundColor: "#FFFFFF",
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
  opacity: 0.6,
};

const SearchMember = () => {
  const token = sessionStorage.getItem("token");
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [showStepper, setShowStepper] = useState(false);

  const [searchParams, setSearchParams] = useState({
    branch: "",
    fromDate: "",
    toDate: "",
    memberName: "",
    memberCode: "",
    mobileNo: "",
    aadharNo: "",
    panNo: "",
  });

  useEffect(() => {
    axios
      .get(`https://testapi.vlnidhi.com/api/admin/approvedmembers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setMembers(response.data);
        setFilteredMembers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching members data:", error);
        toast.error("Failed to fetch members data.");
      });
  }, [token]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  // Apply filtering logic based on searchParams
  const handleSearch = () => {
    const filtered = members?.filter((member) => {
      return (
        (searchParams.fromDate === "" ||
          (member.registrationDate &&
            new Date(member.registrationDate) >=
              new Date(searchParams.fromDate))) &&
        (searchParams.toDate === "" ||
          (member.registrationDate &&
            new Date(member.registrationDate) <=
              new Date(searchParams.toDate))) &&
        (searchParams.memberName === "" ||
          (member.memberName &&
            member.memberName
              .toLowerCase()
              .includes(searchParams.memberName.toLowerCase()))) &&
        (searchParams.memberCode === "" ||
          (member.memberCode &&
            member.memberCode
              .toLowerCase()
              .includes(searchParams.memberCode.toLowerCase()))) &&
        (searchParams.mobileNo === "" ||
          (member.mobileNo &&
            member.mobileNo.includes(searchParams.mobileNo))) &&
        (searchParams.aadharNo === "" ||
          (member.aadharNo &&
            member.aadharNo.includes(searchParams.aadharNo))) &&
        (searchParams.panNo === "" ||
          (member.panNo && member.panNo.includes(searchParams.panNo)))
      );
    });

    if (filtered.length === 0) {
      toast.info("No members found for the given search criteria.");
      setFilteredMembers([]);
      setShowStepper(false);
    } else {
      setFilteredMembers(filtered);
      setShowStepper(true);
    }
  };

  const handlePrint = () => {
    const printContent = document.getElementById("printable-area").innerHTML;
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write(`
        <html>
          <head>
            <title>Print Members List</title>
            <style>
              table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
              }
              th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
              h1 {
                text-align: center;
                margin-top: 20px;
              }
            </style>
          </head>
          <body>
            <h1>Members List</h1>
            ${printContent}
          </body>
        </html>
      `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Search Member
      </Typography>
      <Paper
        sx={{
          // width: "100%",
          p: 4,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 0,
              mt: 0,
            }}
          >
            Search Box
          </Typography>
          <Grid container spacing={1} sx={{ mt: 0 }}>
            {/* Branch */}
            {/* <Grid item xs={10} sm={6}> */}
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                Branch
              </Typography>
              <TextField
                select
                name="branch"
                value={searchParams.branch}
                onChange={handleInputChange}
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                <MenuItem value="MAIN OFFICE">MAIN OFFICE</MenuItem>
                <MenuItem value="BANER OFFICE">BANER OFFICE</MenuItem>
                <MenuItem value="BHAVDHAN OFFICE">BHAVDHAN OFFICE</MenuItem>
              </TextField>
            </Grid>

            {/* From Date */}
            {/* <Grid item xs={10} sm={6}> */}
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                From Date
              </Typography>
              <TextField
                type="date"
                name="fromDate"
                value={searchParams.fromDate}
                onChange={handleInputChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            {/* To Date */}
            {/* <Grid item xs={10} sm={6}> */}
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                To Date
              </Typography>
              <TextField
                type="date"
                name="toDate"
                value={searchParams.toDate}
                onChange={handleInputChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            {/* Member Name */}
            {/* <Grid item xs={10} sm={6}> */}
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                Member Name
              </Typography>
              <TextField
                name="memberName"
                value={searchParams.memberName}
                onChange={handleInputChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            {/* Member Code */}
            {/* <Grid item xs={10} sm={6}> */}
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                Member Code
              </Typography>
              <TextField
                name="memberCode"
                value={searchParams.memberCode}
                onChange={handleInputChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            {/* Mobile Number */}
            {/* <Grid item xs={10} sm={6}> */}
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                Mobile Number
              </Typography>
              <TextField
                name="mobileNo"
                value={searchParams.mobileNo}
                onChange={handleInputChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            {/* Aadhar Number */}
            {/* <Grid item xs={10} sm={6}> */}
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                Aadhar Number
              </Typography>
              <TextField
                name="aadharNo"
                value={searchParams.aadharNo}
                onChange={handleInputChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            {/* Pan Number */}
            {/* <Grid item xs={10} sm={6}> */}
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                Pan Number
              </Typography>
              <TextField
                name="panNo"
                value={searchParams.panNo}
                onChange={handleInputChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              gap: "2%",
            }}
          >
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                background: "#DFBF41",
                width: { xs: "50%", md: "157px" },
                height: "48px",
                borderRadius: "10px",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#B89B3E" },
              }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                background: "#F37D01",
                width: { xs: "50%", md: "157px" },
                height: "48px",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Box>
        </Box>
      </Paper>

      {showStepper && (
        <Paper
          sx={{
            // width: { lg: "1230px", md: "800px", sm: "600px", xs: "340px" },
            p: 4,
            mt: 4,
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box id="printable-area">
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "36px",
                mb: 1,
                mt: 2,
              }}
            >
              Search Result
            </Typography>

            {/* Table for displaying search results */}
            <TableContainer
              component={Paper}
              sx={{ mt: 4, borderRadius: "20px" }}
            >
              <Table
                // sx={{ minWidth: 650 }}
                aria-label="simple table"
                size="small"
                dense
                table
              >
                <TableHead sx={{ backgroundColor: "#5865F2", color: "#fff" }}>
                  <TableRow
                    sx={{
                      backgroundColor: "#3F3FBE",
                      "& .MuiTableCell-root": {
                        color: "#fff",
                        fontWeight: 600,
                        fontFamily: "Poppins",
                      },
                    }}
                  >
                    <TableCell>Member Code</TableCell>
                    <TableCell>Member Name</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Registration Date</TableCell>
                    <TableCell>Aadhar No</TableCell>
                    <TableCell>PAN No</TableCell>
                    <TableCell>Phone No</TableCell>
                    <TableCell>Nominee</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Branch</TableCell>
                    {/* <TableCell>View</TableCell> */}
                    {/* <TableCell>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredMembers.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={8}>No Data Available</TableCell>
                    </TableRow>
                  ) : (
                    filteredMembers.map((member, index) => (
                      <TableRow
                        key={member.memberCode}
                        sx={{
                          backgroundColor:
                            index % 2 === 0 ? "#F2F6FA" : "#C1E0FB", // Alternate row color
                        }}
                      >
                        <TableCell>{member.memberCode}</TableCell>
                        <TableCell>{member.memberName}</TableCell>
                        <TableCell>{member.gender}</TableCell>
                        <TableCell>
                          {new Date(member.registrationDate).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </TableCell>
                        <TableCell>{member.aadharNo}</TableCell>
                        <TableCell>{member.panNo}</TableCell>
                        <TableCell>{member.mobileNo}</TableCell>
                        <TableCell>{member.nomineeName}</TableCell>
                        <TableCell>{member.status}</TableCell>
                        <TableCell>{member.branchName}</TableCell>
                        {/* <TableCell>
                          {new Date(
                            member.registrationDate
                          ).toLocaleDateString()}
                        </TableCell> */}
                        {/* <TableCell>
                          <IconButton color="primary">
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default SearchMember;
