import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "247px" },
  },
  newButton: {
    backgroundColor: "#FFD700",
    color: "#000",
  },
  saveButton: {
    backgroundColor: "#3F51B5",
    color: "#fff",
  },
};

function SBTransferApproval() {
  const [advisors, setAdvisors] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filters, setFilters] = useState({
    branch: "",
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    axios
      .get("https://testapi.vlnidhi.com/api/admin/getallbid", {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      .then((response) => {
        setBranches(response.data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    const token = sessionStorage.getItem("token");

    // Make an API call to fetch pending approvals with date filtering
    axios
      .get("https://testapi.vlnidhi.com/api/admin/trans/pending", {
        params: {
          fromDate: filters.fromDate,
          toDate: filters.toDate,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAdvisors(response.data);
      })
      .catch((error) => {
        console.error("Error fetching pending approvals:", error);
      });
  };

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedAdvisors(advisors.map((advisor) => advisor.id));
    } else {
      setSelectedAdvisors([]);
    }
  };

  const handleSelectAdvisor = (id) => {
    if (selectedAdvisors.includes(id)) {
      setSelectedAdvisors(
        selectedAdvisors.filter((advisorId) => advisorId !== id)
      );
    } else {
      setSelectedAdvisors([...selectedAdvisors, id]);
    }
  };

  const handleApprove = () => {
    const token = sessionStorage.getItem("token");
    const advisorCodes = advisors
      .filter((advisor) => selectedAdvisors.includes(advisor.id))
      .map((advisor) => advisor.transferId);

    axios
      .post(
        "https://testapi.vlnidhi.com/api/admin/trans/approve",
        advisorCodes,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Approval successful:", response);
        Swal.fire("Success", "Approval successful!", "success");
        handleSearch(); // Refresh the list after approval
      })
      .catch((error) => {
        console.error("Error approving advisors:", error);
        Swal.fire({
          icon: "error",
          title: "Approval Failed..",
          text: error.response?.data?.message || "Failed..",
        });
      });
  };

  const handleReject = () => {
    const token = sessionStorage.getItem("token");
    const advisorCodes = advisors
      .filter((advisor) => selectedAdvisors.includes(advisor.id))
      .map((advisor) => advisor.transferId);

    axios
      .post(
        "https://testapi.vlnidhi.com/api/admin/trans/reject",
        advisorCodes,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Rejection successful:", response);
        Swal.fire("Success", "Rejection successful!", "success");
        handleSearch(); // Refresh the list after rejection
      })
      .catch((error) => {
        console.error("Error rejecting advisors:", error);
        Swal.fire({
          icon: "error",
          title: "Error rejecting advisor..",
          text: error.response?.data?.message || "Failed..",
        });
      });
  };

  return (
    <Box>
      <Typography sx={styles.header}>SB Transfer Approval</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Search Box</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Branch :</Typography>
              <TextField
                select
                name="branch"
                value={filters.branch}
                onChange={handleInputChange}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {`${branch.branchName} (${branch.branchCode})`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>From Date :</Typography>
            <TextField
              type="date"
              name="fromDate"
              value={filters.fromDate}
              onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>To Date :</Typography>
            <TextField
              type="date"
              name="toDate"
              value={filters.toDate}
              onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button
              onClick={handleSearch}
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                background: "#DFBF41",
                width: { xs: "100%", md: "157px" },
                height: { xs: "38px", md: "48px" },
                borderRadius: "10px",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#B89B3E" },
                color: "white",
                mt: { md: 2.3, xs: 1 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Table */}
      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>SB Transfer Data List</Typography>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px",
              width: { md: "100%", xs: "296px" },
              // maxWidth: "1210px",
              overflowX: "auto",
            }}
          >
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#413AF1" }}>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAll}
                      color="primary"
                    />
                  </TableCell>
                  {[
                    "Sl No",
                    "From AC",
                    "To AC",
                    "TDate",
                    "Txn Amount",
                    "Debit Branch",
                    "Remark",
                    "TransactionID",
                  ].map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {advisors.length > 0 ? (
                  advisors.map((advisor, index) => (
                    <TableRow key={advisor.id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedAdvisors.includes(advisor.id)}
                          onChange={() => handleSelectAdvisor(advisor.id)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{advisor.debitAccountNumber}</TableCell>
                      <TableCell>{advisor.creditAccountNumber}</TableCell>
                      <TableCell>{advisor.transferDate}</TableCell>
                      <TableCell>{advisor.transferAmount}</TableCell>
                      <TableCell>{advisor.debitAccountBranch}</TableCell>
                      <TableCell>{advisor.remarks}</TableCell>
                      <TableCell>{advisor.transferId}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      sx={{ color: "red" }}
                      colSpan={11}
                      align="center"
                    >
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Approve & Reject Buttons */}
        <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item>
            <Button
              onClick={handleApprove}
              disabled={selectedAdvisors.length === 0}
              sx={{
                height: "40px",
                width: "100px",
                backgroundColor: "green",
                color: "white",
                borderRadius: "10px",
                "&:disabled": {
                  backgroundColor: "grey",
                },
                ":hover": { background: "#196819" },
              }}
            >
              Approve
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleReject}
              disabled={selectedAdvisors.length === 0}
              sx={{
                height: "40px",
                width: "100px",
                backgroundColor: "red",
                color: "white",
                borderRadius: "10px",
                "&:disabled": {
                  backgroundColor: "grey",
                },
                ":hover": { background: "#922424" },
              }}
            >
              Reject
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default SBTransferApproval;
