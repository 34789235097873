import React, { useEffect, useState } from "react";
import {
  TextField,
  Switch,
  Button,
  MenuItem,
  Box,
  Typography,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";

const FixedDeposit = () => {
  const token = sessionStorage.getItem("token");
  const [interestType, setInterestType] = useState("Simple");
  const [minimumAmount, setMinimumAmount] = useState();
  const [interestRate, setInterestRate] = useState();
  const [term, setTerm] = useState();
  const [compoundInterval, setCompoundInterval] = useState();
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [maturityAmount, setMaturityAmount] = useState(0);
  const [planName, setPlanName] = useState("");
  const [planStatus, setPlanStatus] = useState(true);
  const [termMode, setTermMode] = useState("Monthly");
  const [commissionNew, setCommissionNew] = useState("");

  const [fdPlans, setFdPlans] = useState([]); // To store fetched data

  const fetchFdPlans = async () => {
    try {
      const response = await axios.get(
        "https://testapi.vlnidhi.com/api/admin/getallfd",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFdPlans(response.data); // Store the fetched data in state
    } catch (error) {
      console.error("Error fetching FD plans:", error);
    }
  };

  useEffect(() => {
    fetchFdPlans(); // Fetch data when the component mounts
  }, []);

  const calculateAmounts = () => {
    // Ensure values are numbers
    const principal = Number(minimumAmount);
    const rate = Number(interestRate);
    const timeInYears = Number(term) / 12;

    if (interestType === "Simple") {
      // Simple Interest Calculation
      const maturityAmountSimple =
        principal + principal * (rate / 100) * timeInYears;
      setTotalDeposit(parseFloat(principal).toFixed(2));
      setMaturityAmount(parseFloat(maturityAmountSimple).toFixed(2));
    } else {
      // Compound Interest Calculation
      const n = getCompoundFrequency(compoundInterval);
      const maturityAmountCompound =
        principal * Math.pow(1 + rate / (100 * n), n * timeInYears);
      setTotalDeposit(parseFloat(principal).toFixed(2));
      setMaturityAmount(parseFloat(maturityAmountCompound).toFixed(2));
    }
  };

  const getCompoundFrequency = (interval) => {
    switch (interval) {
      case "daily":
        return 365;
      case "monthly":
        return 12;
      case "quarterly":
        return 4;
      case "half-yearly":
        return 2;
      case "yearly":
        return 1;
      default:
        return 1; // Default to yearly if not found
    }
  };

  useEffect(() => {
    if (minimumAmount && interestRate && term) {
      calculateAmounts();
    }
  }, [minimumAmount, interestRate, term, interestType, compoundInterval]);

  // Handle form submission
  const handleSubmit = async () => {
    const requestBody = {
      planName,
      interestRate,
      termMode,
      term,
      commissionNew,
      compoundInterval,
      planStatus,
      minimumAmount,
      interestType,
    };

    try {
      const response = await axios.post(
        "https://testapi.vlnidhi.com/api/admin/createfdplan",
        requestBody,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Form submitted successfully:", response.data);
      fetchFdPlans();
      Swal.fire("Success", "Plan created successfully!", "success");

      // Reset form fields after successful submission
      setPlanName("");
      setInterestRate("");
      setTermMode("Monthly");
      setTerm("");
      setCommissionNew("");
      setPlanStatus(true);
      setMinimumAmount("");
      setTotalDeposit(0);
      setMaturityAmount(0);
      setInterestType("Simple");
      setCompoundInterval("");

      // Handle success response, e.g., show success message, reset form, etc.
    } catch (error) {
      console.error("Error submitting form:", error);
      // Swal.fire("Error", "Failed to create plan", "error");
      Swal.fire(
        "Error",
        error.response?.data?.message || "Failed to create plan",
        "error"
      );
      // Handle error response
    }
  };

  const textFieldStyle = {
    width: { xs: "100%", lg: "100%" },
    height: "48px",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
  };

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Fixed Deposit
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Plan Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Interest Type<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={interestType}
              onChange={(e) => setInterestType(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem value="Simple">Simple</MenuItem>
              <MenuItem value="Compound">Compound</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Minimum Amount<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={minimumAmount}
              onChange={(e) => setMinimumAmount(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Compound Interval<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled={interestType === "Simple"}
              select
              value={compoundInterval}
              onChange={(e) => setCompoundInterval(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: interestType === "Simple" ? "#eee" : "white",
                },
              }}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="quarterly">Quarterly</MenuItem>
              <MenuItem value="half-yearly">Half-Yearly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Interest Rate (%)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={interestRate}
              onChange={(e) => setInterestRate(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Total Deposit<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={totalDeposit}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                  fontWeight: 1000,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Term Mode<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={termMode}
              onChange={(e) => setTermMode(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem value="Monthly">Monthly</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Maturity Amount<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={maturityAmount}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                  fontWeight: 1000,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Term(in months)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={term}
              onChange={(e) => setTerm(Number(e.target.value))}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Comm. New(%)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={commissionNew}
              onChange={(e) => setCommissionNew(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Plan Status<span style={{ color: "red" }}>*</span>
            </Typography>
            <Switch
              checked={planStatus}
              onChange={(e) => setPlanStatus(e.target.checked)}
              sx={{ transform: "scale(1.5)" }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 3,
            gap: "2%",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#E8C30B",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#a99220" },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Box sx={{ mt: 3, borderRadius: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Fixed Deposit Plan
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "10px 10px 0px 0px",
            width: { xs: "320px", md: "100%", lg: "100%" },
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "#3F00FF" }}>
                <TableCell style={{ color: "#fff" }}>Plan Code</TableCell>
                <TableCell style={{ color: "#fff" }}>Plan Name</TableCell>
                <TableCell style={{ color: "#fff" }}>Term</TableCell>
                <TableCell style={{ color: "#fff" }}>ROI</TableCell>
                <TableCell style={{ color: "#fff" }}>Comm(N)</TableCell>
                <TableCell style={{ color: "#fff" }}>MinAmount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fdPlans.map((plan) => (
                <TableRow key={plan.id}>
                  <TableCell>{plan.planCode}</TableCell>
                  <TableCell>{plan.planName}</TableCell>
                  <TableCell>{plan.term}</TableCell>
                  <TableCell>{plan.interestRate}%</TableCell>
                  <TableCell>{plan.commissionNew}%</TableCell>
                  <TableCell>{plan.minimumAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default FixedDeposit;
