import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
};

function ShareApproval() {
  const [shares, setShares] = useState([]);
  console.log("shares", shares);
  const [branches, setBranches] = useState([]);
  const [selectedShares, setSelectedShares] = useState([]);
  console.log("selectedShares", selectedShares);
  const [selectAll, setSelectAll] = useState(false);
  const [filters, setFilters] = useState({
    branch: "",
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    axios
      .get("https://testapi.vlnidhi.com/api/admin/getallbid", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setBranches(response.data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    const token = sessionStorage.getItem("token");

    axios
      .get("https://testapi.vlnidhi.com/api/admin/share/pending", {
        params: {
          branch: filters.branch,
          fromDate: filters.fromDate,
          toDate: filters.toDate,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setShares(response.data);
      })
      .catch((error) => {
        console.error("Error fetching pending shares:", error);
      });
  };

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedShares(shares.map((share) => share.id));
    } else {
      setSelectedShares([]);
    }
  };

  const handleSelectShare = (id) => {
    if (selectedShares.includes(id)) {
      setSelectedShares(selectedShares.filter((shareId) => shareId !== id));
    } else {
      setSelectedShares([...selectedShares, id]);
    }
  };

  const handleApprove = () => {
    const token = sessionStorage.getItem("token");
    const transactionIds = shares
      .filter((share) => selectedShares.includes(share.id))
      .map((share) => share.transactionId); // Extract transactionId

    axios
      .put(
        "https://testapi.vlnidhi.com/api/admin/share/approve", // PUT API URL for approve
        transactionIds, // Payload: array of transactionIds
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Approval successful:", response);
        Swal.fire({
          icon: "success",
          title: "Approval Successful",
          text: "The selected shares have been approved.",
        });
        handleSearch(); // Refresh the data
      })
      .catch((error) => {
        console.error("Error approving shares:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error approving the selected shares.",
        });
      });
  };

  const handleReject = () => {
    const token = sessionStorage.getItem("token");
    const transactionIds = shares
      .filter((share) => selectedShares.includes(share.id))
      .map((share) => share.transactionId); // Extract transactionId

    axios
      .put(
        "https://testapi.vlnidhi.com/api/admin/share/reject", // PUT API URL for reject
        transactionIds, // Payload: array of transactionIds
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Rejection successful:", response);
        Swal.fire({
          icon: "success",
          title: "Rejection Successful",
          text: "The selected shares have been rejected.",
        });
        handleSearch(); // Refresh the data
      })
      .catch((error) => {
        console.error("Error rejecting shares:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error rejecting the selected shares.",
        });
      });
  };

  return (
    <Box>
      <Typography sx={styles.header}>Share Approval</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Search Box</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Branch :</Typography>
              <TextField
                select
                name="branch"
                value={filters.branch}
                onChange={handleInputChange}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {`${branch.branchName} (${branch.branchCode})`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>From Date :</Typography>
            <TextField
              type="date"
              name="fromDate"
              value={filters.fromDate}
              onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>To Date :</Typography>
            <TextField
              type="date"
              name="toDate"
              value={filters.toDate}
              onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button
              onClick={handleSearch}
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                background: "#DFBF41",
                width: { xs: "100%", md: "157px" },
                height: { xs: "38px", md: "48px" },
                borderRadius: "10px",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#B89B3E" },
                color: "white",
                mt: { md: 2.3, xs: 1 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Share Data List</Typography>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px",
              width: { md: "100%", xs: "296px" },
              overflowX: "auto",
            }}
          >
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#413AF1" }}>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAll}
                      color="primary"
                    />
                  </TableCell>
                  {[
                    "Sl No",
                    "To Member",
                    "From Member",
                    "Share Date",
                    "Share Amt",
                    "No. of Share",
                    "Branch",
                  ].map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {shares.length > 0 ? (
                  shares.map((share, index) => (
                    <TableRow key={share.id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedShares.includes(share.id)}
                          onChange={() => handleSelectShare(share.id)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {share.toMember.memberName} -{" "}
                        {share.toMember.memberCode}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {share.fromMember.memberName} -{" "}
                        {share.fromMember.memberCode}
                      </TableCell>
                      <TableCell>
                        {new Date(share.transactionDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </TableCell>
                      <TableCell>{share.transferAmount}</TableCell>
                      <TableCell>
                        {Math.floor(share.transferAmount / 10)}
                      </TableCell>

                      <TableCell>
                        {share.branch.branchName} - {share.branch.branchCode}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell sx={{ color: "red" }} colSpan={8} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item>
            <Button
              onClick={handleApprove}
              disabled={selectedShares.length === 0}
              sx={{
                height: "40px",
                width: "100px",
                backgroundColor: "green",
                color: "white",
                borderRadius: "10px",
                "&:disabled": {
                  backgroundColor: "grey",
                },
                ":hover": { background: "#196819" },
              }}
            >
              Approve
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleReject}
              disabled={selectedShares.length === 0}
              sx={{
                height: "40px",
                width: "100px",
                backgroundColor: "red",
                color: "white",
                borderRadius: "10px",
                "&:disabled": {
                  backgroundColor: "grey",
                },
                ":hover": { background: "#8B0000" },
              }}
            >
              Reject
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default ShareApproval;
