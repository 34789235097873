import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SaveIcon from "@mui/icons-material/Save";

function CodeSetting() {
  const styles = {
    paper: {
      width: "100%",
      p: 2,
      borderRadius: "20px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      px: 3,
    },
    header: {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    saveButton: {
      borderRadius: "10px",
      width: { xs: "100%", md: "89px" },
      height: { xs: "38px", md: "40px" },
      color: "white",
      textTransform: "none",
      fontWeight: "bold",
      background: "#DFBF41",
      ":hover": {
        background: "#a18e46",
      },
    },
  };

  const [codes, setCodes] = useState([]);

  const fetchData = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://testapi.vlnidhi.com/api/admin/code-settings",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCodes(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle input change for TextField and Checkbox
  const handleInputChange = (index, field, value) => {
    const updatedCodes = codes.map((code, i) =>
      i === index ? { ...code, [field]: value } : code
    );
    setCodes(updatedCodes);
  };

  // Handle save button action
  const handleSave = async (index) => {
    const token = sessionStorage.getItem("token");
    const {
      id,
      name,
      branchPrefix,
      codePrefix,
      noOfDigits,
      lastNo,
      schemeType,
    } = codes[index];

    const dataToSave = {
      name,
      branchPrefix,
      codePrefix,
      noOfDigits,
      lastNo,
      schemeType,
    };

    try {
      await axios.put(
        `https://testapi.vlnidhi.com/api/admin/code-settings/${id}`,
        dataToSave,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchData();
      console.log("Data saved successfully for ID:", id);
      Swal.fire({
        icon: "success",
        title: "Saved!",
        text: "Data has been successfully saved.",
      });
    } catch (error) {
      fetchData();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error saving the data. Please try again.",
      });
      console.error("Error saving data:", error);
    }
  };

  return (
    <Box>
      <Typography sx={styles.header}>Code Setting</Typography>
      <Paper sx={styles.paper}>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer
            sx={{ width: { md: "100%", xs: "250px" }, overflowX: "auto" }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  {[
                    "Name",
                    "Branch Prefix",
                    "Code Prefix",
                    "No. Of Digit",
                    "Last No.",
                    "Scheme Type",
                    "Preview",
                    "Action",
                  ].map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontWeight: "bold",
                        // fontSize: { md: "15px", xs: "12px" },
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {codes.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name.replace(/_/g, " ")}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.branchPrefix}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "branchPrefix",
                            e.target.checked
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={{ width: "150px" }}
                        value={row.codePrefix}
                        onChange={(e) =>
                          handleInputChange(index, "codePrefix", e.target.value)
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={{ width: "50px" }}
                        value={row.noOfDigits}
                        onChange={(e) =>
                          handleInputChange(index, "noOfDigits", e.target.value)
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={{ width: "50px" }}
                        value={row.lastNo}
                        onChange={(e) =>
                          handleInputChange(index, "lastNo", e.target.value)
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        sx={{ width: "70px", background: "#eee" }}
                        value={row.schemeType}
                        onChange={(e) =>
                          handleInputChange(index, "schemeType", e.target.value)
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        sx={{ background: "#eee" }}
                        value={row.preview}
                        onChange={(e) =>
                          handleInputChange(index, "preview", e.target.value)
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {/* <Button
                        sx={styles.saveButton}
                        onClick={() => handleSave(index)}
                      >
                        Save
                      </Button> */}
                      <IconButton onClick={() => handleSave(index)}>
                        <SaveIcon sx={{ fontSize: "30px", color: "#DFBF41" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}

export default CodeSetting;
