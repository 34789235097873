import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Button,
  MenuItem,
  Grid,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  Checkbox,
  TextField,
  TableContainer,
  Divider,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import vllogo from "../../Assets/logo.png";
import { useReactToPrint } from "react-to-print";

const DailyRDPassbook = () => {
  const [branch, setBranch] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [frontPageData, setFrontPageData] = useState(null);
  const [showFrontPage, setShowFrontPage] = useState(false);
  const [showTransactionPage, setShowTransactionPage] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [frontPageDetails, setFrontPageDetails] = useState(null);
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    // Fetch all accounts
    axios
      .get("https://testapi.vlnidhi.com/api/admin/saving-accounts/getAll", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching account data:", error);
      });
  }, [token]);

  const handleSearchClick = () => {
    if (branch) {
      const account = accounts.find(
        (acc) => acc.savingAccountNumber === branch
      );
      setSelectedAccount(account);
      fetchTransactionData(account.savingAccountNumber);
      setShowTable(true);
      setShowFrontPage(false);
      setShowTransactionPage(false);
    }
  };

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
    setShowTable(false);
    setShowFrontPage(false);
    setShowTransactionPage(false);
  };

  const handleFrontPageClick = () => {
    if (branch) {
      fetchFrontPageData(branch);
      setShowFrontPage(true);
      setShowTransactionPage(false);
      setShowTable(false);
    }
  };

  const handleTransactionClick = () => {
    if (branch) {
      const account = accounts.find(
        (acc) => acc.savingAccountNumber === branch
      );
      setSelectedAccount(account);
      fetchTransactionData(account.savingAccountNumber);
      setShowTransactionPage(true);
      setShowTable(false);
      setShowFrontPage(false);
    }
  };

  const fetchTransactionData = (accountNumber) => {
    axios
      .get(
        `https://testapi.vlnidhi.com/api/admin/transactions/getpassbook/${accountNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching transaction data:", error);
      });
  };

  const fetchFrontPageData = (accountNo) => {
    axios
      .get(
        `https://testapi.vlnidhi.com/api/admin/saving-accounts/getpassbookfront/${accountNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setFrontPageData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching front page data:", error);
      });
  };

  const printRef = useRef();

  const handleTransactionPrint = useReactToPrint({
    content: () => printRef.current, // Define the component to print
    documentTitle: "Transactions", // Customize the title of the document
  });

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          fontFamily: "Poppins",
          mb: 1,
        }}
      >
        Policy Passbook
      </Typography>

      {/* Form Paper */}
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Details for Print
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: { xs: 1, sm: 0.5 },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <Box>
              <Typography
                sx={{
                  // width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "14px",
                  // opacity: 0.6,
                  mb: 1,
                }}
              >
                Select by Policy No.<span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <Box>
              <FormControl
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  flex: 1,
                }}
              >
                <TextField
                  select
                  value={branch}
                  onChange={handleBranchChange}
                  sx={{
                    width: { xs: "125px", md: "100%" },
                    height: "48px",
                    borderRadius: "10px",
                    borderColor: "#D9D9D9CC",
                    backgroundColor: "#FFFFFF",
                    "& .MuiInputBase-root": {
                      height: "48px",
                    },
                  }}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                >
                  {accounts.map((account) => (
                    <MenuItem
                      key={account.savingAccountNumber}
                      value={account.savingAccountNumber}
                    >
                      {`${account.member.memberName} (${account.savingAccountNumber})`}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Box>
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              marginLeft: { xs: 0, sm: "4%" },
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              flex: 1,
              mt: { xs: 1, md: 3 },
              mb: { xs: 2, md: 0 },
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#DFBF41",
                ":hover": { background: "#9d893a" },
                color: "white",
                borderRadius: "10px",
                width: { md: "106px", xs: "100%" },
                textTransform: "none",
              }}
              onClick={handleSearchClick}
            >
              Search
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#d2691e",
                ":hover": { background: "#a15722" },
                color: "white",
                borderRadius: "10px",
                // width: "136px",
                width: { md: "136px", xs: "100%" },
                textTransform: "none",
              }}
              onClick={handleFrontPageClick}
            >
              Front Page
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#1e90ff",
                ":hover": { background: "#245687" },
                color: "white",
                borderRadius: "10px",
                // width: "146px",
                width: { md: "146px", xs: "100%" },
                textTransform: "none",
              }}
              onClick={handleTransactionClick}
            >
              Transaction
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* Front Page Paper */}
      {showFrontPage && frontPageData && (
        <Paper sx={{ px: 6, py: 3, mt: 2, borderRadius: "20px" }}>
          {/* Header Section */}
          <Box sx={{ marginBottom: 1 }}>
            <Grid container alignItems="center">
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box sx={{ mr: 2 }}>
                  <img
                    src={vllogo}
                    alt="vllogo"
                    style={{ height: "80px", width: "80px" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "16px", md: "20px" },
                    mb: 1,
                    fontFamily: "Poppins",
                  }}
                >
                  VENKETLAXMI URBAN PUNE NIDHI LTD
                </Typography>
                <Typography sx={{ fontFamily: "Poppins" }}>
                  Reg. Office: SECOND FLOOR, GOLDEN EMPIRE, BESIDE CROMA, BANER
                  ROAD, BANER - MAHARASHTRA - 411045
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ mt: 0, mb: 3, borderBottomWidth: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Branch & Code:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    MAIN OFFICE-001
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Policy No:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    VLFPN2247770001
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Applicant Name:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    SANJIV BABURAO LONDHE
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Father/Husband Name:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    BABURAO LONDHE
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Nominee Name:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    BABURAO LONDHE
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Address:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    NEAR BY SAVATA MALI MANDIR, BANER, PUNE, MAHARASHTRA, PUNE,
                    MAHARASHTRA-411045
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Scheme:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    DRD
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Maturity:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    37850
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Mode:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    Dly.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Renewal Amount
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    100
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    DOC:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    09/02/2024
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Member Code :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    VL00035
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Plan:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    VENKETLAXMI HONEYBEE ACCOUNT 1
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Term:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    365
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Relationship:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    Father
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Total Value:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    36500
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Mobile No.:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    9850373971
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Maturity Date:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    07/02/2025
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      opacity: 0.6,
                    }}
                  >
                    Collector Name:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    VLCLN2247770009 - SANSARE SOMESHWAR PRAKASH
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Box>
              <Divider sx={{ borderBottomWidth: 2, background: "black" }} />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Authorized signatory
              </Typography>
            </Box>
          </Box>
        </Paper>
      )}

      {/* Table Paper */}
      {showTable && transactions.length > 0 && (
        <Paper
          elevation={3}
          sx={{ marginTop: 2, padding: 2, borderRadius: "20px" }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 1,
              mt: 1,
            }}
          >
            Transaction Data List
          </Typography>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "10px 10px 0px 0px",
              width: { xs: "300px", md: "100%", lg: "100%" },
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#413AF1",
                    padding: "13px 46px 13px 46px",
                  }}
                >
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    INO
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    TXN Date
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    A/C No
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Credit
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Debit
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Balance
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    TXN ID
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Checkbox
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction, index) => (
                  <TableRow key={transaction.transactionId}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{transaction.transactionDate}</TableCell>
                    <TableCell>{transaction.accountNo}</TableCell>
                    <TableCell>{transaction.credit}</TableCell>
                    <TableCell>{transaction.debit}</TableCell>
                    <TableCell>{transaction.balance}</TableCell>
                    <TableCell>{transaction.transactionId}</TableCell>
                    <TableCell>{transaction.status}</TableCell>
                    <TableCell>
                      <Checkbox disabled />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
          >
            <Button
              variant="contained"
              startIcon={<PrintIcon />}
              sx={{
                background: "#92230C",
                borderRadius: "10px",
                textTransform: "none",
                ":hover": { background: "#742b2b" },
              }}
            >
              Re-Print
            </Button>
          </Box>
        </Paper>
      )}

      {/* Transaction Page Paper */}
      {showTransactionPage && transactions.length > 0 && (
        <Box ref={printRef}>
          <Box
            sx={{
              width: "100%",
              height: "48px",
              borderRadius: "20px 20px 0px 0px",
              backgroundColor: "#D9D9D9",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "16px",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                ml: "2%",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Transactions
            </Typography>
            <Button onClick={handleTransactionPrint}>
              <PrintIcon sx={{ color: "black" }} />
            </Button>
          </Box>
          <Box
            elevation={3}
            sx={{
              padding: 2,
              background: "white",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{ borderRadius: "20px 20px 0px 0px" }}
            >
              <Table
                sx={{
                  overflowX: "auto",
                }}
              >
                <TableBody sx={{ padding: "2" }}>
                  <TableRow
                    sx={{
                      backgroundColor: "#413AF1",
                      padding: "13px 46px 13px 46px",
                    }}
                  >
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      CREDIT
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      DEBIT
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      BALANCE
                    </TableCell>
                  </TableRow>
                  {transactions.map((transaction) => (
                    <TableRow
                      sx={{
                        // backgroundColor: "#413AF1",
                        padding: "13px 46px 13px 46px",
                      }}
                    >
                      <TableCell sx={{ textAlign: "center" }}>
                        {transaction.transactionDate}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {transaction.remark || "N/A"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {transaction.credit}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {transaction.debit}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {transaction.balance}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
            ></Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DailyRDPassbook;
