import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import * as Yup from "yup";

function AddBranch() {
  const [branches, setBranches] = useState([]);
  const [editingBranch, setEditingBranch] = useState(null); // Track branch being edited

  const styles = {
    paper: {
      width: "100%",
      p: 2,
      borderRadius: "20px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      px: 3,
    },
    header: {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    sectionHeader: {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    label: {
      fontFamily: "Poppins, sans-serif",
      fontSize: { xs: "12px", md: "14px" },
    },
    textField: {
      width: "100%",
      borderColor: "#D9D9D9CC",
      backgroundColor: "#FFFFFF",
      "& .MuiInputBase-root": {
        height: { xs: "40px", md: "48px" },
      },
    },
    button: {
      borderRadius: "10px",
      width: { xs: "100%", md: "128px" },
      height: { xs: "38px", md: "48px" },
      color: "white",
      textTransform: "none",
      fontWeight: "bold",
    },
    saveButton: {
      background: "#DFBF41",
      ":hover": {
        background: "#a18e46",
      },
    },
    deleteButton: {
      background: "#d73925",
      ":hover": {
        background: "#a1392c",
      },
    },
    newButton: {
      background: "#05ACD6",
      ":hover": {
        background: "#228097",
      },
    },
  };

  // Setting up Axios instance
  const axiosInstance = axios.create({
    baseURL: "https://testapi.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });

  // Fetch branches from API
  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  useEffect(() => {
    fetchBranches();
  }, [fetchBranches]);

  const validationSchema = Yup.object().shape({
    pin: Yup.string()
      .required("PIN is required")
      .matches(/^[0-9]{6}$/, "PIN must be 6 digits"),
    branchName: Yup.string()
      .required("Branch Name is required")
      .uppercase("Branch Name must be uppercase"),
    branchOpeningDate: Yup.date().required("Opening Date is required"),
    branchAddress: Yup.string().required("Address is required"),
    branchState: Yup.string().required("State is required"),
    contactPersonName: Yup.string().required("Contact Person is required"),
    contactNo: Yup.string()
      .required("Contact No is required")
      .matches(/^[0-9]+$/, "Contact No must be numeric"),
  });

  const formik = useFormik({
    initialValues: {
      pin: "",
      branchName: "",
      branchOpeningDate: "",
      branchAddress: "",
      branchState: "",
      contactPersonName: "",
      contactNo: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (editingBranch) {
        // Update existing branch
        await handleUpdateBranch(values);
      } else {
        // Create new branch
        await handleSaveBranch(values);
      }
      resetForm();
      setEditingBranch(null); // Reset editing state
    },
  });

  const handleSaveBranch = async (values) => {
    try {
      await axiosInstance.post("/admin/addbranch", values);
      fetchBranches();
      Swal.fire("Success", "Branch created successfully!", "success");
    } catch (error) {
      console.error("Failed to create branch:", error.message);
      Swal.fire(
        "Error",
        error.response?.data?.message || "Failed to create branch.",
        "error"
      );
    }
  };

  const handleUpdateBranch = async (values) => {
    try {
      // Using branchCode from the editingBranch object
      const response = await axiosInstance.put(
        `/admin/updatebranch/${editingBranch.branchCode}`, // Pass branchCode in the URL
        {
          branchName: values.branchName,
          branchOpeningDate: values.branchOpeningDate,
          branchAddress: values.branchAddress,
          pin: values.pin,
          branchState: values.branchState,
          contactPersonName: values.contactPersonName,
          contactNo: values.contactNo,
        }
      );
      fetchBranches(); // Refresh the list after successful update
      Swal.fire("Success", "Branch updated successfully!", "success");
    } catch (error) {
      console.error("Failed to update branch:", error.message);
      Swal.fire(
        "Error",
        error.response?.data?.message || "Failed to update branch.",
        "error"
      );
    }
  };

  const handleEditClick = (branch) => {
    formik.setValues(branch);
    setEditingBranch(branch);
  };

  const handleDeleteBranch = async (branch) => {
    // Display confirmation prompt
    const result = await Swal.fire({
      text: `Are you sure you want to delete the branch ${branch.branchName} (${branch.branchCode})?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d73925",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/admin/deletebranch/${branch.branchCode}`);
        fetchBranches();
        Swal.fire("Deleted!", "Branch has been deleted.", "success");
      } catch (error) {
        console.error("Failed to delete branch:", error.message);
        Swal.fire(
          "Error",
          error.response?.data?.message || "Failed to delete branch.",
          "error"
        );
      }
    }
  };

  return (
    <Box>
      <Typography sx={styles.header}>Add Branch</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Branch Details</Typography>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ ...styles.label }}>Branch Code</Typography>
                <TextField
                  disabled
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid> */}

            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ ...styles.label }}>
                  Branch Name<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  name="branchName"
                  value={formik.values.branchName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.branchName &&
                    Boolean(formik.errors.branchName)
                  }
                  helperText={
                    formik.touched.branchName && formik.errors.branchName
                  }
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ ...styles.label }}>
                  PIN<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  name="pin"
                  value={formik.values.pin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.pin && Boolean(formik.errors.pin)}
                  helperText={formik.touched.pin && formik.errors.pin}
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ ...styles.label }}>
                  State<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  name="branchState"
                  value={formik.values.branchState}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.branchState &&
                    Boolean(formik.errors.branchState)
                  }
                  helperText={
                    formik.touched.branchState && formik.errors.branchState
                  }
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ ...styles.label }}>
                  Opening Date<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  type="date"
                  name="branchOpeningDate"
                  value={formik.values.branchOpeningDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.branchOpeningDate &&
                    Boolean(formik.errors.branchOpeningDate)
                  }
                  helperText={
                    formik.touched.branchOpeningDate &&
                    formik.errors.branchOpeningDate
                  }
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ ...styles.label }}>
                  Contact Person<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  name="contactPersonName"
                  value={formik.values.contactPersonName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.contactPersonName &&
                    Boolean(formik.errors.contactPersonName)
                  }
                  helperText={
                    formik.touched.contactPersonName &&
                    formik.errors.contactPersonName
                  }
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ ...styles.label }}>
                  Address<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  name="branchAddress"
                  value={formik.values.branchAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.branchAddress &&
                    Boolean(formik.errors.branchAddress)
                  }
                  helperText={
                    formik.touched.branchAddress && formik.errors.branchAddress
                  }
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ ...styles.label }}>
                  Contact No.<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  name="contactNo"
                  value={formik.values.contactNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.contactNo && Boolean(formik.errors.contactNo)
                  }
                  helperText={
                    formik.touched.contactNo && formik.errors.contactNo
                  }
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}
          >
            {editingBranch ? (
              <>
                <Button
                  type="submit"
                  sx={{ ...styles.button, ...styles.saveButton }}
                >
                  Update
                </Button>
              </>
            ) : (
              <Button
                type="submit"
                sx={{ ...styles.button, ...styles.saveButton }}
              >
                Save
              </Button>
            )}
            <Button
              sx={{ ...styles.button, ...styles.newButton }}
              onClick={() => {
                formik.resetForm(); // Reset form fields
                setEditingBranch(null); // Clear editing state
              }}
            >
              New
            </Button>
          </Box>
        </form>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Branch Data List</Typography>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px 20px 0px 0px",
              width: { md: "100%", xs: "250px" },
              // maxWidth: "1210px",
              overflowX: "auto",
            }}
          >
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#413AF1" }}>
                <TableRow>
                  {[
                    "Sl No",
                    "BRANCH CODE",
                    "BRANCH NAME",
                    "STATE",
                    "CONTACT PERSON",
                    "CONTACT NO.",
                    "OPENING DATE",
                    "Action",
                    "Delete",
                  ].map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        color: "white",
                        fontWeight: 400,
                        fontSize: { md: "13px", xs: "10px" },
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {branches.length > 0 ? (
                  branches.map((branch, index) => (
                    <TableRow
                      key={branch.id}
                      sx={{
                        height: 48,
                        backgroundColor:
                          index % 2 === 0 ? "#F2F6FA" : "#C1E0FB",
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{branch.branchCode}</TableCell>
                      <TableCell>{branch.branchName}</TableCell>
                      <TableCell>{branch.branchState}</TableCell>
                      <TableCell>{branch.contactPersonName}</TableCell>
                      <TableCell>{branch.contactNo}</TableCell>
                      <TableCell>
                        {new Date(branch.branchOpeningDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEditClick(branch)}>
                          <RemoveRedEyeIcon sx={{ color: "green" }} />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeleteBranch(branch)}>
                          <DeleteIcon sx={{ color: "#d73925" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      align="center"
                      sx={{ color: "red" }}
                    >
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}

export default AddBranch;
