import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  CircularProgress,
  Modal,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "128px" },
    height: { xs: "38px", md: "48px" },
    color: "white",
    textTransform: "none",
    fontWeight: "bold",
  },
  saveButton: {
    background: "#DFBF41",
    ":hover": {
      background: "#a18e46",
    },
  },
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

function MemberApproval2() {
  const [branches, setBranches] = useState([]);
  const [pending, setPending] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [branch, setBranch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedMember, setSelectedMember] = useState(null); // For Modal
  const [openModal, setOpenModal] = useState(false);

  const axiosInstance = axios.create({
    baseURL: "https://testapi.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });

  const fetchBranches = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
      toast.error("Error fetching branches!");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPending = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/admin/pending");
      setPending(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching pending members:", error.message);
      toast.error("Error fetching pending members!");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBranches();
    fetchPending();
  }, [fetchBranches, fetchPending]);

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    setSelectedMembers(
      event.target.checked ? filteredData.map((m) => m.id) : []
    );
  };

  const handleSelectMember = (id) => {
    setSelectedMembers((prev) =>
      prev.includes(id)
        ? prev.filter((memberId) => memberId !== id)
        : [...prev, id]
    );
  };

  const handleSearch = () => {
    setLoading(true);
    let filtered = [...pending];
    if (branch) {
      filtered = filtered.filter((item) => item.branch.branchCode === branch);
    }
    if (fromDate) {
      filtered = filtered.filter(
        (item) => new Date(item.registrationDate) >= new Date(fromDate)
      );
    }
    if (toDate) {
      filtered = filtered.filter(
        (item) => new Date(item.registrationDate) <= new Date(toDate)
      );
    }
    setFilteredData(filtered);
    setLoading(false);
    setShowTable(true);
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      const payload = selectedMembers.map((id) => {
        const member = filteredData.find((m) => m.id === id);
        return {
          memberCode: member.memberCode,
          promotorCode: member.promotorCode,
        };
      });

      await axiosInstance.put("/admin/approve", payload);
      // toast.success("Selected members approved successfully!");
      Swal.fire(
        "Success",
        "Selected members approved successfully!",
        "success"
      );
      fetchPending();
      setSelectedMembers([]);
      setSelectAll(false);
    } catch (error) {
      console.error("Error approving members:", error.message);
      // toast.error("Error approving members!");
      Swal.fire(
        "Error",
        error.response?.data?.message || "Error approving members!",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      const payload = selectedMembers.map((id) => {
        const member = filteredData.find((m) => m.id === id);
        return { memberCode: member.memberCode };
      });

      await axiosInstance.put("/admin/reject", payload);
      // toast.success("Selected members rejected successfully!");
      Swal.fire(
        "Success",
        "Selected members approved successfully!",
        "success"
      );
      fetchPending();
      setSelectedMembers([]);
      setSelectAll(false);
    } catch (error) {
      console.error("Error rejecting members:", error.message);
      // toast.error("Error rejecting members!");
      Swal.fire(
        "Error",
        error.response?.data?.message || "Error rejecting members!",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = (member) => {
    setSelectedMember(member);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMember(null);
  };

  return (
    <Box>
      <Typography sx={styles.header}>Member Approval</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Search Box</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Branch :</Typography>
              <TextField
                select
                sx={styles.textField}
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {`${branch.branchName} (${branch.branchCode})`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>From Date :</Typography>
            <TextField
              type="date"
              sx={styles.textField}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>To Date :</Typography>
            <TextField
              type="date"
              sx={styles.textField}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              onClick={handleSearch}
              sx={{
                ...styles.button,
                ...styles.saveButton,
                mt: { md: 2.3, xs: 1 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {showTable && (
        <Paper sx={{ ...styles.paper, mt: 2 }}>
          <Typography sx={styles.sectionHeader}>Member Data List</Typography>
          <Box sx={{ overflowX: "auto" }}>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "20px 20px 0px 0px",
                  width: { md: "100%", xs: "250px" },
                  overflowX: "auto",
                }}
              >
                <Table size="small">
                  <TableHead sx={{ backgroundColor: "#413AF1" }}>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          sx={{ color: "white" }}
                        />
                      </TableCell>
                      {[
                        "Sl No",
                        "M. Code",
                        "M. Name",
                        "Gender",
                        "D.O.J.",
                        "Phone No",
                        "Fees",
                        "Share",
                        "View",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            color: "white",
                            fontWeight: 400,
                            fontSize: { md: "13px", xs: "10px" },
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.length > 0 ? (
                      filteredData.map((member, index) => (
                        <TableRow key={member.id}>
                          <TableCell>
                            <Checkbox
                              color="primary"
                              checked={selectedMembers.includes(member.id)}
                              onChange={() => handleSelectMember(member.id)}
                            />
                          </TableCell>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{member.memberCode}</TableCell>
                          <TableCell>{member.memberName}</TableCell>
                          <TableCell>{member.gender}</TableCell>
                          <TableCell>
                            {new Date(
                              member.registrationDate
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                          </TableCell>
                          <TableCell>{member.mobileNo}</TableCell>
                          <TableCell>{member.memberfees}</TableCell>
                          <TableCell>{member.shareAmount}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleOpenModal(member)}>
                              <VisibilityTwoToneIcon sx={{ color: "blue" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{ color: "red" }}
                          colSpan={10}
                          align="center"
                        >
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
          <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <Grid item>
              <Button
                onClick={handleApprove}
                disabled={selectedMembers.length === 0}
                sx={{
                  height: "40px",
                  width: "100px",
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: "10px",
                  "&:disabled": {
                    backgroundColor: "grey",
                  },
                  ":hover": { background: "#196819" },
                }}
              >
                Approve
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleReject}
                disabled={selectedMembers.length === 0}
                sx={{
                  height: "40px",
                  width: "100px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "10px",
                  "&:disabled": {
                    backgroundColor: "grey",
                  },
                  ":hover": { background: "#8B0000" },
                }}
              >
                Reject
              </Button>
            </Grid>
          </Grid>

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              {selectedMember && (
                <Box>
                  <Typography
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Member Details
                  </Typography>
                  <Table size="small">
                    <TableBody>
                      {[
                        [
                          {
                            label: "Member Code",
                            value: selectedMember.memberCode,
                          },
                          {
                            label: "Member Name",
                            value: `${selectedMember.memberNameTitle} ${selectedMember.memberName}`,
                          },
                        ],
                        [
                          {
                            label: "Branch Name",
                            value: selectedMember.branchName,
                          },
                          { label: "Gender", value: selectedMember.gender },
                        ],
                        [
                          {
                            label: "Date of Birth",
                            value: new Date(
                              selectedMember.dob
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }),
                          },
                          { label: "Age", value: selectedMember.age },
                        ],
                        [
                          {
                            label: "Mobile No",
                            value: selectedMember.mobileNo,
                          },
                          { label: "Email", value: selectedMember.emailId },
                        ],
                        [
                          {
                            label: "Address",
                            value: `${selectedMember.address}, ${selectedMember.district}, ${selectedMember.state}, ${selectedMember.pincode}`,
                          },
                          {
                            label: "Nominee",
                            value: `${selectedMember.nomineeName} (${selectedMember.nomineeRelation})`,
                          },
                        ],
                        [
                          {
                            label: "Intro Member Code",
                            value: selectedMember.introMemCode,
                          },
                          {
                            label: "Verify With",
                            value: selectedMember.verifyWith,
                          },
                        ],
                        [
                          {
                            label: "Registration Date",
                            value: selectedMember.registrationDate,
                          },
                          {
                            label: "Aadhar No",
                            value: selectedMember.aadharNo,
                          },
                        ],
                        [
                          { label: "PAN No", value: selectedMember.panNo },
                          { label: "Voter No", value: selectedMember.voterNo },
                        ],
                        [
                          {
                            label: "Promotor Code",
                            value: selectedMember.promotorCode,
                          },
                          {
                            label: "Share Amount",
                            value: selectedMember.shareAmount,
                          },
                        ],
                        [
                          {
                            label: "Member Fees",
                            value: selectedMember.memberfees,
                          },
                          { label: "Remarks", value: selectedMember.remarks },
                        ],
                        [
                          {
                            label: "Payment By",
                            value: selectedMember.paymentBy,
                          },
                        ],
                      ].map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <>
                              <TableCell key={`label-${cellIndex}`}>
                                <strong>{cell.label}:</strong>
                              </TableCell>
                              <TableCell key={`value-${cellIndex}`}>
                                {cell.value}
                              </TableCell>
                            </>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Box>
          </Modal>
        </Paper>
      )}
    </Box>
  );
}

export default MemberApproval2;
