import React, { useState } from "react";
import { Box, TextField, Button, Typography, Paper } from "@mui/material";
import axios from "axios";
import swal from "sweetalert2";
import Logo from "../Assets/Login/vlLogo.png";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!email) {
      setError("Email is required.");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setError("");

    try {
      const response = await axios.post("https://testapi.vlnidhi.com/api/password/forgot-password", {
        email,
      });

      if (response.status === 200) {
        swal.fire({
          icon: "success",
          title: "Success",
          text: "Password reset link has been sent to your email.",
        });
        setEmail("");
      }
    } catch (error) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Something went wrong!",
      });
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "#98b0ff",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
      }}
    >
      <Paper
        elevation={10}
        sx={{
          p: 4,
          borderRadius: 3,
          maxWidth: 400,
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={Logo}
            alt="Logo"
            style={{ width: 60, marginBottom: 10, borderRadius: "50%" }}
          />
        </Box>
        <Typography
          fontWeight="bold"
          mb={1}
          sx={{
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          VENKETLAXMI URBAN PUNE NIDHI LTD
        </Typography>

        <Typography
          fontWeight="bold"
          mb={1}
          sx={{
            textAlign: "center",
            fontSize: "18px",
          }}
          align="center"
          gutterBottom
        >
          Forget Password
        </Typography>

        <TextField
          fullWidth
          label="Email"
          placeholder="Enter your valid email"
          type="email"
          InputLabelProps={{ shrink: true }}
          margin="normal"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={Boolean(error)}
          helperText={error}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Paper>
    </Box>
  );
}

export default ForgetPassword;
