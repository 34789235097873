import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  CircularProgress,
  Modal,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "128px" },
    height: { xs: "38px", md: "48px" },
    color: "white",
    fontWeight: "bold",
  },
  saveButton: {
    background: "#DFBF41",
    ":hover": {
      background: "#a18e46",
    },
  },
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

function MemberApproval() {
  const [branches, setBranches] = useState([]);
  const [pending, setPending] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [branch, setBranch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedMember, setSelectedMember] = useState(null); // For Modal
  const [openModal, setOpenModal] = useState(false);

  const axiosInstance = axios.create({
    baseURL: "https://testapi.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });

  const fetchBranches = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
      toast.error("Error fetching branches!");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPending = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/admin/pending");
      setPending(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching pending members:", error.message);
      toast.error("Error fetching pending members!");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBranches();
    fetchPending();
  }, [fetchBranches, fetchPending]);

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    setSelectedMembers(
      event.target.checked ? filteredData.map((m) => m.id) : []
    );
  };

  const handleSelectMember = (id) => {
    setSelectedMembers((prev) =>
      prev.includes(id)
        ? prev.filter((memberId) => memberId !== id)
        : [...prev, id]
    );
  };

  const handleSearch = () => {
    setLoading(true);
    let filtered = [...pending];
    if (branch) {
      filtered = filtered.filter((item) => item.branch.branchCode === branch);
    }
    if (fromDate) {
      filtered = filtered.filter(
        (item) => new Date(item.registrationDate) >= new Date(fromDate)
      );
    }
    if (toDate) {
      filtered = filtered.filter(
        (item) => new Date(item.registrationDate) <= new Date(toDate)
      );
    }
    setFilteredData(filtered);
    setLoading(false);
    setShowTable(true);
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      const payload = selectedMembers.map((id) => {
        const member = filteredData.find((m) => m.id === id);
        return {
          memberCode: member.memberCode,
          promotorCode: member.promotorCode,
        };
      });

      await axiosInstance.put("/admin/approve", payload);
      // toast.success("Selected members approved successfully!");
      Swal.fire(
        "Success",
        "Selected members approved successfully!",
        "success"
      );
      fetchPending();
      setSelectedMembers([]);
      setSelectAll(false);
    } catch (error) {
      console.error("Error approving members:", error.message);
      // toast.error("Error approving members!");
      Swal.fire(
        "Error",
        error.response?.data?.message || "Error approving members!",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      const payload = selectedMembers.map((id) => {
        const member = filteredData.find((m) => m.id === id);
        return { memberCode: member.memberCode };
      });

      await axiosInstance.put("/admin/reject", payload);
      // toast.success("Selected members rejected successfully!");
      Swal.fire(
        "Success",
        "Selected members approved successfully!",
        "success"
      );
      fetchPending();
      setSelectedMembers([]);
      setSelectAll(false);
    } catch (error) {
      console.error("Error rejecting members:", error.message);
      // toast.error("Error rejecting members!");
      Swal.fire(
        "Error",
        error.response?.data?.message || "Error rejecting members!",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = (member) => {
    setSelectedMember(member);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMember(null);
  };

  return (
    <Box>
      <Typography sx={styles.header}>Member Approval</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Search Box</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Branch :</Typography>
              <TextField
                select
                sx={styles.textField}
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {`${branch.branchName} (${branch.branchCode})`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>From Date :</Typography>
            <TextField
              type="date"
              sx={styles.textField}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>To Date :</Typography>
            <TextField
              type="date"
              sx={styles.textField}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              onClick={handleSearch}
              sx={{
                ...styles.button,
                ...styles.saveButton,
                mt: { md: 2.3, xs: 1 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {showTable && (
        <Paper sx={{ ...styles.paper, mt: 2 }}>
          <Typography sx={styles.sectionHeader}>Member Data List</Typography>
          <Box sx={{ overflowX: "auto" }}>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "20px 20px 0px 0px",
                  width: { md: "100%", xs: "250px" },
                  overflowX: "auto",
                }}
              >
                <Table size="small">
                  <TableHead sx={{ backgroundColor: "#413AF1" }}>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          sx={{ color: "white" }}
                        />
                      </TableCell>
                      {[
                        "Sl No",
                        "M. Code",
                        "M. Name",
                        "Gender",
                        "D.O.J.",
                        "Phone No",
                        "Fees",
                        "Share",
                        "View",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            color: "white",
                            fontWeight: 400,
                            fontSize: { md: "13px", xs: "10px" },
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.length > 0 ? (
                      filteredData.map((member, index) => (
                        <TableRow key={member.id}>
                          <TableCell>
                            <Checkbox
                              color="primary"
                              checked={selectedMembers.includes(member.id)}
                              onChange={() => handleSelectMember(member.id)}
                            />
                          </TableCell>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{member.memberCode}</TableCell>
                          <TableCell>{member.memberName}</TableCell>
                          <TableCell>{member.gender}</TableCell>
                          <TableCell>
                            {new Date(
                              member.registrationDate
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                          </TableCell>
                          <TableCell>{member.mobileNo}</TableCell>
                          <TableCell>{member.memberfees}</TableCell>
                          <TableCell>{member.shareAmount}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleOpenModal(member)}>
                              <VisibilityTwoToneIcon sx={{ color: "blue" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{ color: "red" }}
                          colSpan={10}
                          align="center"
                        >
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
          <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <Grid item>
              <Button
                onClick={handleApprove}
                disabled={selectedMembers.length === 0}
                sx={{
                  height: "40px",
                  width: "100px",
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: "10px",
                  "&:disabled": {
                    backgroundColor: "grey",
                  },
                  ":hover": { background: "#196819" },
                }}
              >
                Approve
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleReject}
                disabled={selectedMembers.length === 0}
                sx={{
                  height: "40px",
                  width: "100px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "10px",
                  "&:disabled": {
                    backgroundColor: "grey",
                  },
                  ":hover": { background: "#8B0000" },
                }}
              >
                Reject
              </Button>
            </Grid>
          </Grid>

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              {selectedMember && (
                <Box>
                  <Typography
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Member Details
                  </Typography>
                  <Table size="small">
                    <TableBody>
                      {[
                        [
                          {
                            label: "Member Code",
                            value: selectedMember.memberCode,
                          },
                          {
                            label: "Member Name",
                            value: `${selectedMember.memberNameTitle} ${selectedMember.memberName}`,
                          },
                        ],
                        [
                          {
                            label: "Branch Name",
                            value: selectedMember.branchName,
                          },
                          { label: "Gender", value: selectedMember.gender },
                        ],
                        [
                          {
                            label: "Date of Birth",
                            value: new Date(
                              selectedMember.dob
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }),
                          },
                          { label: "Age", value: selectedMember.age },
                        ],
                        [
                          {
                            label: "Mobile No",
                            value: selectedMember.mobileNo,
                          },
                          { label: "Email", value: selectedMember.emailId },
                        ],
                        [
                          {
                            label: "Address",
                            value: `${selectedMember.address}, ${selectedMember.district}, ${selectedMember.state}, ${selectedMember.pincode}`,
                          },
                          {
                            label: "Nominee",
                            value: `${selectedMember.nomineeName} (${selectedMember.nomineeRelation})`,
                          },
                        ],
                        [
                          {
                            label: "Intro Member Code",
                            value: selectedMember.introMemCode,
                          },
                          {
                            label: "Verify With",
                            value: selectedMember.verifyWith,
                          },
                        ],
                        [
                          {
                            label: "Registration Date",
                            value: selectedMember.registrationDate,
                          },
                          {
                            label: "Aadhar No",
                            value: selectedMember.aadharNo,
                          },
                        ],
                        [
                          { label: "PAN No", value: selectedMember.panNo },
                          { label: "Voter No", value: selectedMember.voterNo },
                        ],
                        [
                          {
                            label: "Promotor Code",
                            value: selectedMember.promotorCode,
                          },
                          {
                            label: "Share Amount",
                            value: selectedMember.shareAmount,
                          },
                        ],
                        [
                          {
                            label: "Member Fees",
                            value: selectedMember.memberfees,
                          },
                          { label: "Remarks", value: selectedMember.remarks },
                        ],
                        [
                          {
                            label: "Payment By",
                            value: selectedMember.paymentBy,
                          },
                        ],
                      ].map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <>
                              <TableCell key={`label-${cellIndex}`}>
                                <strong>{cell.label}:</strong>
                              </TableCell>
                              <TableCell key={`value-${cellIndex}`}>
                                {cell.value}
                              </TableCell>
                            </>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Box>
          </Modal>
        </Paper>
      )}
    </Box>
  );
}

export default MemberApproval;

// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   TextField,
//   Button,
//   MenuItem,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
//   Grid,
//   Checkbox,
//   CircularProgress,
//   IconButton,
//   Modal,
// } from "@mui/material";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
// import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";

// const modalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "70%",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
//   borderRadius: "20px",
// };

// const MemberApproval = () => {
//   const [branches, setBranches] = useState([]);
//   const [pendingMembers, setPendingMembers] = useState([]);
//   const [filteredMembers, setFilteredMembers] = useState([]);
//   const [selectedMembers, setSelectedMembers] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);
//   const [showTable, setShowTable] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [selectedMember, setSelectedMember] = useState(null); // For Modal
//   const [openModal, setOpenModal] = useState(false); // Modal visibility

//   const token = sessionStorage.getItem("token");

//   useEffect(() => {
//     setLoading(true);
//     axios
//       .get("https://testapi.vlnidhi.com/api/admin/getallbid", {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((response) => setBranches(response.data))
//       .catch((error) => {
//         console.error("Error fetching branches", error);
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Failed to fetch branches",
//         });
//       })
//       .finally(() => setLoading(false));
//   }, [token]);

//   const handleGetAll = () => {
//     setLoading(true);
//     axios
//       .get("https://testapi.vlnidhi.com/api/admin/pending", {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((response) => {
//         setPendingMembers(response.data);
//         setFilteredMembers(response.data);
//         setShowTable(true);
//       })
//       .catch((error) => {
//         console.error("Error fetching members", error);
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Failed to fetch members",
//         });
//       })
//       .finally(() => setLoading(false));
//   };

//   const handleApproval = (status) => {
//     setLoading(true);

//     // Modify selectedMembers to send memberCode and promotorCode
//     const membersPayload = selectedMembers.map((member) => ({
//       memberCode: member.memberCode,
//       promotorCode: member.promotorCode,
//     }));

//     axios
//       .put(`https://testapi.vlnidhi.com/api/admin/${status}`, membersPayload, {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((response) => {
//         setFilteredMembers((prevMembers) =>
//           prevMembers.filter(
//             (member) =>
//               !selectedMembers.some(
//                 (selected) => selected.memberCode === member.memberCode
//               )
//           )
//         );
//         setSelectedMembers([]);
//         setSelectAll(false);

//         Swal.fire({
//           icon: "success",
//           title: `Member ${
//             status.charAt(0).toUpperCase() + status.slice(1)
//           } Successfully!`,
//           text: response.data.message,
//           confirmButtonText: "OK",
//         });
//       })
//       .catch((error) => {
//         console.error(`Error ${status} members`, error);
//         Swal.fire(
//           "Error",
//           error.response?.data?.responseMessage || "Something went wrong!",
//           "error"
//         );
//       })
//       .finally(() => setLoading(false));
//   };

//   const handleReject = () => {
//     setLoading(true);

//     const membersPayload = selectedMembers.map((member) => ({
//       memberCode: member.memberCode,
//     }));

//     axios
//       .put(`https://testapi.vlnidhi.com/api/admin/reject`, membersPayload, {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((response) => {
//         setFilteredMembers((prevMembers) =>
//           prevMembers.filter(
//             (member) =>
//               !selectedMembers.some(
//                 (selected) => selected.memberCode === member.memberCode
//               )
//           )
//         );
//         setSelectedMembers([]);
//         setSelectAll(false);

//         Swal.fire({
//           icon: "success",
//           title: "Member Rejected Successfully!",
//           text: response.data.message,
//           confirmButtonText: "OK",
//         });
//       })
//       .catch((error) => {
//         console.error("Error rejecting members", error);
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Failed to reject members",
//         });
//       })
//       .finally(() => setLoading(false));
//   };

//   const handleSelectAll = (event) => {
//     const isChecked = event.target.checked;
//     setSelectAll(isChecked);

//     // Map over filteredMembers to create an array of objects containing memberCode and promotorCode
//     const membersPayload = isChecked
//       ? filteredMembers.map((member) => ({
//           memberCode: member.memberCode,
//           promotorCode: member.promotorCode,
//         }))
//       : [];

//     setSelectedMembers(membersPayload);
//   };

//   const handleSelectMember = (member) => {
//     const { memberCode, promotorCode } = member;
//     setSelectedMembers((prevSelected) => {
//       const isSelected = prevSelected.some(
//         (selected) => selected.memberCode === memberCode
//       );

//       if (isSelected) {
//         // Remove member if already selected
//         return prevSelected.filter(
//           (selected) => selected.memberCode !== memberCode
//         );
//       } else {
//         // Add member if not already selected
//         return [...prevSelected, { memberCode, promotorCode }];
//       }
//     });
//   };

//   const validationSchema = Yup.object({
//     branch: Yup.string().required("Branch is required"),
//     fromDate: Yup.date().required("From Date is required"),
//     toDate: Yup.date().required("To Date is required"),
//   });

//   // Function to open the modal with the member data
//   const handleOpenModal = (member) => {
//     setSelectedMember(member); // Set selected member's data
//     setOpenModal(true); // Open modal
//   };

//   const handleCloseModal = () => {
//     setOpenModal(false); // Close modal
//     setSelectedMember(null); // Clear selected member data
//   };

//   return (
//     <Box>
//       <Typography
//         sx={{
//           fontFamily: "Poppins",
//           fontSize: "24px",
//           fontWeight: 600,
//           lineHeight: "36px",
//           mb: 1,
//         }}
//       >
//         Member Approval
//       </Typography>
//       <Formik
//         initialValues={{
//           branch: "",
//           fromDate: "",
//           toDate: "",
//         }}
//         validationSchema={validationSchema}
//         onSubmit={(values) => {
//           const { branch, fromDate, toDate } = values;

//           // Filter members based on branch, fromDate, and toDate
//           const filtered = pendingMembers.filter((member) => {
//             const memberDate = new Date(member.registrationDate);
//             const isWithinDateRange =
//               (!fromDate || new Date(fromDate) <= memberDate) &&
//               (!toDate || memberDate <= new Date(toDate));
//             return member.branchName === branch && isWithinDateRange;
//           });

//           setFilteredMembers(filtered);
//           setShowTable(true);
//         }}
//       >
//         {({ errors, touched, values, setFieldValue }) => (
//           <Form>
//             <Paper
//               sx={{
//                 p: { md: 2, xs: 2 },
//                 borderRadius: "20px",
//                 boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//               }}
//             >
//               {/* Search Box */}
//               <Typography
//                 sx={{
//                   fontFamily: "Poppins",
//                   fontSize: "20px",
//                   fontWeight: 600,
//                   lineHeight: "36px",
//                   mb: 1,
//                   mt: 0,
//                 }}
//               >
//                 Search Box
//               </Typography>
//               <Box sx={{ display: "flex", gap: 2 }}>
//                 {/* Branch Field */}
//                 <Grid>
//                   <Typography>Branch</Typography>
//                   <Field
//                     as={TextField}
//                     variant="outlined"
//                     select
//                     name="branch"
//                     sx={{ width: "257px" }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                     value={values.branch}
//                     onChange={(e) => setFieldValue("branch", e.target.value)}
//                   >
//                     {branches.map((branch) => (
//                       <MenuItem key={branch.id} value={branch.branchName}>
//                         {branch.branchName}
//                       </MenuItem>
//                     ))}
//                   </Field>
//                   {touched.branch && errors.branch ? (
//                     <Typography color="error">{errors.branch}</Typography>
//                   ) : null}
//                 </Grid>

//                 <Grid>
//                   <Typography>From Date</Typography>
//                   <Field
//                     as={TextField}
//                     fullWidth
//                     variant="outlined"
//                     type="date"
//                     name="fromDate"
//                     sx={{ width: "257px" }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                     value={values.fromDate}
//                     onChange={(e) => setFieldValue("fromDate", e.target.value)}
//                   />
//                   {touched.fromDate && errors.fromDate ? (
//                     <Typography color="error">{errors.fromDate}</Typography>
//                   ) : null}
//                 </Grid>
//                 <Grid>
//                   <Typography>To Date</Typography>
//                   <Field
//                     as={TextField}
//                     fullWidth
//                     variant="outlined"
//                     type="date"
//                     name="toDate"
//                     sx={{ width: "257px" }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                     value={values.toDate}
//                     onChange={(e) => setFieldValue("toDate", e.target.value)}
//                   />
//                   {touched.toDate && errors.toDate ? (
//                     <Typography color="error">{errors.toDate}</Typography>
//                   ) : null}
//                 </Grid>
//                 <Grid>
//                   <Button
//                     variant="contained"
//                     type="submit"
//                     sx={{
//                       height: "48px",
//                       width: "150px",
//                       background: "#DFBF41",
//                       color: "white",
//                       marginTop: "3px",
//                       borderRadius: "10px",
//                       mt: 3,
//                     }}
//                   >
//                     Search
//                   </Button>
//                 </Grid>
//                 {/* Get All Button */}
//                 <Grid>
//                   <Button
//                     variant="contained"
//                     onClick={handleGetAll}
//                     sx={{
//                       height: "48px",
//                       width: "150px",
//                       background: "green",
//                       color: "white",
//                       marginTop: "16px",
//                       borderRadius: "10px",
//                       alignSelf: "flex-start",
//                       mt: 3,
//                     }}
//                   >
//                     Get All
//                   </Button>
//                 </Grid>
//               </Box>
//             </Paper>
//           </Form>
//         )}
//       </Formik>

//       {/* Table Data */}
//       {showTable && (
//         <Box sx={{ mt: 2 }}>
//           <Typography
//             sx={{
//               fontFamily: "Poppins",
//               fontSize: "24px",
//               fontWeight: 600,
//               lineHeight: "36px",
//             }}
//           >
//             Member Data List
//           </Typography>
//           <Box sx={{ mt: 1 }}>
//             {loading ? (
//               <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
//                 <CircularProgress />
//               </Box>
//             ) : (
//               <TableContainer component={Paper} sx={{ borderRadius: "20px" }}>
//                 <Table size="small">
//                   <TableHead sx={{ backgroundColor: "#3c3cc1" }}>
//                     <TableRow>
//                       <TableCell sx={{ fontWeight: "bold", color: "white" }}>
//                         <Checkbox
//                           sx={{ color: "white" }}
//                           checked={selectAll}
//                           onChange={handleSelectAll}
//                         />
//                       </TableCell>
//                       {[
//                         "Sl No",
//                         "M. Code",
//                         "M. Name",
//                         "Gender",
//                         "D.O.J.",
//                         "Phone No",
//                         "Fees",
//                         "Share",
//                         "View",
//                       ].map((header) => (
//                         <TableCell
//                           key={header}
//                           sx={{ color: "white", fontWeight: "bold" }}
//                         >
//                           {header}
//                         </TableCell>
//                       ))}
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {filteredMembers.map((member, index) => (
//                       <TableRow
//                         key={member.memberCode}
//                         sx={{
//                           height: 48,
//                           backgroundColor:
//                             index % 2 === 0 ? "#F2F6FA" : "#C1E0FB", // Alternate row color
//                         }}
//                       >
//                         <TableCell>
//                           <Checkbox
//                             checked={selectedMembers.some(
//                               (selected) =>
//                                 selected.memberCode === member.memberCode
//                             )}
//                             onChange={() =>
//                               handleSelectMember({
//                                 memberCode: member.memberCode,
//                                 promotorCode: member.promotorCode,
//                               })
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>{index + 1}</TableCell>
//                         <TableCell>{member.memberCode}</TableCell>
//                         <TableCell>{member.memberName}</TableCell>
//                         <TableCell>{member.gender}</TableCell>
//                         <TableCell>
//                           {new Date(member.registrationDate).toLocaleDateString(
//                             "en-GB",
//                             {
//                               day: "2-digit",
//                               month: "2-digit",
//                               year: "numeric",
//                             }
//                           )}
//                         </TableCell>
//                         <TableCell>{member.mobileNo}</TableCell>
//                         <TableCell>{member.memberfees}</TableCell>
//                         <TableCell>{member.noOfShare}</TableCell>
//                         <TableCell>
//                           <IconButton onClick={() => handleOpenModal(member)}>
//                             <VisibilityTwoToneIcon sx={{ color: "blue" }} />
//                           </IconButton>
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             )}

//             {/* Approve/Reject Buttons */}
//             <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
//               <Button
//                 variant="contained"
//                 onClick={() => handleApproval("approve")}
//                 disabled={selectedMembers.length === 0}
//                 sx={{
//                   height: "40px",
//                   width: "100px",
//                   backgroundColor: "green",
//                   color: "white",
//                   borderRadius: "10px",
//                   "&:disabled": {
//                     backgroundColor: "grey",
//                   },
//                 }}
//               >
//                 Approve
//               </Button>
//               <Button
//                 variant="contained"
//                 onClick={handleReject}
//                 disabled={selectedMembers.length === 0}
//                 sx={{
//                   height: "40px",
//                   width: "100px",
//                   backgroundColor: "red",
//                   color: "white",
//                   borderRadius: "10px",
//                   "&:disabled": {
//                     backgroundColor: "grey",
//                   },
//                 }}
//               >
//                 Reject
//               </Button>
//             </Box>
//             {/* Modal to display member details */}
//             <Modal
//               open={openModal}
//               onClose={handleCloseModal}
//               aria-labelledby="modal-modal-title"
//               aria-describedby="modal-modal-description"
//             >
//               <Box sx={modalStyle}>
//                 {selectedMember && (
//                   <Box>
//                     <Typography
//                       sx={{
//                         marginBottom: 2,
//                         fontWeight: "bold",
//                         fontSize: "20px",
//                         fontFamily: "Poppins",
//                       }}
//                     >
//                       Member Details
//                     </Typography>
//                     <Table size="small">
//                       <TableBody>
//                         <TableRow>
//                           <TableCell>
//                             <strong>Member Code:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.memberCode}</TableCell>
//                           <TableCell>
//                             <strong>Member Name:</strong>
//                           </TableCell>
//                           <TableCell>
//                             {selectedMember.memberNameTitle}{" "}
//                             {selectedMember.memberName}
//                           </TableCell>
//                         </TableRow>
//                         <TableRow>
//                           <TableCell>
//                             <strong>Branch Name:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.branchName}</TableCell>
//                           <TableCell>
//                             <strong>Gender:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.gender}</TableCell>
//                         </TableRow>
//                         <TableRow>
//                           <TableCell>
//                             <strong>Date of Birth:</strong>
//                           </TableCell>
//                           <TableCell>
//                             {new Date(selectedMember.dob).toLocaleDateString(
//                               "en-GB",
//                               {
//                                 day: "2-digit",
//                                 month: "2-digit",
//                                 year: "numeric",
//                               }
//                             )}
//                           </TableCell>
//                           <TableCell>
//                             <strong>Age:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.age}</TableCell>
//                         </TableRow>
//                         <TableRow>
//                           <TableCell>
//                             <strong>Mobile No:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.mobileNo}</TableCell>
//                           <TableCell>
//                             <strong>Email:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.emailId}</TableCell>
//                         </TableRow>
//                         <TableRow>
//                           <TableCell>
//                             <strong>Address:</strong>
//                           </TableCell>
//                           <TableCell>
//                             {selectedMember.address}, {selectedMember.district},{" "}
//                             {selectedMember.state}, {selectedMember.pincode}
//                           </TableCell>
//                           <TableCell>
//                             <strong>Nominee:</strong>
//                           </TableCell>
//                           <TableCell>
//                             {selectedMember.nomineeName} (
//                             {selectedMember.nomineeRelation})
//                           </TableCell>
//                         </TableRow>
//                         <TableRow>
//                           <TableCell>
//                             <strong>Intro Member Code:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.introMemCode}</TableCell>
//                           <TableCell>
//                             <strong>Verify With:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.verifyWith}</TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell>
//                             <strong>Registration Date:</strong>
//                           </TableCell>
//                           <TableCell>
//                             {selectedMember.registrationDate}
//                           </TableCell>
//                           <TableCell>
//                             <strong>Aadhar No:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.aadharNo}</TableCell>
//                         </TableRow>
//                         <TableRow>
//                           <TableCell>
//                             <strong>PAN No:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.panNo}</TableCell>
//                           <TableCell>
//                             <strong>Voter No:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.voterNo}</TableCell>
//                         </TableRow>

//                         {/* <TableRow>
//                           <TableCell>
//                             <strong>Ration No:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.rationNo}</TableCell>
//                           <TableCell>
//                             <strong>DL No:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.dlNo}</TableCell>
//                         </TableRow> */}

//                         <TableRow>
//                           <TableCell>
//                             <strong>Promotor Code:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.promotorCode}</TableCell>
//                           <TableCell>
//                             <strong>Share Amount:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.shareAmount}</TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell>
//                             <strong>Member fees:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.memberfees}</TableCell>
//                           <TableCell>
//                             <strong>Remarks:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.remarks}</TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell>
//                             <strong>Payment By:</strong>
//                           </TableCell>
//                           <TableCell>{selectedMember.paymentBy}</TableCell>
//                         </TableRow>
//                       </TableBody>
//                     </Table>
//                   </Box>
//                 )}
//               </Box>
//             </Modal>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default MemberApproval;
