import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function AddMemberKyc() {
  const [showStepper, setShowStepper] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem("token") || "");

  const [formValues, setFormValues] = useState({
    voterNo: "",
    rationNo: "",
    dlNo: "",
    bankName: "",
    bankBranch: "",
    accountNo: "",
    ifscCode: "",
  });

  const handleMemberChange = (event) => {
    const memberCode = event.target.value;
    const member = members.find((m) => m.memberCode === memberCode);
    setSelectedMember(member);
  };

  useEffect(() => {
    axios
      .get("https://testapi.vlnidhi.com/api/admin/approvedmembers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setMembers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching members data:", error);
      });
  }, [token]);

  const handleSubmit = () => {
    const token = sessionStorage.getItem("token");
    const updatedData = {
      // voterNo: formValues.voterNo || selectedMember.voterNo,
      // rationNo: formValues.rationNo || selectedMember.rationNo,
      // dlNo: formValues.dlNo || selectedMember.dlNo,
      bankName: formValues.bankName || selectedMember.bankName,
      bankBranch: formValues.bankBranch || selectedMember.bankBranch,
      accountNo: formValues.accountNo || selectedMember.accountNo,
      ifscCode: formValues.ifscCode || selectedMember.ifscCode,
    };
    // Conditionally add voterNo if it is not present in selectedMember
    if (!selectedMember.voterNo) {
      updatedData.voterNo = formValues.voterNo;
    }
    if (!selectedMember.rationNo) {
      updatedData.rationNo = formValues.rationNo;
    }
    if (!selectedMember.dlNo) {
      updatedData.dlNo = formValues.dlNo;
    }

    axios
      .put(
        `https://testapi.vlnidhi.com/api/admin/updatemember/${selectedMember.memberCode}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const { memberCode } = response.data;

        setMembers((prevMembers) =>
          prevMembers.map((member) =>
            member.memberCode === selectedMember.memberCode
              ? { ...member, ...updatedData }
              : member
          )
        );

        toast.success("Member KYC added successfully!", {
          position: "top-right",
          autoClose: 3000,
        });

        Swal.fire({
          title: "Success!",
          text: `Member KYC added successfully with Member Code: ${memberCode}`,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });

        sessionStorage.setItem("formValues", JSON.stringify(formValues));
      })
      .catch((error) => {
        console.error("Error adding member KYC :", error);
        toast.error("Failed to add member KYC!", {
          position: "top-right",
          autoClose: 3000,
        });
        sessionStorage.setItem("formValues", JSON.stringify(formValues));
      });
  };

  const handleSearch = () => {
    setShowStepper(true);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    height: "48px",
    // borderRadius: "80%",
    // borderColor: "#D9D9D9CC",
    // backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    opacity: 0.6,
  };

  const steps = ["Step 1", "Step 2", "Step 3"];

  const stepIconStyle = {
    "&.Mui-completed": {
      color: "green",
    },
    "&.Mui-active": {
      color: "#3029D9",
    },
    "&.Mui-disabled": {
      color: "gray",
    },
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Add Member KYC
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 4,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 1,
            }}
          >
            Search Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={6}>
              <Typography
                sx={{
                  // width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  mb: 1,
                  ...fontStyle,
                }}
              >
                Search By Code*
              </Typography>
              <TextField
                select
                name="searchByCode"
                value={selectedMember?.memberCode || ""}
                onChange={handleMemberChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                {members.map((member) => (
                  <MenuItem key={member.id} value={member.memberCode}>
                    {member.memberName} ({member.memberCode})
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  mb: 1,
                  ...fontStyle,
                }}
              >
                KYC Verified By
              </Typography>
              <TextField
                name="kycVerifiedBy"
                disabled
                sx={textFieldStyle}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              gap: "2%",
            }}
          >
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                background: "#3029D9",
                width: "157px",
                height: "48px",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Paper>

      {showStepper && selectedMember && (
        <Paper
          sx={{
            width: "100%",
            p: 4,
            mt: 4,
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    sx: stepIconStyle,
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* ----------------------------------------------------- */}

          {activeStep === 0 && (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "36px",
                  mb: 1,
                  mt: 2,
                }}
              >
                Member Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Member Name
                  </Typography>
                  <TextField
                    disabled
                    name="memberName"
                    value={selectedMember.memberName}
                    sx={textFieldStyle}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Member Code
                  </Typography>
                  <TextField
                    disabled
                    name="memberCode"
                    value={selectedMember.memberCode}
                    sx={textFieldStyle}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Mobile Number
                  </Typography>
                  <TextField
                    disabled
                    name="mobileNo"
                    value={selectedMember.mobileNo}
                    sx={textFieldStyle}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Registration Date
                  </Typography>
                  <TextField
                    disabled
                    name="registrationDate"
                    value={selectedMember.registrationDate}
                    sx={textFieldStyle}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Aadhar Number
                  </Typography>
                  <TextField
                    disabled
                    name="aadharNo"
                    value={selectedMember.aadharNo}
                    onChange={handleChange}
                    sx={textFieldStyle}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Pan Number
                  </Typography>
                  <TextField
                    disabled
                    name="panNo"
                    value={selectedMember.panNo}
                    sx={textFieldStyle}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Voter Number
                  </Typography>
                  <TextField
                    name="voterNo"
                    value={formValues.voterNo || selectedMember.voterNo}
                    disabled={Boolean(selectedMember.voterNo)}
                    onChange={handleChange}
                    sx={textFieldStyle}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Ration Card Number
                  </Typography>
                  <TextField
                    name="rationNo"
                    value={formValues.rationNo || selectedMember.rationNo}
                    disabled={Boolean(selectedMember.rationNo)}
                    onChange={handleChange}
                    sx={textFieldStyle}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    DL Number
                  </Typography>
                  <TextField
                    name="dlNo"
                    value={formValues.dlNo || selectedMember.dlNo}
                    disabled={Boolean(selectedMember.dlNo)}
                    onChange={handleChange}
                    sx={textFieldStyle}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid mt={4} item xs={12} sm={6}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="outlined"
                    href="https://resident.uidai.gov.in/verify"
                    target="_blank"
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      width: { xs: "30px", md: "250px" },
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      color: "#E0C042",
                      borderColor: "#E0C042",
                      fontSize: { xs: "8px", md: "14px" },
                    }}
                  >
                    Online Aadhar Verify
                  </Button>
                  <Button
                    variant="outlined"
                    href="https://electoralsearch.eci.gov.in/"
                    target="_blank"
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      width: { xs: "30px", md: "250px" },
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      color: "#E7A03C",
                      borderColor: "#E7A03C",
                      fontSize: { xs: "8px", md: "14px" },
                    }}
                  >
                    Online Voter Verify
                  </Button>
                  <Button
                    variant="outlined"
                    href="https://eportal.incometax.gov.in/iec/foservices/#/pre-login/verifyYourPAN"
                    target="_blank"
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      width: { xs: "30px", md: "250px" },
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      color: "#56BDEA",
                      borderColor: "#56BDEA",
                      fontSize: { xs: "8px", md: "14px" },
                    }}
                  >
                    Online PAN Verify
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      width: { xs: "30px", md: "250px" },
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      background: "#3029D9",
                      fontSize: { xs: "8px", md: "14px" },
                    }}
                  >
                    Verification Done
                  </Button>
                </Box>
              </Grid>
            </Box>
          )}

          {/* ----------------------------------------------------- */}

          {activeStep === 1 && (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "36px",
                  mb: 1,
                  mt: 2,
                }}
              >
                Member KYC
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Grid direction="column">
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: { xs: "16px", md: "18px" },
                          marginBottom: "8px",
                          ...fontStyle,
                        }}
                      >
                        Applicant Photo
                      </Typography>

                      <ImageUploadWrapper>
                        <PersonIcon
                          style={{ fontSize: 100, color: "#BDBDBD" }}
                        />
                      </ImageUploadWrapper>

                      {/* <UploadButton variant="contained" component="label">
                        Choose File
                        <input type="file" hidden />
                      </UploadButton>
                      <Caption>Photo Size: Maximum 10MB</Caption> */}
                    </Grid>
                    <Grid direction="column">
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: { xs: "16px", md: "18px" },
                          marginBottom: "8px",
                          ...fontStyle,
                        }}
                      >
                        Signature
                      </Typography>

                      <ImageUploadWrapper></ImageUploadWrapper>

                      {/* <UploadButton variant="contained" component="label">
                        Choose File
                        <input type="file" hidden />
                      </UploadButton>
                      <Caption>Photo Size: Maximum 10MB</Caption> */}
                    </Grid>
                    <Grid direction="column">
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: { xs: "16px", md: "18px" },
                          marginBottom: "8px",
                          ...fontStyle,
                        }}
                      >
                        Aadhar Front Photo
                      </Typography>

                      <ImageUploadWrapper></ImageUploadWrapper>

                      {/* <UploadButton variant="contained" component="label">
                        Choose File
                        <input type="file" hidden />
                      </UploadButton>
                      <Caption>Photo Size: Maximum 10MB</Caption> */}
                    </Grid>
                    <Grid direction="column">
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: { xs: "16px", md: "18px" },
                          marginBottom: "8px",
                          ...fontStyle,
                        }}
                      >
                        Aadhar Back Photo
                      </Typography>

                      <ImageUploadWrapper></ImageUploadWrapper>

                      {/* <UploadButton variant="contained" component="label">
                        Choose File
                        <input type="file" hidden />
                      </UploadButton>
                      <Caption>Photo Size: Maximum 10MB</Caption> */}
                    </Grid>
                    <Grid container direction="column">
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: { xs: "16px", md: "18px" },
                          marginBottom: "8px",
                          ...fontStyle,
                        }}
                      >
                        PAN Photo
                      </Typography>

                      <ImageUploadWrapper></ImageUploadWrapper>

                      {/* <UploadButton variant="contained" component="label">
                        Choose File
                        <input type="file" hidden />
                      </UploadButton>
                      <Caption>Photo Size: Maximum 10MB</Caption> */}
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Box>
          )}

          {/* ----------------------------------------------------- */}

          {activeStep === 2 && (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "36px",
                  mb: 1,
                  mt: 2,
                }}
              >
                Bank Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Bank Name
                  </Typography>
                  <TextField
                    name="bankName"
                    value={formValues.bankName || selectedMember.bankName}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Bank Branch
                  </Typography>
                  <TextField
                    name="bankBranch"
                    value={formValues.bankBranch || selectedMember.bankBranch}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Account Number
                  </Typography>
                  <TextField
                    name="accountNo"
                    value={formValues.accountNo || selectedMember.accountNo}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    IFSC Code
                  </Typography>
                  <TextField
                    // type="date"
                    name="ifscCode"
                    value={formValues.ifscCode || selectedMember.ifscCode}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Navigation buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              gap: "2%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
                gap: "2%",
              }}
            >
              {activeStep === 2 ? (
                <>
                  <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      // width: "250px",
                      width: { xs: "40px", md: "250px" },
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      background: "#3029D9",
                      // width: "250px",
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      width: { xs: "40px", md: "250px" },
                    }}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      // width: "157px",
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      width: { xs: "40px", md: "250px" },
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      background: "#3029D9",
                      // width: "157px",
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      width: { xs: "40px", md: "250px" },
                    }}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Paper>
      )}
    </Box>
  );
}

export default AddMemberKyc;
