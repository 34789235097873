import React from "react";
import SignIn from "./Components/Signin";
import Sidebar from "./Components/Sidebar";
import { Routes, Route } from "react-router-dom";
import { PageNotFound } from "./Pages/PageNotFound";
import ProtectedRoute from "./Components/ProtectedRoute";
import AddMoney from "./Componentstest/AddMoney";
import PayoutScreen from "./Componentstest/Payout";
import AddMember from "./Pages/MemberSection/AddMember";
import AddMemberKyc from "./Pages/MemberSection/AddMemberKyc";
import MemberSummary from "./Pages/MemberSection/MemberSummary";
import SearchMember from "./Pages/MemberSection/SearchMember";
import AccountPassbook from "./Pages/SavingAccount/AccountPassbook";
import AddSbAccount from "./Pages/SavingAccount/AddSbAccount";
import SavingAccountClose from "./Pages/SavingAccount/SavingAccountClose";
import SavingDepositWithdraw from "./Pages/SavingAccount/SavingDepositeWithdraw";
import SavingPlanMaster from "./Pages/SavingAccount/SavingPlanMaster";
import SavingStatement from "./Pages/SavingAccount/SavingStatement";
import SearchSaving from "./Pages/SavingAccount/SearchSaving";
import ServiceChargeDeduction from "./Pages/SavingAccount/ServiceChargeDeduction";
import SMSSearchSaving from "./Pages/SavingAccount/SMSSearchSaving";
import MemberApproval from "./Pages/ApprovalSection/MemberApproval";
import SavingTransactionApproval from "./Pages/ApprovalSection/SavingTransactionApproval";
import PlanMaster from "./Pages/InvestmentSection/PlanMaster/PlanMaster";
import Dashboard from "./Pages/Dashboard";
import DailyRDPassbook from "./Pages/InvestmentSection/DailyRDPassbook";
import CertificateIssue from "./Pages/InvestmentSection/CertificateIssue";
import InvestmentReceipt from "./Pages/InvestmentSection/InvestmentReceipt";
import RecurringReceipt from "./Pages/InvestmentSection/RecurringReceipt";
import CertificateReissue from "./Pages/InvestmentSection/CertificateReissue";
import InterestDetails from "./Pages/InvestmentSection/InterestDetails";
import InterestPayable from "./Pages/InvestmentSection/InterestPayable";
import RenewalPayment from "./Pages/InvestmentSection/RenewalPayment";
import InvestmentSearch from "./Pages/InvestmentSection/InvestmentSearch";
import ReceiptEntry from "./Pages/AccountSection/ReceiptEntry";
import PaymentEntry from "./Pages/AccountSection/PaymentEntry";
import AddAdvisor from "./Pages/AdvisorSection/AddAdvisor";
import AdvisorTree from "./Pages/AdvisorSection/AdvisorTree";
import AdvisorDownline from "./Pages/AdvisorSection/AdvisorDownline";
import AdvisorIDCard from "./Pages/AdvisorSection/AdvisorIDCard";
import AdvisorPromotion from "./Pages/AdvisorSection/AdvisorPromotion";
import SearchAdvisor from "./Pages/AdvisorSection/SearchAdvisor";
import ShareTransfer from "./Pages/MemberShare/ShareTransfer";
import UnallotedShares from "./Pages/MemberShare/UnallotedShares";
import ShareCertificate from "./Pages/MemberShare/ShareCertificate";
import ShareReport from "./Pages/MemberShare/ShareReport";
import DNOGenerate from "./Pages/MemberShare/DNOGenerate";
import NewInvestment from "./Pages/InvestmentSection/NewInvestment";
import AddDesignation from "./Pages/EmployeeSection.jsx/AddDesignation";
import AddDepartment from "./Pages/EmployeeSection.jsx/AddDepartment";
import AddEmployee from "./Pages/EmployeeSection.jsx/AddEmployee";
import EmployeeIDCard from "./Pages/EmployeeSection.jsx/EmployeeIDCard";
import SearchEmployee from "./Pages/EmployeeSection.jsx/SearchEmployee";
import DailyRenewalPayment from "./Pages/InvestmentSection/DailyRenewalPayment";
import FlexiRenewalPayment from "./Pages/InvestmentSection/FlexiRenewalPayment";
import LedgerMaster from "./Pages/AccountSection/LedgerMaster";
import CountraEntry from "./Pages/AccountSection/CountraEntry";
import JournalEntry from "./Pages/AccountSection/JournalEntry";
import InsentivePayment from "./Pages/AccountSection/InsentivePayment";
import MisPayment from "./Pages/AccountSection/MisPayment";
import CheckClearing from "./Pages/AccountSection/CheckClearing";
import BankStatement from "./Pages/AccountSection/BankStatement";
import SBIntraTransfer from "./Pages/SavingAccount/SBIntraTransfer";
import ShareTransfer2 from "./Pages/MemberShare/ShareTransfer2";
import SBInterestTransfer from "./Pages/SavingAccount/SBInterestTransfer";
import AdvisorApproval from "./Pages/ApprovalSection/AdvisorApproval";
import BankCharges from "./Pages/RealBanking/BankCharges";
import AccountDetails from "./Pages/RealBanking/AccountDetails";
import CreateVirtualAccount from "./Pages/RealBanking/CreateVirtualAccount";
import AddNewBeneficiary from "./Pages/RealBanking/AddNewBeneficiary";
import FundTransfer from "./Pages/RealBanking/FundTransfer";
import ReceivePaymentsList from "./Pages/RealBanking/ReceivePaymentsList";
import TransferPaymentsList from "./Pages/RealBanking/TransferPaymentsList";
import MandateDepositBank from "./Pages/AccountSection/MandateDepositBank";
import CashBook from "./Pages/AccountSection/CashBook";
import TransferBook from "./Pages/AccountSection/TransferBook";
import DayBook from "./Pages/AccountSection/DayBook";
import LedgerReport from "./Pages/AccountSection/LedgerReport";
import JournalReport from "./Pages/AccountSection/JournalReport";
import TrialBalance from "./Pages/AccountSection/TrialBalance";
import PLStatement from "./Pages/AccountSection/PLStatement";
import BalanceSheet from "./Pages/AccountSection/BalanceSheet";
import ProfitLoss from "./Pages/AccountSection/ProfitLoss";
import BranchCashTransfer from "./Pages/AccountSection/BranchCashTransfer";
import AppUserCreation from "./Pages/MobileAppControl/AppUserCreation";
import MoMemberApproval from "./Pages/MobileAppControl/MoMemberApproval";
import MoAdvisorApproval from "./Pages/MobileAppControl/MoAdvisorApproval";
import MoPolicyApproval from "./Pages/MobileAppControl/MoPolicyApproval";
import MoRecurringApproval from "./Pages/MobileAppControl/MoRecurringApproval";
import MoRegularEMIApproval from "./Pages/MobileAppControl/MoRegularEMIApproval";
import MoIrregularEMIApproval from "./Pages/MobileAppControl/MoIrregularEMIApproval";
import MoSavingTransactionApproval from "./Pages/MobileAppControl/MoSavingTransactionApproval";
import Home from "./Pages/Summary/Home";
import AddBranch from "./Components/NavbarOptions/Configuration/AddBranch";
import CompanySetting from "./Components/NavbarOptions/Configuration/CompanySetting";
import AddFYMaster from "./Components/NavbarOptions/Configuration/AddFYMaster";
import BankMaster from "./Components/NavbarOptions/Configuration/BankMaster";
import CodeSetting from "./Components/NavbarOptions/Configuration/CodeSetting";
import AddDirector from "./Components/NavbarOptions/Configuration/AddDirector";
import UserCreation from "./Components/NavbarOptions/Configuration/UserCreation";
import UserMenuAccess from "./Components/NavbarOptions/Configuration/UserMenuAccess";
import UserActivityReport from "./Components/NavbarOptions/Configuration/UserActivityReport";
import CollectionSheet from "./Components/NavbarOptions/CollectionSheet";
import AddCollector from "./Pages/AdvisorSection/AddCollector";
import SBTransferApproval from "./Pages/ApprovalSection/SBTransferApproval";
import { createTheme, ThemeProvider } from "@mui/material";
import MemberRectification from "./Pages/RectificationSection/MemberRectification";
import ShareRectification from "./Pages/RectificationSection/ShareRectification";
import EditAdvisorCollector from "./Pages/RectificationSection/EditAdvisorCollector";
import InvestmentRectification from "./Pages/RectificationSection/InvestmentRectification";
import RenewalRectification from "./Pages/RectificationSection/RenewalRectification";
import MyForm from "./Pages/RectificationSection/MyForm";
import ShareApproval from "./Pages/ApprovalSection/ShareApproval";
import SavingsRectification from "./Pages/RectificationSection/SavingsRectification";
import InvestmentApproval from "./Pages/ApprovalSection/InvestmentApproval";
import RecurringApproval from "./Pages/ApprovalSection/RecurringApproval";
import InactivityHandler from "./Components/InactivityHandler";
import Login from "./Components/Login";
import ResetPassword from "./Components/ResetPassword";
import ForgetPassword from "./Components/ForgetPassword";
import ChangePassword from "./Components/ChangePassword";
import MemberApproval2 from "./Pages/ApprovalSection/MemberApproval2";

const App = () => {
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Poppins",
        textTransform: "none",
        fontSize: 16,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <InactivityHandler timeout={30 * 60 * 1000}>
        <div className="App">
          <Routes>
            {/* Public Routes */}
            <Route index element={<Login />} />
            {/* <Route path="/login" element={<SignIn />} /> */}
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/addmoneyNew" element={<AddMoney />} />
            <Route path="/payoutnew" element={<PayoutScreen />} />

            {/* <Route path="/" element={<Sidebar />}> */}
            {/* Protected Routes */}

            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Sidebar />
                </ProtectedRoute>
              }
            >
              {/* Nested Routes that will render inside Sidebar's main area */}
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="/change-password" element={<ChangePassword />} />
              {/* Configuration */}
              <Route path="company-setting" element={<CompanySetting />} />
              <Route path="add-fy-master" element={<AddFYMaster />} />
              <Route path="add-branch" element={<AddBranch />} />
              <Route path="bank-master" element={<BankMaster />} />
              <Route path="code-setting" element={<CodeSetting />} />
              <Route path="add-director" element={<AddDirector />} />
              <Route path="user-creation" element={<UserCreation />} />
              <Route path="user-menu-access" element={<UserMenuAccess />} />
              <Route
                path="user-activity-report"
                element={<UserActivityReport />}
              />
              <Route path="collection-sheet" element={<CollectionSheet />} />
              <Route path="home-summary" element={<Home />} />
              {/* Member Section */}
              <Route path="add-member" element={<AddMember />} />
              <Route path="add-member-kyc" element={<AddMemberKyc />} />
              <Route path="member-summary" element={<MemberSummary />} />
              <Route path="search-member" element={<SearchMember />} />
              {/* Member Share */}
              {/* <Route path="share-transfer" element={<ShareTransfer />} /> */}
              <Route path="share-transfer" element={<ShareTransfer />} />
              <Route path="unalloted-shares" element={<UnallotedShares />} />
              <Route path="share-certificate" element={<ShareCertificate />} />
              <Route path="share-report" element={<ShareReport />} />
              <Route path="dno-generate" element={<DNOGenerate />} />
              {/* Savings Section */}
              <Route path="account-passbook" element={<AccountPassbook />} />
              <Route path="savings-opening" element={<AddSbAccount />} />
              <Route
                path="saving-account-close"
                element={<SavingAccountClose />}
              />
              <Route
                path="saving-deposite-withdraw"
                element={<SavingDepositWithdraw />}
              />
              <Route path="saving-plan-master" element={<SavingPlanMaster />} />
              <Route path="saving-statement" element={<SavingStatement />} />
              <Route path="search-saving" element={<SearchSaving />} />
              <Route
                path="service-charge-deduction"
                element={<ServiceChargeDeduction />}
              />
              <Route path="sms-search-saving" element={<SMSSearchSaving />} />
              <Route path="sb-intra-transfer" element={<SBIntraTransfer />} />
              <Route
                path="sb-interest-transfer"
                element={<SBInterestTransfer />}
              />
              {/* Approval Section */}
              <Route path="member-approval" element={<MemberApproval />} />
              <Route path="member-approval2" element={<MemberApproval2 />} />
              <Route path="share-approval" element={<ShareApproval />} />
              <Route
                path="investment-approval"
                element={<InvestmentApproval />}
              />
              <Route
                path="recurring-approval"
                element={<RecurringApproval />}
              />
              <Route
                path="saving-transaction-approval"
                element={<SavingTransactionApproval />}
              />
              <Route path="advisor-approval" element={<AdvisorApproval />} />
              <Route
                path="sb-transfer-approval"
                element={<SBTransferApproval />}
              />
              {/* Investment Section */}
              <Route path="plan-master" element={<PlanMaster />} />
              <Route path="renewal-payment" element={<RenewalPayment />} />
              <Route path="daily-rd-passbook" element={<DailyRDPassbook />} />
              <Route path="certificate-issue" element={<CertificateIssue />} />
              <Route
                path="certificate-re-issue"
                element={<CertificateReissue />}
              />
              <Route
                path="investment-receipt"
                element={<InvestmentReceipt />}
              />
              <Route path="recurring-receipt" element={<RecurringReceipt />} />
              <Route path="interest-details" element={<InterestDetails />} />
              <Route path="interest-payable" element={<InterestPayable />} />
              <Route path="investment-search" element={<InvestmentSearch />} />
              <Route path="new-investment" element={<NewInvestment />} />
              <Route
                path="daily-renewal-payment"
                element={<DailyRenewalPayment />}
              />
              <Route
                path="flexi-renewal-payment"
                element={<FlexiRenewalPayment />}
              />
              {/* Account Section */}
              <Route path="ledger-master" element={<LedgerMaster />} />
              <Route path="payment-entry" element={<PaymentEntry />} />
              <Route path="receipt-entry" element={<ReceiptEntry />} />
              <Route path="countra-entry" element={<CountraEntry />} />
              <Route path="journal-entry" element={<JournalEntry />} />
              <Route path="insentive-payment" element={<InsentivePayment />} />
              <Route path="mis-payment" element={<MisPayment />} />
              <Route path="check-clearing" element={<CheckClearing />} />
              <Route
                path="mandate-deposit-bank"
                element={<MandateDepositBank />}
              />
              <Route path="bank-statement" element={<BankStatement />} />
              <Route path="cash-book" element={<CashBook />} />
              <Route path="transfer-book" element={<TransferBook />} />
              <Route path="day-book" element={<DayBook />} />
              <Route path="ledger-report" element={<LedgerReport />} />
              <Route path="journal-report" element={<JournalReport />} />
              <Route path="trial-balance" element={<TrialBalance />} />
              <Route path="pl-statement" element={<PLStatement />} />
              <Route path="balance-sheet" element={<BalanceSheet />} />
              <Route path="profit-loss" element={<ProfitLoss />} />
              <Route
                path="branch-cash-transfer"
                element={<BranchCashTransfer />}
              />
              {/* Mobile App */}
              <Route path="app-user-creation" element={<AppUserCreation />} />
              <Route path="mo-member-approval" element={<MoMemberApproval />} />
              <Route
                path="mo-advisor-approval"
                element={<MoAdvisorApproval />}
              />
              <Route path="mo-policy-approval" element={<MoPolicyApproval />} />
              <Route
                path="mo-recurring-approval"
                element={<MoRecurringApproval />}
              />
              <Route
                path="mo-regular-emi-approval"
                element={<MoRegularEMIApproval />}
              />
              <Route
                path="mo-irregular-emi-approval"
                element={<MoIrregularEMIApproval />}
              />
              <Route
                path="mo-saving-transaction-approval"
                element={<MoSavingTransactionApproval />}
              />
              MoSavingTransactionApproval
              {/* Advisor Section */}
              <Route path="add-collector-advisor" element={<AddAdvisor />} />
              <Route path="add-collector" element={<AddCollector />} />
              <Route path="advisor-tree" element={<AdvisorTree />} />
              <Route path="advisor-downline" element={<AdvisorDownline />} />
              <Route path="advisor-promotion" element={<AdvisorPromotion />} />
              <Route path="advisor-id-card" element={<AdvisorIDCard />} />
              <Route path="search-advisor" element={<SearchAdvisor />} />
              {/* Employee Section */}
              <Route path="add-designation" element={<AddDesignation />} />
              <Route path="add-department" element={<AddDepartment />} />
              <Route path="add-employee" element={<AddEmployee />} />
              <Route path="employee-id-card" element={<EmployeeIDCard />} />
              <Route path="search-employee" element={<SearchEmployee />} />
              {/* Rectification Section */}
              <Route
                path="member-rectification"
                element={<MemberRectification />}
              />
              <Route
                path="share-rectification"
                element={<ShareRectification />}
              />
              <Route
                path="advisor-collector-rectification"
                element={<EditAdvisorCollector />}
              />
              <Route
                path="investment-rectification"
                element={<InvestmentRectification />}
              />
              <Route
                path="renewal-rectification"
                element={<RenewalRectification />}
              />
              <Route
                path="savings-rectification"
                element={<SavingsRectification />}
              />
              <Route path="test-form" element={<MyForm />} />
              {/* Real Banking */}
              <Route path="bank-charges" element={<BankCharges />} />
              <Route path="account-details" element={<AccountDetails />} />
              <Route
                path="create-virtual-account"
                element={<CreateVirtualAccount />}
              />
              <Route
                path="add-new-beneficiary"
                element={<AddNewBeneficiary />}
              />
              <Route path="fund-transfer" element={<FundTransfer />} />
              <Route
                path="receive-payments-list"
                element={<ReceivePaymentsList />}
              />
              <Route
                path="transfer-payments-list"
                element={<TransferPaymentsList />}
              />
            </Route>

            {/* Catch-All Route */}
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </div>
      </InactivityHandler>
    </ThemeProvider>
  );
};
export default App;
