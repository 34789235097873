import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

const InputField = ({
  label,
  type = "text",
  required = false,
  error,
  helperText,
  InputProps = {},
  ...props
}) => (
  <Grid item xs={12} md={6}>
    <Typography sx={{ fontSize: { xs: "12px", md: "14px" } }}>
      {label} {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
    <TextField
      type={type}
      sx={{
        width: "100%",
        "& .MuiInputBase-root": {
          height: { xs: "40px", md: "48px" },
          borderRadius: "10px", // Apply borderRadius here
          backgroundColor: InputProps?.style?.background || "#FFFFFF", // Dynamic background
        },
      }}
      InputProps={{
        ...InputProps,
      }}
      error={error}
      helperText={helperText}
      {...props}
    />
  </Grid>
);

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "170px" },
    height: "48px",
    fontWeight: "bold",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
  deleteButton: {
    backgroundColor: "#92230C",
    color: "#fff",
    ":hover": { background: "#831F0A" },
  },
};

const RenewalPayment = () => {
  const [policyNo, setPolicyNo] = useState([]);
  const [selectedPolicyNo, setSelectedPolicyNo] = useState("");
  const [policyDetails, setPolicyDetails] = useState({
    policyDate: "",
    lastInstPaid: 0,
    memberName: "",
    maturityDate: "",
    dueDate: "",
    lateFine: "",
    applicantName: "",
    mobileNo: "",
    policyAmount: "",
    advisorCode: "",
    schemeName: "",
    advisorName: "",
    schemeTerm: "",
    maturityAmount: "",
    netDeposited: "",
    amountDue: "",
    smsStatus: "",
    branchName: "",
    memberCode: "",
    noInstallmentPaid: "",
    remarks: "",
    paymentBy: "CASH",
    policyDate: "",
    lastInstPaid: 0,
    advisorCode: "",
  });

  const resetFields = () => {
    setSelectedPolicyNo("");
    setPolicyDetails({
      policyDate: "",
      lastInstPaid: 0,
      memberName: "",
      maturityDate: "",
      dueDate: "",
      lateFine: "",
      applicantName: "",
      mobileNo: "",
      policyAmount: "",
      advisorCode: "",
      schemeName: "",
      advisorName: "",
      schemeTerm: "",
      maturityAmount: "",
      netDeposited: "",
      amountDue: "",
      smsStatus: "",
      branchName: "",
      memberCode: "",
      noInstallmentPaid: "",
      remarks: "",
      paymentBy: "CASH",
      policyDate: "",
      lastInstPaid: 0,
      advisorCode: "",
    });
  };

  const axiosInstance = axios.create({
    baseURL: "https://testapi.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });

  const fetchPolicyNo = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        "/admin/investment/policy-codes?schemeType=RD"
      );
      setPolicyNo(response.data);
    } catch (error) {
      console.error("Error fetching policy no:", error.message);
    }
  }, []);

  // Fetch details of a selected policy
  const fetchPolicyDetails = useCallback(async (policyCode) => {
    try {
      const response = await axiosInstance.get(
        `/admin/monthly/installments/${policyCode}`
      );
      const data = response.data;

      // Map API response to respective fields
      setPolicyDetails({
        policyDate: data.addInvestment.policyDate || "",
        lastInstPaid: data.lastInstPaid || "",
        maturityDate: data.addInvestment.maturityDate || "",
        dueDate: data.dueDate || "",
        lateFine: data.lateFine || "",
        applicantName: data.addInvestment.applicantName || "",
        mobileNo: data.addInvestment.member.mobileNo || "N/A",
        memberCode: data.addInvestment.member.memberCode || "",
        policyAmount: data.policyAmount || "N/A",
        advisorCode: data.addCollector.advisorCode || "N/A",
        schemeName: data.addInvestment.schemeName || "N/A",
        advisorName: data.addCollector.advisorName || "N/A",
        schemeTerm: data.addInvestment.planCode || "N/A",
        maturityAmount: data.addInvestment.maturityAmount || "N/A",
        netDeposited: data.netDeposited || "N/A",
        amountDue: data.amountDue || "N/A",
        smsStatus: data.smsStatus || "",
        branchName: data.addInvestment.branch.branchName || "",
        // ------------------------------------------------------
        policyDate: data.addInvestment.policyDate || "",
        lastInstPaid: data.lastInstPaid || 0,
        advisorCode: data.addCollector.advisorCode || "",
        advisorName: data.addCollector.advisorName || "",
        paymentBy: data.addInvestment.paymentBy || "",
      });
    } catch (error) {
      console.error("Error fetching policy details:", error.message);
    }
  }, []);

  // Fetch advisor details when advisorCode changes
  const fetchAdvisorDetails = useCallback(async (advisorCode) => {
    try {
      const response = await axiosInstance.get(
        `/admin/Downline/details/${advisorCode}`
      );
      const data = response.data;

      setPolicyDetails((prevDetails) => ({
        ...prevDetails,
        advisorName: data.advisorName || "",
      }));
    } catch (error) {
      console.error("Error fetching advisor details:", error.message);
      setPolicyDetails((prevDetails) => ({
        ...prevDetails,
        advisorName: "",
      }));
    }
  }, []);

  const handleSave = async () => {
    if (
      !policyDetails.noInstallmentPaid ||
      // !policyDetails.remarks ||
      !policyDetails.paymentBy
    ) {
      alert("Please fill in all required fields.");
      // Swal.fire({
      //   icon: "warning",
      //   title: "Validation Error",
      //   text: "Please fill in all required fields.",
      // });
      return;
    }
    if (!policyDetails.advisorName) {
      alert("Please enter a valid advisor code.");
      // Swal.fire({
      //   icon: "warning",
      //   title: "Validation Error",
      //   text: "Please enter a valid advisor code.",
      // });
      return;
    }
    const payload = {
      noInstallmentPaid: policyDetails.noInstallmentPaid,
      remarks: policyDetails.remarks,
      paymentBy: policyDetails.paymentBy,
      addinvestment: {
        policyCode: selectedPolicyNo,
      },
      advisorCode: policyDetails.advisorCode,
      advisorCollector: policyDetails.advisorName,
    };

    try {
      const response = await axiosInstance.post(
        "/admin/monthly/monthlyrenewal",
        payload
      );
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Monthly renewal payment saved successfully!",
      });
      resetFields();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error saving renewal payment. Please try again.",
      });
    }
  };

  useEffect(() => {
    fetchPolicyNo();
  }, [fetchPolicyNo]);

  return (
    <Box>
      <Typography sx={styles.header}>Monthly Renewal</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
          Search Box
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box display="flex" gap={2}>
              <InputField
                label="Policy No."
                select
                required
                value={selectedPolicyNo}
                onChange={(e) => {
                  const selectedPolicy = e.target.value;
                  setSelectedPolicyNo(selectedPolicy);
                  fetchPolicyDetails(selectedPolicy);
                }}
              >
                {policyNo.map((policy, index) => (
                  <MenuItem key={index} value={policy.policyCode}>
                    {`${policy.applicantName} - ${policy.policyCode}`}
                  </MenuItem>
                ))}
              </InputField>

              <InputField
                label="Renewal Date"
                type="date"
                required
                value={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  setPolicyDetails({
                    ...policyDetails,
                    renewalDate: e.target.value,
                  })
                }
              />
              <InputField
                label="Branch Name"
                value={policyDetails.branchName}
                disabled
                required
                InputProps={{
                  style: { background: "#eee" },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Grid container spacing={3}>
          <InputField
            label="Policy Date"
            type="date"
            required
            value={policyDetails.policyDate}
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="Last Inst. Paid"
            value={
              policyDetails.lastInstPaid == 0 ? 0 : policyDetails.lastInstPaid
            }
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
            required
          />
          <InputField
            label="Maturity Date"
            type="date"
            required
            value={policyDetails.maturityDate}
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="Due Date"
            type="date"
            value={policyDetails.dueDate}
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
            required
          />
          <InputField
            label="Member Code"
            value={policyDetails.memberCode}
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
            required
          />
          <InputField
            label="Late Fine"
            value={policyDetails.lateFine}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="Applicant Name"
            value={policyDetails.applicantName}
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
            required
          />
          <InputField
            label="No. Installment Paid"
            value={policyDetails.noInstallmentPaid}
            onChange={(e) => {
              setPolicyDetails((prevDetails) => ({
                ...prevDetails,
                noInstallmentPaid: e.target.value,
              }));
            }}
            required
          />
          <InputField
            label="Mobile No"
            value={policyDetails.mobileNo}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="Payment By"
            select
            required
            value={policyDetails.paymentBy}
            onChange={(e) => {
              setPolicyDetails((prevDetails) => ({
                ...prevDetails,
                paymentBy: e.target.value,
              }));
            }}
          >
            <MenuItem value="CASH">CASH</MenuItem>
            <MenuItem value="CHEQUE">CHEQUE</MenuItem>
            <MenuItem value="ONLINE">ONLINE</MenuItem>
            <MenuItem value="NEFT">NEFT</MenuItem>
            <MenuItem value="SBACCOUNT">SBACCOUNT</MenuItem>
          </InputField>
          <InputField
            label="Policy Amount"
            required
            value={policyDetails.policyAmount}
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="Advisor/Collector Code"
            value={policyDetails.advisorCode}
            onChange={(e) => {
              const advisorCode = e.target.value;
              setPolicyDetails((prevDetails) => ({
                ...prevDetails,
                advisorCode,
              }));
              fetchAdvisorDetails(advisorCode);
            }}
            required
          />
          <InputField
            label="Scheme Name"
            value={policyDetails.schemeName}
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
            required
          />
          <InputField
            label="Advisor/Collector Name"
            value={policyDetails.advisorName}
            onChange={(e) => {
              const advisorName = e.target.value;
              setPolicyDetails((prevDetails) => ({
                ...prevDetails,
                advisorName,
              }));
            }}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="Scheme Term"
            value={policyDetails.schemeTerm}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="Remarks"
            value={policyDetails.remarks}
            onChange={(e) => {
              setPolicyDetails((prevDetails) => ({
                ...prevDetails,
                remarks: e.target.value,
              }));
            }}
          />
          <InputField
            label="Maturity Amount"
            required
            value={policyDetails.maturityAmount}
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="Net Deposited"
            value={policyDetails.netDeposited}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="Amount Due"
            value={policyDetails.amountDue}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="SMS Status"
            value={policyDetails.smsStatus === "true" ? "Yes" : "No"}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          />
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            onClick={handleSave}
            sx={{ ...styles.button, ...styles.saveButton, mt: 2.5 }}
          >
            Save
          </Button>
          <Button
            onClick={resetFields}
            sx={{ ...styles.button, ...styles.newButton, mt: 2.5 }}
          >
            New
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default RenewalPayment;
