import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Paper } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import Logo from "../Assets/Login/vlLogo.png";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get("token");
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    } else {
      Swal.fire("Error", "No token found in URL", "error");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setError("");

    try {
      const response = await axios.post(
        "https://testapi.vlnidhi.com/api/password/reset-password",
        {
          token,
          newPassword,
        }
      );
      if (response.status === 200) {
        Swal.fire("Success", "Password successfully reset", "success");
        setNewPassword("");
        setConfirmPassword("");
        navigate("/login");
      } else {
        Swal.fire("Error", "Something went wrong. Please try again.", "error");
      }
    } catch (err) {
      console.error("Error resetting password:", err);
      Swal.fire(
        "Error",
        err.response?.data?.message || "An error occurred",
        "error"
      );
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "#98b0ff",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
      }}
    >
      <Paper
        elevation={10}
        sx={{
          p: 4,
          borderRadius: 3,
          maxWidth: 400,
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={Logo}
            alt="Logo"
            style={{ width: 60, marginBottom: 10, borderRadius: "50%" }}
          />
        </Box>
        <Typography
          fontWeight="bold"
          mb={1}
          sx={{
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          VENKETLAXMI URBAN PUNE NIDHI LTD
        </Typography>

        <Typography
          fontWeight="bold"
          mb={1}
          sx={{
            textAlign: "center",
            fontSize: "18px",
          }}
          align="center"
          gutterBottom
        >
          Reset Password
        </Typography>

        <TextField
          fullWidth
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          margin="normal"
          required
        />

        <TextField
          fullWidth
          label="Confirm New Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          margin="normal"
          required
        />

        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Paper>
    </Box>
  );
};

export default ResetPassword;
