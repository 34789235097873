import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Swal from "sweetalert2";

const ServiceChargeDeduction = () => {
  const [date, setDate] = useState("");
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false); // Track if search was performed

  const token = sessionStorage.getItem("token");

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
  };

  const handleSearch = async () => {
    if (!date) {
      Swal.fire({
        title: "Error!",
        text: "Please select a date.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    setShowTable(true);
    setLoading(true);
    setSearchPerformed(true); // Mark that search was performed

    try {
      const response = await axios.get(
        `https://testapi.vlnidhi.com/api/admin/saving-accounts/failed-service-deductions/${date}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error fetching data. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (row) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(row)
        ? prevSelectedRows.filter((r) => r !== row)
        : [...prevSelectedRows, row]
    );
  };

  const handleDeductCharges = async () => {
    if (selectedRows.length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please select at least one row.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const accountNumbers = selectedRows.map((row) => row.accountNumber);

    try {
      await axios.post(
        "https://testapi.vlnidhi.com/api/admin/saving-accounts/retry-service",
        accountNumbers,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        title: "Success!",
        text: "Charges deducted successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      setSelectedRows([]); // Clear selection after successful deduction
      setSearchPerformed(false); // Reset searchPerformed to hide the table
    } catch (error) {
      console.error("Error deducting charges:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error deducting charges. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Service Charges Deduction
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
          }}
        >
          Search Box
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                mb: 1,
                mt: 2,
                ...fontStyle,
              }}
            >
              As On Date:
            </Typography>
            <TextField
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              sx={{
                width: { xs: "100%", md: "495px" },
                height: "48px",
                borderRadius: "10px",
                borderColor: "#D9D9D9CC",
                backgroundColor: "#FFFFFF",
                "& .MuiInputBase-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Box>

          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              mt: 5,
            }}
            onClick={handleSearch}
            // startIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Box>
      </Paper>

      {showTable && (
        <Paper sx={{ p: 2, mt: 2, borderRadius: "20px" }}>
          {loading && <Typography sx={{ mt: 2 }}>Loading...</Typography>}

          {searchPerformed && data.length > 0 && (
            <Box sx={{ mt: 0 }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontStyle: "poppins",
                  mt: 1,
                  mb: 2,
                }}
              >
                Saving Account List
              </Typography>
              <TableContainer component={Paper} sx={{ borderRadius: "12px" }}>
                <Table>
                  <TableHead sx={{ backgroundColor: "#5865F2", color: "#fff" }}>
                    <TableRow>
                      <TableCell sx={{ color: "#fff" }}>Select</TableCell>
                      <TableCell sx={{ color: "#fff" }}>A/C No.</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        A/C Holder Name
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>Member Code</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Balance</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Quarter</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        Service Charges
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row.accountNumber}>
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.includes(row)}
                            onChange={() => handleCheckboxChange(row)}
                          />
                        </TableCell>
                        <TableCell>{row.accountNumber}</TableCell>
                        <TableCell>{row.accountHolderName}</TableCell>
                        <TableCell>{row.memberCode}</TableCell>
                        <TableCell>{row.balance}</TableCell>
                        <TableCell>{row.quarter}</TableCell>
                        <TableCell>{row.smsCharges}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button
                  sx={{
                    color: "white",
                    textTransform: "none",
                    fontFamily: "Poppins",
                    background: "#DFBF41",
                    width: { xs: "50%", md: "157px" },
                    height: "48px",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#B89B3E" },
                    mt: 2,
                    mb: 2,
                  }}
                  onClick={handleDeductCharges}
                >
                  Deduct Charges
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default ServiceChargeDeduction;

// import React, { useState } from 'react';
// import { Box, Button, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import axios from 'axios';
// import Swal from 'sweetalert2';

// const ServiceChargeDeduction = () => {
//   const [date, setDate] = useState('');
//   const [data, setData] = useState([]);
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [searchPerformed, setSearchPerformed] = useState(false); // State to track if search was performed

//   const token = sessionStorage.getItem('token');

//   const handleSearch = async () => {
//     if (!date) {
//       Swal.fire({
//         title: 'Error!',
//         text: 'Please select a date.',
//         icon: 'error',
//         confirmButtonText: 'OK'
//       });
//       return;
//     }

//     setLoading(true);
//     try {
//       const response = await axios.get(`https://testapi.vlnidhi.com/api/admin/saving-accounts/failed-service-deductions/${date}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setData(response.data);
//       setSearchPerformed(true); // Mark that search was performed
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       Swal.fire({
//         title: 'Error!',
//         text: 'There was an error fetching data. Please try again.',
//         icon: 'error',
//         confirmButtonText: 'OK'
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleCheckboxChange = (row) => {
//     setSelectedRows((prevSelectedRows) =>
//       prevSelectedRows.includes(row)
//         ? prevSelectedRows.filter((r) => r !== row)
//         : [...prevSelectedRows, row]
//     );
//   };

//   const handleDeductCharges = async () => {
//     if (selectedRows.length === 0) {
//       Swal.fire({
//         title: 'Error!',
//         text: 'Please select at least one row.',
//         icon: 'error',
//         confirmButtonText: 'OK'
//       });
//       return;
//     }
//     const accountNumbers = selectedRows.map(row => row.accountNumber);

//     try {
//       await axios.post('https://testapi.vlnidhi.com/api/admin/saving-accounts/retry-service', accountNumbers, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       Swal.fire({
//         title: 'Success!',
//         text: 'Charges deducted successfully.',
//         icon: 'success',
//         confirmButtonText: 'OK'
//       });
//       setSelectedRows([]); // Clear selection after successful deduction
//       setSearchPerformed(false); // Reset searchPerformed to hide the table
//     } catch (error) {
//       console.error('Error deducting charges:', error);
//       Swal.fire({
//         title: 'Error!',
//         text: 'There was an error deducting charges. Please try again.',
//         icon: 'error',
//         confirmButtonText: 'OK'
//       });
//     }
//   };

//   return (
//     <Box sx={{ width: '100%', padding: '20px', borderRadius: '20px', backgroundColor: '#fff', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', maxWidth: '1200px', margin: 'auto', mt: 4 }}>
//       <Typography variant="h6" sx={{ mb: 2 }}>
//         Search Box
//       </Typography>

//       <Box sx={{ display: 'flex', alignItems: 'center' }}>
//         <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
//           <Typography sx={{ mb: 1 }}>As On Date:</Typography>
//           <TextField
//             variant="outlined"
//             type="date"
//             value={date}
//             onChange={(e) => setDate(e.target.value)}
//             sx={{ width: '200px', height: '40px', '& input': { padding: '8px' } }}
//           />
//         </Box>

//         <Button
//           variant="contained"
//           sx={{ height: '40px', backgroundColor: '#D0B24F', color: '#fff', '&:hover': { backgroundColor: '#B89B3E' }, alignSelf: 'flex-end' }}
//           onClick={handleSearch}
//           startIcon={<SearchIcon />}
//         >
//           Search
//         </Button>
//       </Box>

//       {loading && <Typography sx={{ mt: 2 }}>Loading...</Typography>}

//       {searchPerformed && data.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <TableContainer component={Paper} sx={{ borderRadius: '12px' }}>
//             <Table>
//               <TableHead sx={{ backgroundColor: '#5865F2', color: '#fff' }}>
//                 <TableRow>
//                   <TableCell sx={{ color: '#fff' }}>Select</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>A/C No.</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>A/C Holder Name</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Member Code</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Balance</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Quarter</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Service Charges</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {data.map((row) => (
//                   <TableRow key={row.accountNumber}>
//                     <TableCell>
//                       <Checkbox
//                         checked={selectedRows.includes(row)}
//                         onChange={() => handleCheckboxChange(row)}
//                       />
//                     </TableCell>
//                     <TableCell>{row.accountNumber}</TableCell>
//                     <TableCell>{row.accountHolderName}</TableCell>
//                     <TableCell>{row.memberCode}</TableCell>
//                     <TableCell>{row.balance}</TableCell>
//                     <TableCell>{row.quarter}</TableCell>
//                     <TableCell>{row.smsCharges}</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//             <Button
//               variant="contained"
//               sx={{ backgroundColor: '#D0B24F', color: '#fff', '&:hover': { backgroundColor: '#B89B3E' }, borderRadius: '12px' }}
//               onClick={handleDeductCharges}
//             >
//               Deduct Charges
//             </Button>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ServiceChargeDeduction;

// import React, { useState } from 'react';
// import { Box, Button, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import axios from 'axios';

// const ServiceChargeDeduction = () => {
//   const [date, setDate] = useState('');
//   const [data, setData] = useState([]);
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const token = sessionStorage.getItem('token');

//   const handleSearch = async () => {
//     if (!date) {
//       alert('Please select a date');
//       return;
//     }

//     setLoading(true);
//     try {
//       const response = await axios.get(`https://testapi.vlnidhi.com/api/admin/saving-accounts/failed-service-deductions/${date}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setData(response.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleCheckboxChange = (row) => {
//     setSelectedRows((prevSelectedRows) =>
//       prevSelectedRows.includes(row)
//         ? prevSelectedRows.filter((r) => r !== row)
//         : [...prevSelectedRows, row]
//     );
//   };

//   const handleDeductCharges = async () => {
//     if (selectedRows.length === 0) {
//       alert('Please select at least one row');
//       return;
//     }

//     // Extract only accountNumber from each selected row
//     const accountNumbers = selectedRows.map(row => row.accountNumber);

//     try {
//       await axios.post('https://testapi.vlnidhi.com/api/admin/saving-accounts/retry-service', accountNumbers, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       alert('Charges deducted successfully');
//       setSelectedRows([]); // Clear selection after successful deduction
//     } catch (error) {
//       console.error('Error deducting charges:', error);
//     }
//   };

//   return (
//     <Box sx={{ width: '100%', padding: '20px', borderRadius: '20px', backgroundColor: '#fff', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', maxWidth: '1200px', margin: 'auto', mt: 4 }}>
//       <Typography variant="h6" sx={{ mb: 2 }}>
//         Search Box
//       </Typography>

//       <Box sx={{ display: 'flex', alignItems: 'center' }}>
//         <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
//           <Typography sx={{ mb: 1 }}>As On Date:</Typography>
//           <TextField
//             variant="outlined"
//             type="date"
//             value={date}
//             onChange={(e) => setDate(e.target.value)}
//             sx={{ width: '200px', height: '40px', '& input': { padding: '8px' } }}
//           />
//         </Box>

//         <Button
//           variant="contained"
//           sx={{ height: '40px', backgroundColor: '#D0B24F', color: '#fff', '&:hover': { backgroundColor: '#B89B3E' }, alignSelf: 'flex-end' }}
//           onClick={handleSearch}
//           startIcon={<SearchIcon />}
//         >
//           Search
//         </Button>
//       </Box>

//       {loading && <Typography sx={{ mt: 2 }}>Loading...</Typography>}

//       {data.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <TableContainer component={Paper} sx={{ borderRadius: '12px' }}>
//             <Table>
//               <TableHead sx={{ backgroundColor: '#5865F2', color: '#fff' }}>
//                 <TableRow>
//                   <TableCell sx={{ color: '#fff' }}>Select</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>A/C No.</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>A/C Holder Name</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Member Code</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Balance</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Quarter</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Service Charges</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {data.map((row) => (
//                   <TableRow key={row.accountNumber}>
//                     <TableCell>
//                       <Checkbox
//                         checked={selectedRows.includes(row)}
//                         onChange={() => handleCheckboxChange(row)}
//                       />
//                     </TableCell>
//                     <TableCell>{row.accountNumber}</TableCell>
//                     <TableCell>{row.accountHolderName}</TableCell>
//                     <TableCell>{row.memberCode}</TableCell>
//                     <TableCell>{row.balance}</TableCell>
//                     <TableCell>{row.quarter}</TableCell>
//                     <TableCell>{row.smsCharges}</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//             <Button
//               variant="contained"
//               sx={{ backgroundColor: '#D0B24F', color: '#fff', '&:hover': { backgroundColor: '#B89B3E' }, borderRadius: '12px' }}
//               onClick={handleDeductCharges}
//             >
//               Deduct Charges
//             </Button>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ServiceChargeDeduction;
