import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const loginUser = (userData, navigate) => async (dispatch) => {
  // const navigate = useNavigate();
  console.log(userData);
  try {
    const response = await axios.post(
      "https://testapi.vlnidhi.com/api/public/login",
      userData
    );
    const { Token, username, usertype } = response.data;

    // Check for valid user types
    const validUserTypes = ["ROLE_ADMIN", "ROLE_MEMBER", "ROLE_ADVISOR"];
    if (!validUserTypes.includes(usertype)) {
      throw new Error("Invalid User Type");
    }

    sessionStorage.setItem("token", Token);
    sessionStorage.setItem("username", username);
    sessionStorage.setItem("usertype", usertype);

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: { Token, username, usertype },
    });

    // Navigate to dashboard after successful login
    // toast.success("Login Successful!");
    navigate("/dashboard");
    // window.location.href = "/dashboard"; // Use window.location to force a redirect
  } catch (error) {
    const errorMessage =
      error.message === "Invalid User Type"
        ? error.message
        : error.response?.data?.message ||
          "Login Failed: Invalid username or password";

    dispatch({
      type: "LOGIN_FAIL",
      payload: { message: errorMessage },
    });
    // Show error toast
    toast.error(errorMessage);
  }
};

// export const logoutUser = () => (dispatch) => {
//   // Clear local storage and any user-related state
//   // sessionStorage.removeItem("token");
//   // sessionStorage.removeItem("username");
//   // sessionStorage.removeItem("usertype");
//   sessionStorage.clear();

//   dispatch({
//     type: "LOGOUT",
//   });
//   alert("You have been logged out due to inactivity. Plaese login again.")
//   toast.info("You have been logged out due to inactivity.");
// };
