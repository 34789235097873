import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "118px" },
    height: "48px",
    fontWeight: "bold",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
};

function BankMaster() {
  const [branches, setBranches] = useState([]);
  const [banks, setBanks] = useState([]);
  const [banksDropdown, setBanksDropdown] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentBankName, setCurrentBankName] = useState("");
  const [currentBankId, setCurrentBankId] = useState("");
  const [formValues, setFormValues] = useState({
    accountNo: "",
    mobileNo: "",
    address: "",
    openingDate: "",
    opBalance: "",
    bankName: "",
    branchIds: [],
  });

  const axiosInstance = axios.create({
    baseURL: "https://testapi.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });

  const handleEdit = (bank) => {
    setIsEditMode(true);
    setCurrentBankName(bank.bankName.bankName);
    setCurrentBankId(bank.id);
    setFormValues({
      accountNo: bank.accountNo,
      mobileNo: bank.mobileNo,
      address: bank.address,
      openingDate: bank.openingDate,
      opBalance: bank.opBalance,
      bankName: bank.bankName.bankName,
      branchIds: bank.branchIds || [],
    });
    setSelectedBranches(bank.accessibleBranches.map((branch) => branch.id));
  };

  const handleUpdate = async () => {
    try {
      const payload = {
        ...formValues,
        branchIds: selectedBranches,
      };

      const response = await axiosInstance.put(
        `/admin/bank/${currentBankId}`,
        payload
      );

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Bank updated successfully!",
        confirmButtonText: "OK",
      });

      fetchBanks();
      setFormValues({
        accountNo: "",
        mobileNo: "",
        address: "",
        openingDate: "",
        opBalance: "",
        bankName: "",
        branchIds: [],
      });
      setSelectedBranches([]);
      setIsEditMode(false);
      setCurrentBankName("");
    } catch (error) {
      console.error("Error updating bank details:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Failed to update bank",
        confirmButtonText: "OK",
      });
    }
  };

  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  const fetchBanks = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/bank");
      setBanks(response.data);
    } catch (error) {
      console.error("Error fetching banks:", error.message);
    }
  }, []);

  const fetchBanksDropdown = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/banknames/name/all");
      setBanksDropdown(response.data);
    } catch (error) {
      console.error("Error fetching banks:", error.message);
    }
  }, []);

  useEffect(() => {
    fetchBranches();
    fetchBanks();
    fetchBanksDropdown();
  }, [fetchBranches, fetchBanks, fetchBanksDropdown]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleBranchSelect = (branchId) => {
    setFormValues((prevValues) => {
      const branchIds = prevValues.branchIds.includes(branchId)
        ? prevValues.branchIds.filter((id) => id !== branchId)
        : [...prevValues.branchIds, branchId];
      return { ...prevValues, branchIds };
    });
  };

  const handleCheckboxChange = (branchId) => {
    setSelectedBranches((prev) =>
      prev.includes(branchId)
        ? prev.filter((id) => id !== branchId)
        : [...prev, branchId]
    );
  };

  const handleSave = async () => {
    try {
      const payload = {
        ...formValues,
        branchIds: selectedBranches,
      };
      const response = await axiosInstance.post("/admin/bank/add", payload);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Bank added successfully!",
        confirmButtonText: "OK",
      });

      fetchBanks();
      setFormValues({
        accountNo: "",
        mobileNo: "",
        address: "",
        openingDate: "",
        opBalance: "",
        bankName: "",
        branchIds: [],
      });
      setSelectedBranches([]);
    } catch (error) {
      console.error("Error saving bank details:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Failed to add bank",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Box>
      <Typography sx={styles.header}>Add Bank</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Bank Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Bank Name :</Typography>
            <TextField
              name="bankName"
              select
              value={formValues.bankName}
              onChange={(e) =>
                setFormValues({ ...formValues, bankName: e.target.value })
              }
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              {banksDropdown.map((bank) => (
                <MenuItem key={bank.id} value={bank.bankName}>
                  {bank.bankName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Account No :</Typography>
            <TextField
              name="accountNo"
              value={formValues.accountNo}
              onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Mobile No. :</Typography>
            <TextField
              name="mobileNo"
              value={formValues.mobileNo}
              onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Address :</Typography>
            <TextField
              name="address"
              value={formValues.address}
              onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Opening Date :</Typography>
            <TextField
              name="openingDate"
              type="date"
              value={formValues.openingDate}
              onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Op. Balance :</Typography>
            <TextField
              name="opBalance"
              type="number"
              value={formValues.opBalance}
              onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Typography sx={styles.sectionHeader}>Branch Access List</Typography>
          <Box sx={{ overflowX: "auto", mt: 1 }}>
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "20px 20px 0px 0px",
                width: { md: "100%", xs: "250px" },
                overflowX: "auto",
              }}
            >
              <Table size="small">
                <TableHead sx={{ background: "#413AF1" }}>
                  <TableRow>
                    {["Branch Code", "Branch Name", "Select"].map(
                      (header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            color: "white",
                          }}
                        >
                          {header}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {branches.length > 0 ? (
                    branches.map((branch, index) => (
                      <TableRow
                        key={branch.id}
                        sx={{
                          height: 48,
                          backgroundColor:
                            index % 2 === 0 ? "#F2F6FA" : "#C1E0FB",
                        }}
                      >
                        <TableCell>{branch.branchCode}</TableCell>
                        <TableCell>{branch.branchName}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={selectedBranches.includes(branch.id)}
                            onChange={() => handleCheckboxChange(branch.id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={12}
                        align="center"
                        sx={{ color: "red" }}
                      >
                        No results found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          {isEditMode ? (
            <Button
              sx={{ ...styles.button, ...styles.saveButton }}
              onClick={handleUpdate}
            >
              Update
            </Button>
          ) : (
            <Button
              sx={{ ...styles.button, ...styles.saveButton }}
              onClick={handleSave}
            >
              Save
            </Button>
          )}
          <Button
            sx={{ ...styles.button, ...styles.newButton }}
            onClick={() => {
              setIsEditMode(false);
              setFormValues({
                accountNo: "",
                mobileNo: "",
                address: "",
                openingDate: "",
                opBalance: "",
                bankName: "",
                branchIds: [],
              });
              setSelectedBranches([]);
            }}
          >
            New
          </Button>
        </Box>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Bank Data List</Typography>
        <Box sx={{ overflowX: "auto", mt: 1 }}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px 20px 0px 0px",
              width: { md: "100%", xs: "250px" },
              overflowX: "auto",
            }}
          >
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#413AF1" }}>
                <TableRow>
                  {[
                    "Sl No.",
                    "Account No",
                    "Bank Name",
                    "Mobile No.",
                    "Op. Date",
                    "Op. Balance",
                    "Action",
                  ].map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        color: "white",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {banks.length > 0 ? (
                  banks.map((bank, index) => (
                    <TableRow
                      key={bank.id}
                      sx={{
                        height: 48,
                        backgroundColor:
                          index % 2 === 0 ? "#F2F6FA" : "#C1E0FB",
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{bank.accountNo}</TableCell>
                      <TableCell>{bank.bankName.bankName}</TableCell>
                      <TableCell>{bank.mobileNo}</TableCell>
                      <TableCell>
                        {new Date(bank.openingDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </TableCell>
                      <TableCell>{bank.opBalance}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleEdit(bank)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      align="center"
                      sx={{ color: "red" }}
                    >
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}

export default BankMaster;
